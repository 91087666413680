import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { totalPlayTimetoString } from 'Utils';
import { useSelector } from 'react-redux';
import { MainAppReducer } from 'Types';
import { range } from 'lodash';
import { LessonReducer } from 'Reducers/lessonReducer';
import './BarChart.css';

interface BarChartProps {
  data: { tier: number; time: number }[]; // time in seconds, value is the number on the far right
}

const BarChart: React.FC<BarChartProps> = ({ data }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const lesson = useSelector((state: LessonReducer) => state.lessonReducer)

  useEffect(() => {

    const margin = { top: 20, right: 60, bottom: 20, left: 60 }; // Add margin to the right for the value and to the left for tier labels
    const width = 260 - margin.left - margin.right; // Adjusted width
    const height = 120 - margin.top - margin.bottom;
    const maxPlayedTier = data.reduce((currentGreatest, current) => {
      if(current.tier > currentGreatest && current.time > 0) {
        return current.tier
      } else {
        return currentGreatest
      }
    }, 0)
    const tiersAboveCurrentTier = range(maxPlayedTier + 1, lesson.currentLevelTiers + 1)

    // Remove previous SVG content on re-render
    d3.select(svgRef.current).selectAll('*').remove();

    const svg = d3
      .select(svgRef.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)
      // .attr('class', 'TierBox')

    // Define scales
    const x = d3.scaleLinear()
      .domain([0, d3.max(data, (d: { tier: number; time: number }) => d.time || 0) || 0])
      .range([0, width]);

    const y = d3.scaleBand()
      .domain(data.map((d: { tier: number; time: number }) => `Tier ${d.tier}`))
      .range([0, height])
      .padding(0.2); // More padding to prevent overlap

    const currentTierColor = '#6f7ce4'
    const prevTierColor = '#787b84'
    const playedTierLabel = '#DAD1D4'
    const notPlayedTierLabel = '#767C8C'

    // Append bars
    svg
      .selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', 0)
      .attr('y', (d: { tier: number; time: number }) => y(`Tier ${d.tier}`) || 0)
      .attr('width', (d: { tier: number; time: number }) => x(d.time))
      .attr('height', y.bandwidth())
      .attr('fill', (d: { tier: number; time: number }) => d.tier === lesson.currentUserLevelData?.current_tier ? currentTierColor : prevTierColor );

    // Add tier labels (left side)
    svg
      .selectAll('.tier-label')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'tier-label')
      .attr('x', -10) // Slightly left of the bars
      .attr('y', (d: { tier: number; time: number }) => (y(`Tier ${d.tier}`) || 0) + y.bandwidth() / 2)
      .attr('dy', '.35em')
      // .attr('text-anchor', 'end')
      .attr('fill', (d: { tier: number; time: number }) => 
        d.tier <= maxPlayedTier ? playedTierLabel : notPlayedTierLabel)
      .attr('font-size', '12px')
      .text((d: { tier: number; time: number }) => `Tier ${d.tier}`)
      .style(`text-anchor`, `start`)
      .attr('transform', `translate(-28,0)`)


    // Add value labels (right side, like "784" in your screenshot)
    svg
      .selectAll('.value-label')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'value-label')
      .attr('x', width + 5) // Place it to the right of the bar
      .attr('y', (d: { tier: number; time: number }) => (y(`Tier ${d.tier}`) || 0) + y.bandwidth() / 2)
      .attr('dy', '.35em')
      .style(`text-anchor`, `start`)
      .attr('fill', '#fff')
      .attr('font-size', '12px')
      .text((d: { tier: number; time: number }) => d.time ? totalPlayTimetoString(d.time) : "");


  }, [data]);

  return (
    <div style={{ padding: '10px', borderRadius: '10px' }}>
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default BarChart;
