import { useMediaQuery } from "@mui/material";
import { mediaQueryValues, getMaxHeightWidth } from "Utils/MediaQueries";


export const useMediaQueries = () => {
  const minWidth800 = useMediaQuery(`(min-width:${mediaQueryValues.minWidth800}px)`);
  const minHeight1100 = useMediaQuery(`(min-height:${mediaQueryValues.minHeight1100}px)`);
  const minWidth1000 = useMediaQuery(`(min-width:${mediaQueryValues.minWidth1000}px)`);
  const minHeight900 = useMediaQuery(`(min-height:${mediaQueryValues.minHeight900}px)`);
  const minHeight750 = useMediaQuery(`(min-height:${mediaQueryValues.minHeight750}px)`);

  // console.log("minHeight1100", minHeight1100)
  // console.log("minHeight900", minHeight900)
  // console.log("minHeight750", minHeight750)

  // console.log("minWidth1000", minWidth1000)
  // console.log("minWidth800", minWidth800)
  
  
  const [ maxHeight, maxWidth ] = getMaxHeightWidth({
    [mediaQueryValues.minWidth800]: minWidth800,
    [mediaQueryValues.minHeight1100]: minHeight1100,
    [mediaQueryValues.minHeight750]: minHeight750,
    [mediaQueryValues.minWidth1000]: minWidth1000,
    [mediaQueryValues.minHeight900]: minHeight900
  })

  // console.log("maxHeight", maxHeight)
  // console.log("maxWidth", maxWidth)

  return {
    minWidth1000,
    minWidth800,
    minHeight1100,
    minHeight900,
    minHeight750,
    maxHeight,
    maxWidth
  }

};

