import { ReactToExerciseEventType } from "Phaser/EventBus";
import Tutorial, { ChapterType, ExerciseScene } from "../Types/ExerciseData";

const phrase2 = `
[
    {
        "currentTimestamp": 0,
        "left": "",
        "currentVoiceEntries": [
            {
                "Notes": [
                    {
                        "length": 0.25,
                        "isRest": false,
                        "pitch": {
                            "accidental": 2,
                            "fundamentalNote": 0,
                            "octave": 1,
                            "halfTone": 48,
                            "frequency": 261.6255653005986
                        },
                        "printObject": true,
                        "graphics": [
                            {
                                "staveNote": {
                                    "id": "vf-auto1843222"
                                }
                            }
                        ],
                        "staffPosition": "top"
                    }
                ]
            },
            {
                "Notes": [
                    {
                        "length": 1,
                        "isRest": true,
                        "printObject": false,
                        "graphics": [
                            {
                                "staveNote": {
                                    "id": "vf-auto1843247"
                                }
                            }
                        ],
                        "staffPosition": "bottom"
                    }
                ]
            }
        ],
        "currentMeasureIndex": 0
    },
    {
        "currentTimestamp": 0.25,
        "left": "",
        "currentVoiceEntries": [
            {
                "Notes": [
                    {
                        "length": 0.25,
                        "isRest": false,
                        "pitch": {
                            "accidental": 2,
                            "fundamentalNote": 0,
                            "octave": 1,
                            "halfTone": 48,
                            "frequency": 261.6255653005986
                        },
                        "printObject": true,
                        "graphics": [
                            {
                                "staveNote": {
                                    "id": "vf-auto1843230"
                                }
                            }
                        ],
                        "staffPosition": "top"
                    }
                ]
            }
        ],
        "currentMeasureIndex": 0
    },
    {
        "currentTimestamp": 0.5,
        "left": "",
        "currentVoiceEntries": [
            {
                "Notes": [
                    {
                        "length": 0.5,
                        "isRest": false,
                        "pitch": {
                            "accidental": 2,
                            "fundamentalNote": 0,
                            "octave": 1,
                            "halfTone": 48,
                            "frequency": 261.6255653005986
                        },
                        "printObject": true,
                        "graphics": [
                            {
                                "staveNote": {
                                    "id": "vf-auto1843238"
                                }
                            }
                        ],
                        "staffPosition": "top"
                    }
                ]
            }
        ],
        "currentMeasureIndex": 0
    },
    {
        "currentTimestamp": 1,
        "left": "",
        "currentVoiceEntries": [
            {
                "Notes": [
                    {
                        "length": 0.25,
                        "isRest": false,
                        "pitch": {
                            "accidental": 2,
                            "fundamentalNote": 0,
                            "octave": 1,
                            "halfTone": 48,
                            "frequency": 261.6255653005986
                        },
                        "printObject": true,
                        "graphics": [
                            {
                                "staveNote": {
                                    "id": "vf-auto1843254"
                                }
                            }
                        ],
                        "staffPosition": "top"
                    }
                ]
            },
            {
                "Notes": [
                    {
                        "length": 1,
                        "isRest": true,
                        "printObject": false,
                        "graphics": [
                            {
                                "staveNote": {
                                    "id": "vf-auto1843287"
                                }
                            }
                        ],
                        "staffPosition": "bottom"
                    }
                ]
            }
        ],
        "currentMeasureIndex": 1
    },
    {
        "currentTimestamp": 1.25,
        "left": "",
        "currentVoiceEntries": [
            {
                "Notes": [
                    {
                        "length": 0.25,
                        "isRest": false,
                        "pitch": {
                            "accidental": 2,
                            "fundamentalNote": 0,
                            "octave": 1,
                            "halfTone": 48,
                            "frequency": 261.6255653005986
                        },
                        "printObject": true,
                        "graphics": [
                            {
                                "staveNote": {
                                    "id": "vf-auto1843262"
                                }
                            }
                        ],
                        "staffPosition": "top"
                    }
                ]
            }
        ],
        "currentMeasureIndex": 1
    },
    {
        "currentTimestamp": 1.5,
        "left": "",
        "currentVoiceEntries": [
            {
                "Notes": [
                    {
                        "length": 0.25,
                        "isRest": false,
                        "pitch": {
                            "accidental": 2,
                            "fundamentalNote": 0,
                            "octave": 1,
                            "halfTone": 48,
                            "frequency": 261.6255653005986
                        },
                        "printObject": true,
                        "graphics": [
                            {
                                "staveNote": {
                                    "id": "vf-auto1843270"
                                }
                            }
                        ],
                        "staffPosition": "top"
                    }
                ]
            }
        ],
        "currentMeasureIndex": 1
    },
    {
        "currentTimestamp": 1.75,
        "left": "",
        "currentVoiceEntries": [
            {
                "Notes": [
                    {
                        "length": 0.25,
                        "isRest": false,
                        "pitch": {
                            "accidental": 2,
                            "fundamentalNote": 0,
                            "octave": 1,
                            "halfTone": 48,
                            "frequency": 261.6255653005986
                        },
                        "printObject": true,
                        "graphics": [
                            {
                                "staveNote": {
                                    "id": "vf-auto1843278"
                                }
                            }
                        ],
                        "staffPosition": "top"
                    }
                ]
            }
        ],
        "currentMeasureIndex": 1
    },
    {
        "currentTimestamp": 2,
        "left": "",
        "currentVoiceEntries": [
            {
                "Notes": [
                    {
                        "length": 1,
                        "isRest": false,
                        "pitch": {
                            "accidental": 2,
                            "fundamentalNote": 0,
                            "octave": 1,
                            "halfTone": 48,
                            "frequency": 261.6255653005986
                        },
                        "printObject": true,
                        "graphics": [
                            {
                                "staveNote": {
                                    "id": "vf-auto1843294"
                                }
                            }
                        ],
                        "staffPosition": "top"
                    }
                ]
            },
            {
                "Notes": [
                    {
                        "length": 1,
                        "isRest": true,
                        "printObject": false,
                        "graphics": [
                            {
                                "staveNote": {
                                    "id": "vf-auto1843301"
                                }
                            }
                        ],
                        "staffPosition": "bottom"
                    }
                ]
            }
        ],
        "currentMeasureIndex": 2
    },
    {
        "currentTimestamp": 3,
        "left": "",
        "currentVoiceEntries": [
            {
                "Notes": [
                    {
                        "length": 0.25,
                        "isRest": false,
                        "pitch": {
                            "accidental": 2,
                            "fundamentalNote": 0,
                            "octave": 1,
                            "halfTone": 48,
                            "frequency": 261.6255653005986
                        },
                        "printObject": true,
                        "graphics": [
                            {
                                "staveNote": {
                                    "id": "vf-auto1843308"
                                }
                            }
                        ],
                        "staffPosition": "top"
                    }
                ]
            },
            {
                "Notes": [
                    {
                        "length": 1,
                        "isRest": true,
                        "printObject": false,
                        "graphics": [
                            {
                                "staveNote": {
                                    "id": "vf-auto1843333"
                                }
                            }
                        ],
                        "staffPosition": "bottom"
                    }
                ]
            }
        ],
        "currentMeasureIndex": 3
    },
    {
        "currentTimestamp": 3.25,
        "left": "",
        "currentVoiceEntries": [
            {
                "Notes": [
                    {
                        "length": 0.5,
                        "isRest": false,
                        "pitch": {
                            "accidental": 2,
                            "fundamentalNote": 0,
                            "octave": 1,
                            "halfTone": 48,
                            "frequency": 261.6255653005986
                        },
                        "printObject": true,
                        "graphics": [
                            {
                                "staveNote": {
                                    "id": "vf-auto1843316"
                                }
                            }
                        ],
                        "staffPosition": "top"
                    }
                ]
            }
        ],
        "currentMeasureIndex": 3
    },
    {
        "currentTimestamp": 3.75,
        "left": "",
        "currentVoiceEntries": [
            {
                "Notes": [
                    {
                        "length": 0.25,
                        "isRest": false,
                        "pitch": {
                            "accidental": 2,
                            "fundamentalNote": 0,
                            "octave": 1,
                            "halfTone": 48,
                            "frequency": 261.6255653005986
                        },
                        "printObject": true,
                        "graphics": [
                            {
                                "staveNote": {
                                    "id": "vf-auto1843324"
                                }
                            }
                        ],
                        "staffPosition": "top"
                    }
                ]
            }
        ],
        "currentMeasureIndex": 3
    }
]
`;

const phrase1 = `
[
  {
    "currentTimestamp": 0,
    "left": "",
    "currentVoiceEntries": [
      {
        "Notes": [
          {
            "length": 0.5,
            "isRest": false,
            "pitch": {
              "accidental": 2,
              "fundamentalNote": 7,
              "octave": 1,
              "halfTone": 55,
              "frequency": 391.99543598174927
            },
            "printObject": true,
            "graphics": [{ "staveNote": { "left": 0, "id": "vf-auto1208" } }],
            "staffPosition": "top"
          }
        ]
      },
      {
        "Notes": [
          {
            "length": 1,
            "isRest": true,
            "printObject": true,
            "graphics": [{ "staveNote": { "left": 0, "id": "vf-auto1246" } }],
            "staffPosition": "bottom"
          }
        ]
      }
    ],
    "currentMeasureIndex": 0
  },
  {
    "currentTimestamp": 0.5,
    "left": "",
    "currentVoiceEntries": [
      {
        "Notes": [
          {
            "length": 0.125,
            "isRest": false,
            "pitch": {
              "accidental": 2,
              "fundamentalNote": 9,
              "octave": 1,
              "halfTone": 57,
              "frequency": 440
            },
            "printObject": true,
            "graphics": [{ "staveNote": { "left": 0, "id": "vf-auto1216" } }],
            "staffPosition": "top"
          }
        ]
      }
    ],
    "currentMeasureIndex": 0
  },
  {
    "currentTimestamp": 0.625,
    "left": "",
    "currentVoiceEntries": [
      {
        "Notes": [
          {
            "length": 0.125,
            "isRest": false,
            "pitch": {
              "accidental": 2,
              "fundamentalNote": 11,
              "octave": 1,
              "halfTone": 59,
              "frequency": 493.8833012561241
            },
            "printObject": true,
            "graphics": [{ "staveNote": { "left": 0, "id": "vf-auto1226" } }],
            "staffPosition": "top"
          }
        ]
      }
    ],
    "currentMeasureIndex": 0
  },
  {
    "currentTimestamp": 0.75,
    "left": "",
    "currentVoiceEntries": [
      {
        "Notes": [
          {
            "length": 0.25,
            "isRest": false,
            "pitch": {
              "accidental": 2,
              "fundamentalNote": 0,
              "octave": 2,
              "halfTone": 60,
              "frequency": 523.2511306011972
            },
            "printObject": true,
            "graphics": [{ "staveNote": { "left": 0, "id": "vf-auto1236" } }],
            "staffPosition": "top"
          }
        ]
      }
    ],
    "currentMeasureIndex": 0
  },
  {
    "currentTimestamp": 1,
    "left": "",
    "currentVoiceEntries": [
      {
        "Notes": [
          {
            "length": 0.25,
            "isRest": false,
            "pitch": {
              "accidental": 2,
              "fundamentalNote": 7,
              "octave": 1,
              "halfTone": 55,
              "frequency": 391.99543598174927
            },
            "printObject": true,
            "graphics": [{ "staveNote": { "left": 0, "id": "vf-auto1253" } }],
            "staffPosition": "top"
          }
        ]
      },
      {
        "Notes": [
          {
            "length": 1,
            "isRest": true,
            "printObject": true,
            "graphics": [{ "staveNote": { "left": 0, "id": "vf-auto1297" } }],
            "staffPosition": "bottom"
          }
        ]
      }
    ],
    "currentMeasureIndex": 1
  },
  {
    "currentTimestamp": 1.25,
    "left": "",
    "currentVoiceEntries": [
      {
        "Notes": [
          {
            "length": 0.25,
            "isRest": true,
            "printObject": true,
            "graphics": [{ "staveNote": { "left": 0, "id": "vf-auto1261" } }],
            "staffPosition": "top"
          }
        ]
      }
    ],
    "currentMeasureIndex": 1
  },
  {
    "currentTimestamp": 1.5,
    "left": "",
    "currentVoiceEntries": [
      {
        "Notes": [
          {
            "length": 0.25,
            "isRest": false,
            "pitch": {
              "accidental": 2,
              "fundamentalNote": 9,
              "octave": 1,
              "halfTone": 57,
              "frequency": 440
            },
            "printObject": true,
            "graphics": [{ "staveNote": { "left": 0, "id": "vf-auto1267" } }],
            "staffPosition": "top"
          }
        ]
      }
    ],
    "currentMeasureIndex": 1
  },
  {
    "currentTimestamp": 1.75,
    "left": "",
    "currentVoiceEntries": [
      {
        "Notes": [
          {
            "length": 0.125,
            "isRest": false,
            "pitch": {
              "accidental": 2,
              "fundamentalNote": 7,
              "octave": 1,
              "halfTone": 55,
              "frequency": 391.99543598174927
            },
            "printObject": true,
            "graphics": [{ "staveNote": { "left": 0, "id": "vf-auto1275" } }],
            "staffPosition": "top"
          }
        ]
      }
    ],
    "currentMeasureIndex": 1
  },
  {
    "currentTimestamp": 1.875,
    "left": "",
    "currentVoiceEntries": [
      {
        "Notes": [
          {
            "length": 0.125,
            "isRest": false,
            "pitch": {
              "accidental": 2,
              "fundamentalNote": 5,
              "octave": 1,
              "halfTone": 53,
              "frequency": 349.2282314330039
            },
            "printObject": true,
            "graphics": [{ "staveNote": { "left": 0, "id": "vf-auto1285" } }],
            "staffPosition": "top"
          }
        ]
      }
    ],
    "currentMeasureIndex": 1
  },
  {
    "currentTimestamp": 2,
    "left": "",
    "currentVoiceEntries": [
      {
        "Notes": [
          {
            "length": 0.25,
            "isRest": false,
            "pitch": {
              "accidental": 2,
              "fundamentalNote": 4,
              "octave": 1,
              "halfTone": 52,
              "frequency": 329.6275569128699
            },
            "printObject": true,
            "graphics": [{ "staveNote": { "left": 0, "id": "vf-auto1304" } }],
            "staffPosition": "top"
          }
        ]
      },
      {
        "Notes": [
          {
            "length": 1,
            "isRest": true,
            "printObject": true,
            "graphics": [{ "staveNote": { "left": 0, "id": "vf-auto1337" } }],
            "staffPosition": "bottom"
          }
        ]
      }
    ],
    "currentMeasureIndex": 2
  },
  {
    "currentTimestamp": 2.25,
    "left": "",
    "currentVoiceEntries": [
      {
        "Notes": [
          {
            "length": 0.25,
            "isRest": false,
            "pitch": {
              "accidental": 2,
              "fundamentalNote": 2,
              "octave": 1,
              "halfTone": 50,
              "frequency": 293.6647679174076
            },
            "printObject": true,
            "graphics": [{ "staveNote": { "left": 0, "id": "vf-auto1312" } }],
            "staffPosition": "top"
          }
        ]
      }
    ],
    "currentMeasureIndex": 2
  },
  {
    "currentTimestamp": 2.5,
    "left": "",
    "currentVoiceEntries": [
      {
        "Notes": [
          {
            "length": 0.25,
            "isRest": false,
            "pitch": {
              "accidental": 2,
              "fundamentalNote": 0,
              "octave": 1,
              "halfTone": 48,
              "frequency": 261.6255653005986
            },
            "printObject": true,
            "graphics": [{ "staveNote": { "left": 0, "id": "vf-auto1320" } }],
            "staffPosition": "top"
          }
        ]
      }
    ],
    "currentMeasureIndex": 2
  },
  {
    "currentTimestamp": 2.75,
    "left": "",
    "currentVoiceEntries": [
      {
        "Notes": [
          {
            "length": 0.25,
            "isRest": false,
            "pitch": {
              "accidental": 2,
              "fundamentalNote": 4,
              "octave": 1,
              "halfTone": 52,
              "frequency": 329.6275569128699
            },
            "printObject": true,
            "graphics": [{ "staveNote": { "left": 0, "id": "vf-auto1328" } }],
            "staffPosition": "top"
          }
        ]
      }
    ],
    "currentMeasureIndex": 2
  },
  {
    "currentTimestamp": 3,
    "left": "",
    "currentVoiceEntries": [
      {
        "Notes": [
          {
            "length": 0.5,
            "isRest": false,
            "pitch": {
              "accidental": 2,
              "fundamentalNote": 2,
              "octave": 1,
              "halfTone": 50,
              "frequency": 293.6647679174076
            },
            "printObject": true,
            "graphics": [{ "staveNote": { "left": 0, "id": "vf-auto1344" } }],
            "staffPosition": "top"
          }
        ]
      },
      {
        "Notes": [
          {
            "length": 1,
            "isRest": true,
            "printObject": true,
            "graphics": [{ "staveNote": { "left": 0, "id": "vf-auto1361" } }],
            "staffPosition": "bottom"
          }
        ]
      }
    ],
    "currentMeasureIndex": 3
  },
  {
    "currentTimestamp": 3.5,
    "left": "",
    "currentVoiceEntries": [
      {
        "Notes": [
          {
            "length": 0.5,
            "isRest": false,
            "pitch": {
              "accidental": 2,
              "fundamentalNote": 0,
              "octave": 1,
              "halfTone": 48,
              "frequency": 261.6255653005986
            },
            "printObject": true,
            "graphics": [{ "staveNote": { "left": 0, "id": "vf-auto1352" } }],
            "staffPosition": "top"
          }
        ]
      }
    ],
    "currentMeasureIndex": 3
  }
]
`;

const TutorialData: Tutorial[] = [
  {
    level: 0,
    videoUrl: "level-0.mp4",
    end: "6:36",
    sections: [
      {
        chapters: [
          {
            start: "0:0",
            title: "The Piano",
            data: [ChapterType.Lecture],
          },
          {
            start: "0:12.2",
            title: "Highest and Lowest Keys",
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.PianoScene,
                  {
                    transitionDelay: 2400,
                    start: "A0",
                    end: "C8",
                    textPromptPosition: "Bottom",
                    objectives: [
                      {
                        text: "Play the key furthest to the left on your piano.",
                        target: "any",
                        videoCompleteDestination: "0:17.5",
                      },
                      {
                        text: "Play the key furthest to the right on your piano.",
                        target: "any",
                        videoCompleteDestination: "0:23.5",
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: "0:15",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "0:17",
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: "0:20.5",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "0:22",
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        chapters: [
          {
            start: "0:26",
            title: "Counting Beats",
            data: [ChapterType.Lecture],
          },
          {
            start: "0:56",
            title: "Tempo",
            data: [ChapterType.Lecture],
          },
          {
            start: "1:26",
            title: "Playing Beats in Time",
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.ExerciseTempoPractice,
                  {
                    objectives: [
                      {
                        videoSkipDestination: "1:34.8",
                        videoCompleteDestination: "1:34",
                        numBeats: 8,
                        tempo: 72,
                        text: "Use any key to tap out 8 beats in sync with the tempo.",
                        instructionWaitTime: 5,
                      },
                      {
                        //videoSkipDestination: "1:39",
                        //videoCompleteDestination: "1:38",
                        videoCompleteDestination: "1:43",
                        videoSkipDestination: "1:44",
                        numBeats: 8,
                        tempo: 120,
                        text: "Use any key to tap out 8 beats in sync with the tempo.",
                        instructionWaitTime: 5,
                      },
                      /*{
                        videoCompleteDestination: "1:43",
                        videoSkipDestination: "1:44",
                        numBeats: 8,
                        tempo: 40,
                        text: "Use any key to tap out 8 beats in sync with the tempo.",
                        instructionWaitTime: 5,
                      },*/
                    ],
                  },
                ],
                events: [
                  {
                    at: "1:30.5",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "1:33.5",
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true,
                  },
                  {
                    at: "1:35.5",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "1:37",
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true,
                  },
                  {
                    at: "1:40",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "1:41.5",
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        chapters: [
          {
            start: "1:44",
            title: "Music Notes",
            data: [ChapterType.Lecture],
          },
          {
            start: "2:26",
            title: "Quarter Notes",
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.NoteSequenceExercise,
                  {
                    transitionDelay: 2400,
                    tempo: 60,
                    objectives: [
                      {
                        scale: 1.4,
                        text: "Play four quarter notes in a row, using any key.",
                        videoSkipDestination: "2:36",
                        videoCompleteDestination: "2:34.5",
                        notes: [
                          { length: "quarter" },
                          { length: "quarter" },
                          { length: "quarter" },
                          { length: "quarter" },
                        ],
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: "2:30",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "2:33.5",
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              },
            ],
          },
          {
            start: "2:37",
            title: "Half Notes",
            data: [ChapterType.Lecture],
          },
          {
            start: "2:50",
            title: "Half Notes",
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.NoteSequenceExercise,
                  {
                    tempo: 60,
                    objectives: [
                      {
                        scale: 1.4,
                        text: "Play three half notes in a row, using any key.",
                        videoSkipDestination: "3:02",
                        videoCompleteDestination: "3:01",
                        notes: [
                          { length: "half" },
                          { length: "half" },
                          { length: "half" },
                        ],
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: "2:55",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "3:00",
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              },
            ],
          },
          {
            start: "3:03",
            title: "Whole Notes",
            data: [ChapterType.Lecture],
          },
          {
            start: "3:17",
            title: "Whole Notes",
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.NoteSequenceExercise,
                  {
                    tempo: 60,
                    paddingHorizontal: 100,
                    objectives: [
                      {
                        scale: 1.4,
                        text: "Play two whole notes in a row, using any key.",
                        videoSkipDestination: "3:26",
                        videoCompleteDestination: "3:25",
                        notes: [{ length: "whole" }, { length: "whole" }],
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: "3:20",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "3:24",
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        chapters: [
          {
            start: "3:26",
            title: "The Metronome",
            data: [ChapterType.Lecture],
          },
          {
            start: "3:51",
            title: "Combining Rhythms",
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.NoteSequenceExercise,
                  {
                    tempo: 72,
                    objectives: [
                      {
                        videoSkipDestination: "4:01",
                        videoCompleteDestination: "4:00",
                        scale: 0.8,
                        text: "Use any key to play these notes in time with the metronome",
                        notes: [
                          { length: "half" },
                          { length: "half" },
                          { length: "quarter" },
                          { length: "quarter" },
                          { length: "half" },
                        ],
                      },
                      {
                        videoSkipDestination: "4:04.5",
                        videoCompleteDestination: "4:04",
                        scale: 0.8,
                        text: "Use any key to play these notes in time with the metronome",
                        notes: [
                          { length: "quarter" },
                          { length: "quarter" },
                          { length: "quarter" },
                          { length: "quarter" },
                          { length: "half" },
                          { length: "half" },
                        ],
                      },
                      {
                        videoSkipDestination: "4:10",
                        videoCompleteDestination: "4:08",
                        scale: 0.8,
                        text: "Use any key to play these notes in time with the metronome",
                        notes: [
                          { length: "quarter" },
                          { length: "half" },
                          { length: "quarter" },
                          { length: "whole" },
                        ],
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: "3:56",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    pause: true,
                    at: "3:59",
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: "4:01.5",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    pause: true,
                    at: "4:03",
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: "4:05.5",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    pause: true,
                    at: "4:07",
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        chapters: [
          {
            start: "4:10",
            title: "The Grand Staff",
            data: [ChapterType.Lecture],
          },
          {
            start: "5:13",
            title: "Reading Notes on the Staff",
            data: [ChapterType.Lecture],
          },
          {
            start: "5:39",
            title: "Counting Bars",
            data: [ChapterType.Lecture],
          },
          {
            start: "5:59",
            title: "Time Signatures",
            data: [ChapterType.Lecture],
          },
        ],
      },
    ],
  },
  {
    end: "3:41",
    level: 1,
    videoUrl: "level-1.mp4",
    sections: [
      {
        chapters: [
          {
            start: "0:0",
            title: "Keys on the Piano",
            data: [ChapterType.Lecture],
          },
          {
            start: "0:20",
            title: "The White Keys",
            data: [ChapterType.Lecture],
          },
          {
            start: "1:04",
            title: "Middle C on the Keyboard",
            data: [ChapterType.Lecture],
          },
          {
            start: "1:17",
            title: "Find Middle C",
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.PianoScene,
                  {
                    start: "A0",
                    end: "C8",
                    transition: "Dissolve",
                    textPromptPosition: "Top",
                    objectives: [
                      {
                        text: "Play Middle C on your keyboard.",
                        target: "C4",
                        videoCompleteDestination: "1:20",
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: "1:18",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "1:19.5",
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        chapters: [
          {
            title: "Reading Middle C",
            start: "1:22",
            data: [ChapterType.Lecture],
          },
          {
            start: "2:11.5",
            title: "Alternating Thumbs",
            data: [ChapterType.Lecture],
          },
          {
            start: "3:08",
            title: "Middle C Practice",
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.StaffExercise,
                  {
                    objectives: [
                      {
                        tempo: 72,
                        text: "Play this line of music in sync with the metronome.",
                        paddingHorizontal: 20,
                        svgUrl: "assets/phrase1.svg",
                        jsonData: { notes: JSON.parse(phrase1), uuid: "test" },
                        timeSignature: { numerator: 4, denominator: 4 },
                        videoCompleteDestination: "3:16.5",
                        videoSkipDestination: "3:18",
                        phraseId:
                          "PNOb94a9de6cd68f9d67455a4332806fbf3-with-notes",
                      },
                      {
                        tempo: 72,
                        text: "Play this line of music in sync with the metronome.",
                        paddingHorizontal: 20,
                        svgUrl: "assets/phrase1.svg",
                        jsonData: { notes: JSON.parse(phrase1), uuid: "test" },
                        // jsonData: "assets/phrase1.json",
                        timeSignature: { numerator: 4, denominator: 4 },
                        videoCompleteDestination: "3:27.5",
                        videoSkipDestination: "3:29",
                        phraseId:
                          "PNOb94a9de6cd68f9d67455a4332806fbf3-with-notes",
                      },
                      {
                        tempo: 72,
                        text: "Play this line of music in sync with the metronome.",
                        paddingHorizontal: 20,
                        svgUrl: "assets/phrase1.svg",
                        jsonData: { notes: JSON.parse(phrase1), uuid: "test" },
                        timeSignature: { numerator: 4, denominator: 4 },
                        videoCompleteDestination: "3:34.5",
                        videoSkipDestination: "3:36",
                        phraseId:
                          "PNOb94a9de6cd68f9d67455a4332806fbf3-with-notes",
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: "3:11",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "3:15.5",
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: "3:18",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "3:26.5",
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: "3:29",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "3:33.5",
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    end: "2:14",
    level: 2,
    videoUrl: "level-2.mp4",
    sections: [
      {
        chapters: [
          {
            start: "0:0",
            title: "D in the Treble Clef",
            data: [ChapterType.Lecture],
          },
          {
            start: "0:20",
            title: "D on the Keyboard",
            data: [ChapterType.Lecture],
          },
          {
            start: "0:31",
            title: "Find D",
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.PianoScene,
                  {
                    start: "A0",
                    end: "C8",
                    transition: "Dissolve",
                    textPromptPosition: "Top",
                    objectives: [
                      {
                        text: "Play D on your keyboard.",
                        target: "D4",
                        videoCompleteDestination: "0:36",
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: "0:33",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "0:34.5",
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        chapters: [
          {
            title: "Finger Numbers",
            start: "0:38.5",
            data: [ChapterType.Lecture],
          },
          {
            title: "Playing D",
            start: "0:54",
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.NoTimeExercise,
                  {
                    firstObjectiveAppearDelay: 500,
                    padding_x: 230,
                    objectives: [
                      {
                        text: "Play these notes at your own pace.",
                        trebleNotes: [
                          ["D4", "quarter"],
                          ["D4", "quarter"],
                          ["D4", "quarter"],
                          ["D4", "quarter"],
                          ["D4", "quarter"],
                          ["D4", "quarter"],
                          ["D4", "quarter"],
                          ["D4", "quarter"],
                        ],
                        bassNotes: [],
                        videoSkipDestination: "1:11.5",
                        videoCompleteDestination: "1:10",
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: "0:55",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "1:03",
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true,
                  },
                ],
              },
            ],
          },
          {
            start: "1:11",
            title: "Playing with the 2nd Finger",
            data: [ChapterType.Lecture],
          },
          {
            start: "1:45",
            title: "Playing C and D",
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.NoTimeExercise,
                  {
                    centre_align_horizontal: true,
                    objectives: [
                      {
                        text: "Play these notes at your own pace.",
                        trebleNotes: [
                          ["D4", "quarter"],
                          ["C4", "quarter"],
                          ["D4", "quarter"],
                          ["C4", "quarter"],
                          ["D4", "quarter"],
                          ["C4", "quarter"],
                          ["D4", "quarter"],
                          ["C4", "quarter"],
                        ],
                        bassNotes: [],
                        videoSkipDestination: "1:58",
                        videoCompleteDestination: "1:56",
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: "1:47",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "1:50",
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true,
                  },
                ],
              },
            ],
          },
          {
            start: "2:01",
            title: "Playing in Time",
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.StaffExercise,
                  {
                    objectives: [
                      {
                        tempo: 72,
                        text: "Play this line of music in sync with the metronome.",
                        paddingHorizontal: 20,
                        svgUrl: "assets/phrase2.svg",
                        jsonData: { notes: JSON.parse(phrase2), uuid: "test" },
                        timeSignature: { numerator: 4, denominator: 4 },
                        videoCompleteDestination: "2:06",
                        videoSkipDestination: "2:07",
                        phraseId: "PNO120dc097cb6bb83c0f71b4188bd48002",
                        //phraseId:
                        //"PNOb94a9de6cd68f9d67455a4332806fbf3-with-notes",
                      },
                      {
                        tempo: 72,
                        text: "Play this line of music in sync with the metronome.",
                        paddingHorizontal: 20,
                        svgUrl: "assets/phrase2.svg",
                        jsonData: { notes: JSON.parse(phrase2), uuid: "test" },
                        timeSignature: { numerator: 4, denominator: 4 },
                        videoCompleteDestination: "2:10.5",
                        videoSkipDestination: "2:12",
                        phraseId: "PNO120dc097cb6bb83c0f71b4188bd48002",
                        //phraseId:
                        //"PNOb94a9de6cd68f9d67455a4332806fbf3-with-notes",
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: "2:02.5",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "2:04",
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: "2:07",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "2:08.5",
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    end: "2:46",
    level: 3,
    videoUrl: "level-3.mp4",
    sections: [
      {
        chapters: [
          {
            title: "B in the Bass Clef",
            start: "0:0",
            data: [ChapterType.Lecture],
          },
          {
            title: "B on the Keyboard",
            start: "0:25",
            data: [ChapterType.Lecture],
          },
          {
            title: "Find B",
            start: "0:39",
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.PianoScene,
                  {
                    start: "A0",
                    end: "C8",
                    transition: "Dissolve",
                    textPromptPosition: "Top",
                    objectives: [
                      {
                        text: "Play B on your keyboard.",
                        target: "B3",
                        videoCompleteDestination: "0:44",
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: "0:40.5",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "0:42",
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        chapters: [
          {
            title: "Playing with the 2nd Finger",
            start: "0:46",
            data: [ChapterType.Lecture],
          },
          {
            title: "Playing B",
            start: "0:56",
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.NoTimeExercise,
                  {
                    centre_align_horizontal: false,
                    padding_x: 700,
                    firstObjectiveAppearDelay: 1500,
                    objectives: [
                      {
                        text: "Play these notes at your own pace.",
                        trebleNotes: [
                          ["B3", "quarter"],
                          ["B3", "quarter"],
                          ["B3", "quarter"],
                          ["B3", "quarter"],
                          ["B3", "quarter"],
                          ["B3", "quarter"],
                          ["B3", "quarter"],
                          ["B3", "quarter"],
                        ],
                        bassNotes: [],
                        videoSkipDestination: "1:09",
                        videoCompleteDestination: "1:07",
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: "0:59",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "1:01",
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              },
            ],
          },
          {
            title: "Alternating Fingers",
            start: "1:09",
            data: [ChapterType.Lecture],
          },
          {
            title: "Playing B and C",
            start: "1:29.5",
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.NoTimeExercise,
                  {
                    centre_align_horizontal: true,
                    objectives: [
                      {
                        text: "Play these notes at your own pace.",
                        trebleNotes: [
                          ["D4", "quarter"],
                          ["C4", "quarter"],
                          ["D4", "quarter"],
                          ["C4", "quarter"],
                          ["D4", "quarter"],
                          ["C4", "quarter"],
                          ["D4", "quarter"],
                          ["C4", "quarter"],
                        ],
                        bassNotes: [],
                        videoSkipDestination: "1:40.5",
                        videoCompleteDestination: "1:39.5",
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: "1:31",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "1:32.5",
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                ],
              },
            ],
          },
          {
            title: "Playing in Time",
            start: "1:41",
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.StaffExercise,
                  {
                    objectives: [
                      {
                        tempo: 72,
                        text: "Play this line of music in sync with the metronome.",
                        paddingHorizontal: 20,
                        svgUrl: "assets/phrase2.svg",
                        jsonData: { notes: JSON.parse(phrase2), uuid: "test" },
                        timeSignature: { numerator: 4, denominator: 4 },
                        videoCompleteDestination: "1:49",
                        videoSkipDestination: "1:50",
                        phraseId: "PNO120dc097cb6bb83c0f71b4188bd48002",
                        //phraseId:
                        //"PNOb94a9de6cd68f9d67455a4332806fbf3-with-notes",
                      },
                      {
                        tempo: 72,
                        text: "Play this line of music in sync with the metronome.",
                        paddingHorizontal: 20,
                        svgUrl: "assets/phrase2.svg",
                        jsonData: { notes: JSON.parse(phrase2), uuid: "test" },
                        timeSignature: { numerator: 4, denominator: 4 },
                        videoCompleteDestination: "1:53.5",
                        videoSkipDestination: "1:54.5",
                        phraseId: "PNO120dc097cb6bb83c0f71b4188bd48002",
                        //phraseId:
                        //"PNOb94a9de6cd68f9d67455a4332806fbf3-with-notes",
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: "1:43",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "1:46.5",
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true,
                  },
                  {
                    at: "1:51",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "1:52",
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true,
                  },
                ],
              },
            ],
          },
          {
            title: "Using Both Hands",
            start: "1:55",
            data: [ChapterType.Lecture],
          },
          {
            title: "Switching Hands",
            start: "2:30.5",
            data: [
              ChapterType.Exercise,
              {
                scene: [
                  ExerciseScene.StaffExercise,
                  {
                    firstObjectiveAppearDelay: 500,
                    objectives: [
                      {
                        tempo: 72,
                        text: "Play this line of music in sync with the metronome.",
                        paddingHorizontal: 20,
                        svgUrl: "assets/phrase2.svg",
                        jsonData: { notes: JSON.parse(phrase2), uuid: "test" },
                        timeSignature: { numerator: 4, denominator: 4 },
                        videoCompleteDestination: "2:37",
                        videoSkipDestination: "2:38",
                        phraseId: "PNO120dc097cb6bb83c0f71b4188bd48002",
                        //phraseId:
                        //"PNOb94a9de6cd68f9d67455a4332806fbf3-with-notes",
                      },
                      {
                        tempo: 72,
                        text: "Play this line of music in sync with the metronome.",
                        paddingHorizontal: 20,
                        svgUrl: "assets/phrase2.svg",
                        jsonData: { notes: JSON.parse(phrase2), uuid: "test" },
                        timeSignature: { numerator: 4, denominator: 4 },
                        videoCompleteDestination: "2:42",
                        videoSkipDestination: "2:44",
                        phraseId: "PNO120dc097cb6bb83c0f71b4188bd48002",
                        //phraseId:
                        //"PNOb94a9de6cd68f9d67455a4332806fbf3-with-notes",
                      },
                    ],
                  },
                ],
                events: [
                  {
                    at: "2:33",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "2:35",
                    pause: true,
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                  },
                  {
                    at: "2:39.5",
                    phaserEvent: [ReactToExerciseEventType.UpdateObjective],
                  },
                  {
                    at: "2:41",
                    phaserEvent: [ReactToExerciseEventType.StartExercise],
                    pause: true,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default TutorialData;
