import { useState, useEffect } from "react"
import Paywall from "./Paywall"
import Payment from "./Payment"
import Confirmation from "./Confirmation"
import { useLocation } from "react-router-dom"
import { useSelector } from 'react-redux';
import { MainAppReducer } from 'Types';
import { isActiveSubscription } from "Utils/Subscription"
import axios from "axios"
import { AuthReducer } from "Types/AuthTypes"
import { getCurrentAuthToken } from "Utils/Amplify"

const PaymentFlow = () => {
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)

  const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/`
  const userData = data.userData

  const getBillingDetails = async () => {
    const authToken = await getCurrentAuthToken();
    try {
      const response = await axios.get<any>(
        `${baseUrl}stripe/user-billing-details`,
        {
          headers: {'Authorization': `Bearer ${authToken}`}
        }
      )
      return response.data.details
    } catch {
      return {"error": "could not get user billing details"}
    }
  }

  const [showPaywall, setShowPaywall] = useState(false)
  const [showPayment, setShowPayment] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [planTypeSelected, setPlanTypeSelected] = useState('')
  const [discountCodeValid, setDiscountCodeValid] = useState<boolean | null>(null)
  const [discountCode, setDiscountCode] = useState('')
  const [showDiscountCode, setShowDiscountCode] = useState(false)
  const [hasPaymentMethod, setHasPaymentMethod] = useState(null)

  const location = useLocation();
  // If paywall; not allowed to exit.
  let paywallExitAllowed = false//location.pathname !== '/lesson'

  useEffect(()=>{
    if(showConfirmation) {
      
    }
  },[showConfirmation])

  useEffect(() => {
    if (userData && userData.subscription_status != "") {
      getBillingDetails().then(resp => {
        if (resp.isCustomer) {
          setHasPaymentMethod(resp.hasPaymentMethodId)
        }
      })
    }
  }, [userData?.subscription_status]) // only trigger checking billing on subscription_status (will still fire on null subscription_status as desired.)

  useEffect(() => {
    if (userData?.coupon_code) { // If they have coupon code in userData; that means that the code was valid.
      // WE will need to ensure they are grandfathered in if we decide to rotate coupon codes (possibly query and update all users with a certain code and update them so that when the trial expires they will have discount!)
      setDiscountCode(userData?.coupon_code)
      setShowDiscountCode(true)
      setDiscountCodeValid(true)
    }
    if (hasPaymentMethod != null) {
      if (userData && userData.subscription_status && !isActiveSubscription(userData.subscription_status, hasPaymentMethod)) {
        setShowPaywall(true)
      } else {
        setShowPaywall(false)
      }
    }
  }, [userData, hasPaymentMethod])

  return (
    <>
      {showPaywall && <Paywall setShowPaywall={setShowPaywall} setShowPayment={setShowPayment} setPlanTypeSelected={setPlanTypeSelected} exitAllowed={paywallExitAllowed} discountCodeValid={discountCodeValid} setDiscountCodeValid={setDiscountCodeValid} discountCode={discountCode} setDiscountCode={setDiscountCode} showDiscountCode={showDiscountCode} setShowDiscountCode={setShowDiscountCode}/>}
      {showPayment && <Payment setShowPaywall={setShowPaywall} setShowPayment={setShowPayment} setShowConfirmation={setShowConfirmation} planTypeSelected={planTypeSelected} exitAllowed={false} discountCode={discountCode} discountCodeValid={discountCodeValid} setDiscountCodeValid={setDiscountCodeValid}/>}
      {showConfirmation && <Confirmation setShowConfirmation={setShowConfirmation}/>}
    </>
  )
}
export default PaymentFlow
