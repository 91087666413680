import { Box, ListItemIcon, ListItemText } from '@mui/material';
import MainApp from 'Containers/MainApp/MainApp'
import AutorenewIcon from '@mui/icons-material/Autorenew';
import LeftArrowIcon from 'assets/images/LeftArrowIcon.png'
import InfoIcon from 'assets/images/InfoIcon.png'
import Chevron1 from 'assets/images/Chevron1.png'
import ChevronSingle from 'assets/images/ChevronSingle.png'
import ChevronFilled from 'assets/images/ChevronFilled.png'
import ChevronLastFilled from 'assets/images/ChevronLastFilled.png'
import MinusIcon from 'assets/images/MinusIcon.png'
import PlusIcon from 'assets/images/PlusIcon.png'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux'
import * as appActions from 'Actions/app'
import * as eventActions from 'Actions/events'
import * as lessonActions from 'Actions/lesson'

import { ERROR_RECOGNITION_MODE, MainAppReducer } from 'Types';
// import { Levels } from '../../Constants/LevelData'
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef, useCallback, FC, ReactNode, StrictMode } from 'react';
import { BPM, MIN_NUM_PHRASES } from 'Utils/Constants'
import './Lesson.css'
import RepertoireUnlocked from './RepertoireUnlocked';
import TierWrapUp from './TierWrapUp';
import UnitComplete from './UnitComplete';
import FooterContainer from './Footer';
import { range } from 'lodash'
import { colors, inProgressContainerStyles, completeContainerStyles } from 'Components/RoadmapComponents/Path';
import {v4 as uuid} from 'uuid';
import { MUSEFLOW_ORANGE } from 'Components/Colors';
import { AuthReducer } from 'Types/AuthTypes';
import { LessonReducer } from 'Reducers/lessonReducer';
import { RepertoireReducer } from 'Types/RepertoireTypes';
import { WrapUpModalPage } from 'Types/LessonTypes';
import logger from 'Utils/Logger';
import { sortBy } from 'lodash';
import Modal from 'Components/TierWrapUp/Modal';
import { useMediaQueries } from 'Hooks/useMediaQueries';
import Navigation from 'Components/TierWrapUp/Navigation';
import BackToRoadmapButton from 'Components/TierWrapUp/BackToRoadmap';
import { useComponentWillUnmount } from 'Utils';

const activationAccuracyValue = 95
const minTempoForChevronFlash = 72

const smallScreenBreakWidth = 1200
const smallScreenBreakHeight = 900

const LessonPage = (props: any) => {
    // const { midiProps } = props
    // const location = useLocation();
    const dispatch = useDispatch();

    const navigate = useNavigate(); 
    const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
    // const levelData = data.levelData[data.levelSelect];
    const { currentLevelCompletedAt, currentUserLevelData, phrasesTilNextTier, currentLevelTiers, lessonPlaying, tierSummary, showWrapUp, wrapUpIndex} = useSelector((state: LessonReducer) => state.lessonReducer)
    const repertoireReducerData = useSelector((state: RepertoireReducer) => state.repertoireReducer)
    const { userLevelData, hasShownIntroduction } = data
    let levelSelect = data.levelSelect
    const [showTierComplete, setShowTierComplete] = useState(false)
    const [allowChevrons, setAllowChevrons] = useState(false)

    const [ accuracyPercent, setAccuracyPercent ] = useState<number | null>(0);
    const [ showAccuracy, setShowAccuracy ] = useState(false);

    const [currentTier, setCurrentTier] = useState(currentUserLevelData?.current_tier || 1)

    const [cursorIsVisible, setCursorIsVisible] = useState(true);
    const [newRepsUnlocked, setNewRepsUnlocked] = useState<any[]>([])
    const [initialLevelCompletedAt] = useState(currentUserLevelData?.level_completed_at)
     
    const repertoireData = repertoireReducerData?.repertoireData
    const levelComplete = currentUserLevelData?.current_tier && currentUserLevelData?.current_tier >= currentLevelTiers
    const levelCompleteFirstTime = !(currentUserLevelData?.level_completed_at) || (currentUserLevelData?.level_completed_at && new Date(currentUserLevelData?.level_completed_at).getTime() == currentLevelCompletedAt?.getTime())
    const unitComplete = currentUserLevelData?.level.level_number === sortBy(data.levelData, levelDatum => levelDatum.level_number).last().level_number

    const [carousel, setCarousel] = useState<ReactNode[]>([])
    const {
      maxHeight,
      maxWidth
    } = useMediaQueries()

    const mediaQueries = {maxHeight, maxWidth};

    const updateTier = useCallback((tier: number)=>{
      dispatch(lessonActions.updateCurrentULP({
        currentUserLevelProgress: {
          current_tier: tier
        },
      }))
    },[dispatch])

    const clearReps = useCallback(()=>{
      setNewRepsUnlocked([])
    },[setNewRepsUnlocked])

    
    useEffect(() => {
      if(showWrapUp) {
        const newCarousel = []
        console.log()
        if (levelComplete && repertoireData) {
          let newReps: any[] = []
          repertoireData?.forEach((rep) => {
            if (rep.level.level_number == currentUserLevelData.level.level_number) {
              newReps.push(rep)
            }
          })
          setNewRepsUnlocked(newReps)
          if(newReps.length > 0 && levelCompleteFirstTime) { 
            newCarousel.push(
              <RepertoireUnlocked 
                newRepsUnlocked={newReps}
                index={newCarousel.length}
              />
            )
          }
        
        }
        newCarousel.push(
          <TierWrapUp
            mediaQueries={mediaQueries}
          />
        )
        if(unitComplete) {
          newCarousel.push(
            <UnitComplete
              index={newCarousel.length}
            />
          )       
        }
        setCarousel(newCarousel)
      }
     
    }, [levelComplete, repertoireData, showWrapUp, levelCompleteFirstTime])

    const replayTier = useCallback(()=>{
      logger.debug("replay tier clicked")
      clearReps()
      dispatch(lessonActions.updatePhrasesTilNextTier(MIN_NUM_PHRASES))
      setAccuracyPercent(null)
      // await dispatch(lessonActions.resetLesson())
      dispatch(lessonActions.setTierSummary(null))
      dispatch(lessonActions.createLevelSession({
        level_number:  data?.levelSelect,
        tier_number: currentUserLevelData?.current_tier || 1,
        error_recognition_mode: ERROR_RECOGNITION_MODE.MIDI
      }))
      dispatch(lessonActions.setTierSummary(null))
      dispatch(lessonActions.setWrapUpIndex(0))
      dispatch(lessonActions.setShowWrapUp(false))
      setShowAccuracy(false)
      setAccuracyPercent(null)
    },[dispatch, setAccuracyPercent, currentUserLevelData?.current_tier, data.levelSelect, setShowAccuracy, setAccuracyPercent])

    const goToNextLevelOrTier = useCallback(()=>{
      clearReps()
      dispatch(lessonActions.updatePhrasesTilNextTier(MIN_NUM_PHRASES))
      if(levelComplete) {
        dispatch(appActions.setLevelSelect({
          levelSelect: data.levelSelect + 1
        }))
        dispatch(appActions.fetchTutorialData())
        updateTier(1)
        navigate('/tutorial')
      } else {
          dispatch(lessonActions.createLevelSession({
            level_number:  data?.levelSelect,
            tier_number: currentUserLevelData?.current_tier ? currentUserLevelData?.current_tier + 1 : 1,
            error_recognition_mode: ERROR_RECOGNITION_MODE.MIDI
          }))
      }
      dispatch(lessonActions.setTierSummary(null))
      dispatch(lessonActions.setWrapUpIndex(0))
      dispatch(lessonActions.setShowWrapUp(false))
      setShowAccuracy(false)
      setAccuracyPercent(null)
      // dispatch(lessonActions.updateCurrentULP({
      //   currentUserLevelProgress: {play_session: {id: null}},
      // }))
    },[dispatch, clearReps, updateTier, levelComplete, currentUserLevelData?.current_tier, data.levelSelect, setShowAccuracy, setAccuracyPercent])

    useEffect(()=>{
      if(showWrapUp &&  carousel.length > 0) {
        if(wrapUpIndex >= carousel.length) {
          goToNextLevelOrTier()
        } else if(wrapUpIndex < 0) {
          replayTier()
        }
      }
    },[wrapUpIndex])


    useEffect(() => {
      setCurrentTier(currentUserLevelData?.current_tier || 1)
    }, [currentUserLevelData?.current_tier])

    const updateCurrentTier =  useCallback((tier: number) => {
      if(tier !== currentTier) {
        setCurrentTier(tier)
        dispatch(lessonActions.createLevelSession({
          level_number: data?.levelData[levelSelect].level_number,
          tier_number: tier,
          error_recognition_mode: ERROR_RECOGNITION_MODE.MIDI
        }))
      }
    }, [currentTier])
    
    useEffect(() => {
      if(phrasesTilNextTier === 0) {
        setShowTierComplete(true)
        dispatch(lessonActions.setLessonPlaying({lessonPlaying: !lessonPlaying}))
      }
    }, [phrasesTilNextTier])

    const handleKeyDown = (e: any) => {
      // could also potentially check that no text fields are focued, but fine for now
      if (e.keyCode === 32) { //32 is space bar
        e.preventDefault(); //prevent scrollown with spacebar
      }
    };

    useEffect(() => {
      // check this... what if user-data errors?
      if((!userLevelData.length || userLevelData[0].highest_tier_complete == 0) && !hasShownIntroduction) {
      // if (!hasShownIntroduction) {
        // dispatch(appActions.setShownIntroduction(true))
        // Still navigating 
        navigate('/introduction')
      } else {
        // The not ideal way of restarting webworker... actually refreshing
        // const hasReloaded = localStorage.getItem('hasReloaded');
        // if (!hasReloaded || hasReloaded == 'false') {
          // localStorage.setItem('hasReloaded', 'true');
          // window.location.reload();
        // }

        window.addEventListener('keydown', handleKeyDown);
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }
    }, []);

    const backToRoadmap = useCallback(()=>{
      if(!levelComplete && currentUserLevelData?.current_tier) {
        // updateTier(lesson?.currentUserLevelData?.current_tier + 1)
      } 
      navigate('/roadmap')
    },[updateTier, currentUserLevelData?.current_tier])
    
    
      useComponentWillUnmount(()=>{
        dispatch(lessonActions.updatePhrasesTilNextTier(MIN_NUM_PHRASES))
        dispatch(lessonActions.setTierSummary(null))
        dispatch(lessonActions.setLessonProcessPending(false))
        dispatch(lessonActions.setShowWrapUp(false))
      })
  

//   carousel: CarouselElementState[]
    return (
      <>
          { !((!userLevelData.length || userLevelData[0].highest_tier_complete == 0) && !hasShownIntroduction) &&
            <Box sx = {{display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: 'white'}}>
                <Header showTierComplete={showTierComplete} currentTier={currentTier} updateCurrentTier={updateCurrentTier} showAccuracy={showAccuracy} accuracyPercent={accuracyPercent} levelSelect={levelSelect} phrasesTilNextTier={phrasesTilNextTier} allowChevrons={allowChevrons}/>
                <Box className="main-app-container-styles"
                  sx={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <StrictMode>

                  <MainApp showTierComplete={showTierComplete} currentTier={currentTier} setCurrentTier={setCurrentTier} updateCurrentTier={updateCurrentTier} showKeyboard={false} setAccuracy={setAccuracyPercent} setShowAccuracy={setShowAccuracy} setAllowChevrons={setAllowChevrons} cursorIsVisible={cursorIsVisible}
                  // midiProps={midiProps}
                  />
                  </StrictMode>

                </Box>
                {/* less than 0 or greater than length is what triggers next or previous action*/}
                {(wrapUpIndex >= 0 && wrapUpIndex < carousel.length) && showWrapUp && (
                  <Modal>
                  <BackToRoadmapButton
                    onClick={backToRoadmap}
                  />  
                  {carousel[wrapUpIndex]}
                    <Navigation
                      {...props}
                      sx={{
                        marginTop: 'auto',
                        bottom: '40px'
                      }}
                      nextButtonSx={{
                        width: '200px'
                      }}
                      backText={ wrapUpIndex > 0 ? "Back" : "Replay Tier" }
                      nextText={
                        wrapUpIndex >= carousel.length - 1 ? "Keep playing" : "Next"
                      } 
                      onBack={async () => {
                        dispatch(lessonActions.setWrapUpIndex(wrapUpIndex - 1))
                      }}
                      onNext={() => {
                        dispatch(lessonActions.setWrapUpIndex(wrapUpIndex + 1))
                      }}
                      carousel={carousel.map((_,indx) => indx === wrapUpIndex ? 'active' : 'inactive')}
                    />
                  </Modal>
                )}

                <FooterContainer cursorIsVisible={cursorIsVisible} setCursorIsVisible={setCursorIsVisible} />
            </Box>
          }
      </>
    )
}

const accuracyColors = [
  '#00C2FF', //neutral
  '#40E16D', // green
  '#EBC334', // yellow
  '#ff7d6f',
  // 'rgba(200, 128, 114, 1.0)',// '#EB3459', // red
]

interface HeaderProps {
  levelSelect: number;
  accuracyPercent: number | null;
  showAccuracy: boolean;
  phrasesTilNextTier: number;
  allowChevrons: boolean;
  updateCurrentTier: any;
  currentTier: number;
  showTierComplete: boolean
}

const Header = (props: HeaderProps) => {
    const { 
      accuracyPercent, 
      showAccuracy, 
      allowChevrons,
      updateCurrentTier,
      currentTier,
      showTierComplete
    } = props

    // const accuracyPercent = 0 // test value; comment out accuracyPercent from props above
    // const phrasesTilNextTier = 3 // test value; comment out phrasesTilNextTier from props above


    const [tempo, setTempo] = useState(BPM)

    const [isSmallScreen, setIsSmallScreen] = useState(false)//useState(window.innerWidth <= smallScreenBreakWidth || window.innerHeight <= smallScreenBreakHeight);
    useEffect(() => {
      const handleResize = () => { setIsSmallScreen(false)}//window.innerWidth <= smallScreenBreakWidth || window.innerHeight <= smallScreenBreakHeight); }
      window.addEventListener('resize', handleResize);
      return () => { window.removeEventListener('resize', handleResize); };
    }, []);

    const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
    const lessonData = useSelector((state: LessonReducer) => state.lessonReducer)
    const { currentUserLevelData, phrasesTilNextTier, currentLevelTiers, lessonPlaying } = useSelector((state: LessonReducer) => state.lessonReducer)
    const levelSelect = data.levelSelect
    const levelData = data.levelData
    const levelName = levelData[levelSelect].name
    const levelNumber = levelData[levelSelect].level_number
    const navigate = useNavigate();

    useEffect(()=>{console.log("phrases til next tier in header", phrasesTilNextTier)},[phrasesTilNextTier])

    const chevronPulseStyles = {
      height: '100%',
      animation: (allowChevrons && accuracyPercent && accuracyPercent >= activationAccuracyValue) ? `pulse ${60/Math.min(tempo, minTempoForChevronFlash)}s infinite linear` : '',
    }
    const chevronContainerStyles = {
      position: 'absolute',
      height:'100%'
    }

    const positionData = [
      { til: 4, filledSrc: ChevronFilled, src: Chevron1 },
      { til: 3, filledSrc: ChevronFilled, src: Chevron1 },
      { til: 2, filledSrc: ChevronFilled, src: Chevron1 },
      { til: 1, filledSrc: ChevronLastFilled, src: ChevronSingle },
    ];

    let accuracyColor
    if (accuracyPercent == 0) {
      accuracyColor = accuracyColors[0]
    } else if (accuracyPercent && accuracyPercent >= 95) {
      accuracyColor = accuracyColors[1]
    } else if (accuracyPercent && accuracyPercent >= 75) {
      accuracyColor = accuracyColors[2]
    } else {
      accuracyColor = accuracyColors[3]
    }

    const [infoAnchorEl, setInfoAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(infoAnchorEl);
    const handleInfoClick = (event: React.MouseEvent<HTMLDivElement>) => {
      setInfoAnchorEl(event.currentTarget);
    };
    const handleInfoClose = () => {
      setInfoAnchorEl(null);
    };

    return (
        <Box className="lesson-container-styles">
          { showTierComplete && <Box
            sx={{
              height: '100%',
              width: '100%',
              visibility: lessonData.phrasesTilNextTier === 0 ? 'unset' : 'hidden',
              backgroundColor: '#414753',
              opacity: '70%',
              position: 'absolute',
              zIndex: '998'
            }}
          />}
            <Box sx={{
                // background: 'linear-gradient(90deg, black, #1D2F44)', 
                background: 'linear-gradient(270deg, #1D2F44 28.99%, #071423 90.95%)',
                width:'100%', display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'space-between'
                }}>
                  <Box sx={{position: 'relative', marginLeft: '80px', display:'flex', flexDirection:'column',alignItems:'center'}}>
                    <Box sx={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                    <Box 
                      sx={backcontainerStyles} 
                      onClick={() => navigate('/roadmap')}
                    >
                      <img style={{height: '100%'}} src={LeftArrowIcon}/>
                    </Box>
                    <Box className="header-text-styles">Level {levelNumber}: {levelName}</Box>
                      <Box 
                        sx={{...backcontainerStyles, marginLeft: '10px',
                        border: '1px solid rgba(202, 124, 98, .8)', borderRadius: '7px', padding: '3px 8px',
                        display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px',
                        '&:hover': {cursor:'pointer'}}} 
                        onClick={handleInfoClick}
                        >
                          <img style={{height: '18px',}}src={InfoIcon}/>
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
                            <path d="M6.53151 4.97725L7.32365 5.74557L8.14993 4.96948L12.7227 0.44849L13.8975 1.62326L7.34521 8.17551L0.792969 1.62326L1.96774 0.44849L6.53151 4.97725Z" fill="#CA7C62"/>
                          </svg>
                      </Box>

                      <Menu
                        id="tutorial-info-menu"
                        anchorEl={infoAnchorEl}
                        open={open}
                        onClose={handleInfoClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                          sx:{
                            backgroundColor: 'black'
                          }
                        }}
                        sx={{
                          '.MuiListItemText-root': {
                            color: '#fff',
                            fontWeight: 'bold'
                          },
                          'bgcolor.MuiList-root-MuiMenu-list': {
                            backgroundColor: 'black',
                            color: '#fff'
                          },
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleInfoClose()
                            navigate('/tutorial')
                          }}>
                            <ListItemIcon
                              sx={{svg: {fill: MUSEFLOW_ORANGE}}}
                            >
                              <AutorenewIcon fontSize="medium" />
                            </ListItemIcon>
                            <ListItemText>Level Tutorial</ListItemText>
                        </MenuItem>
                        <MenuItem 
                          onClick={() => {
                            handleInfoClose()
                            navigate('/introduction')
                          }}
                        >
                          <ListItemIcon
                            sx={{svg: {fill: MUSEFLOW_ORANGE}}}
                          >
                            <AutorenewIcon fontSize="medium" />
                          </ListItemIcon>
                          <ListItemText>Game Tutorial</ListItemText>
                        </MenuItem>
                      </Menu>
                    </Box>
                    <Box sx={{
                      position: 'absolute', top: '44px', display: 'flex', justifyContent: 'space-between', 
                      width: (currentLevelTiers > 1) ? '370px' : '230px',
                      marginRight: '20px'}}>
                    {
                      range(1, currentLevelTiers + 1).map((tier) => {
                        const hasReachedTier = currentUserLevelData?.highest_tier_complete && currentUserLevelData?.highest_tier_complete >= tier
                        const isUnlocked = (tier == 1) || (currentUserLevelData?.highest_tier_complete && tier == currentUserLevelData?.highest_tier_complete +1)
                        let chosenStyles = {}
                        let bgColor = colors.background.incomplete.backgroundColor
                        if (hasReachedTier) {
                          chosenStyles = completeContainerStyles
                          bgColor = colors.background.complete.backgroundColor
                        } else if (isUnlocked) {
                          chosenStyles = {...inProgressContainerStyles, border: '3px solid #28856F'}
                          bgColor = '#ADB1B7'//colors.background.inProgress
                        }
                        return (
                            <Box 
                            key={uuid()}
                            sx={{
                              borderRadius: '99px',
                              backgroundColor: bgColor,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              flexGrow: 1, height: '14px', position: 'relative',
                              marginRight: '5px', marginLeft: '5px',
                              '&:hover': {
                                cursor: (hasReachedTier || isUnlocked) ? 'pointer' : '',
                                transition: (tier == currentTier || hasReachedTier || isUnlocked) ? '' : '0.3s',
                                boxShadow: ((tier == currentTier) || (tier != currentTier && !hasReachedTier && !isUnlocked)) ? '' : '0px 0px 5px 5px rgb(70, 159, 106,.5)'
                              },

                            }} 
                              onClick={() => {
                                // Weird bug that makes next tier chevrons full if clicked during tier complete dialogue
                                // so just make them not doing anything if phrases til next tier is zero
                                if ((hasReachedTier || isUnlocked) && !(phrasesTilNextTier === 0)) {
                                  updateCurrentTier(tier)
                                }
                              }}
                            >
                              { (tier == currentTier) && <Box sx={{
                                borderRadius: '10px', backgroundColor: 'rgba(70, 159, 106, 0.2)',
                                border: '2px solid rgba(70, 159, 106, .8)',
                                position: 'absolute', width: 'calc(100% + 4px)',
                                height: '18px',  zIndex: 10,
                              }}/>}
                            </Box>
                        )
                      })
                  }
                    </Box>
                  </Box>
                
                <TempoContainer tempo={tempo} setTempo={setTempo} isSmallScreen={isSmallScreen}/>
            </Box>
            
            <Box className="accuracy-container-styles">
                <Box sx={{height: '100%', display:'flex',flexDirection:'row', position: 'relative'}}>
                    <Box className="accuracy-container-styles2">
                        <Box className="accuracy-title-text-styles">Accuracy</Box>
                        {
                            showAccuracy && <Box className="accuracy-text-styles" sx={{color: accuracyColor}}>{accuracyPercent ? `${accuracyPercent?.toFixed(1)}%` : '--'}</Box>
                        }
                        {
                            !showAccuracy &&<Box className="accuracy-text-styles">--</Box>
                        }
                    </Box>
                    <Box className="first-chevron-styles"/>
                    {positionData.map((v, idx) => {
                      const curChevronDiff = v.til - phrasesTilNextTier;
                      const imgStyle = curChevronDiff === 0 ? chevronPulseStyles : { height: '100%' };
                      let imgSrc;
                      if (curChevronDiff > 0) {
                        imgSrc = v.filledSrc;
                      } else if (curChevronDiff === 0) {
                        imgSrc = (allowChevrons && accuracyPercent && accuracyPercent >= activationAccuracyValue) ? v.filledSrc : v.src;
                      } else {
                        imgSrc = v.src;
                      }
                      return (
                        <Box key={uuid()} className={`left-position-${idx+1}`} sx={{ ...chevronContainerStyles }}>
                          <img style={imgStyle} src={imgSrc} />
                        </Box>
                      );
                    })}

                </Box>
            </Box>
        </Box>
  )
}

const lineStyles = {
  height: '5px',
  // width: '200px',
  flexGrow: 1,
  borderRadius: '99px',
}

const circleStyles = {
  width: '26px',
  height: '26px',
  borderRadius: '50%',
}

const backcontainerStyles = {
    marginRight: '18px', 
    height:'25px',
    '&:hover': {
        background: 'rgba(222,144,118,.5)',
        cursor: 'pointer',
        transition: '0.3s',
        borderRadius:'50%',
        boxShadow: '0px 0px 5px 5px rgb(222,144,118,.5)',
      },
}

const TempoContainer = (props: any) => {
    const {
        isSmallScreen
    } = props
    const [amount, setAmount] = useState(0)
    const [message, setMessage] = useState('')
    const inputRef: any = useRef(null);
    const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
    const { tempo } = data;
    const dispatch = useDispatch()
    const tempoLowerLimit = 20;
    const tempoUpperLimit = 300;
    const goalTempo = 72;
    const setDispatchTempo = (tempo: number) => {
      dispatch(appActions.setTempo({tempo: tempo}))
    }

    useEffect(() => {
        setDispatchTempo(tempo) 
        if (inputRef.current) {
          inputRef.current.value = tempo
          inputRef.current.style.width = `${String(tempo).length}ch`;
        }
        if (tempo < goalTempo) {
          setAmount(goalTempo - tempo)
          setMessage("below")
        } else {// if (tempo == goalTempo) {
          setAmount(0)
          setMessage("at goal")
        }

    }, [tempo])

    const setTempoBlur = (val: string) => {
      let tempoVal = Math.round(parseFloat(val))
      if (isNaN(tempoVal) || tempoVal < tempoLowerLimit) tempoVal = tempoLowerLimit
      if (tempoVal > tempoUpperLimit) tempoVal = tempoUpperLimit
      dispatch(appActions.setTempo({tempo: tempoVal}))
    }

    return (
        <Box className="tempo-container-styles">
          { (!isSmallScreen) &&
            <Box sx={{
                textAlign: 'left',
                //fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '1px',
                textTransform: 'uppercase',
                color: '#ECECEC',
                marginBottom: '10px',
            }}>Tempo</Box>
          }
          <Box className="tempo-row-styles">
            { (isSmallScreen) &&
              <Box sx={{
                textAlign: 'left',
                //fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '1px',
                textTransform: 'uppercase',
                color: '#ECECEC',
                // marginBottom: '10px',
                marginRight: '10px',
              }}>Tempo</Box>
            }

            <Box sx={{
                background: '#ADB1B7',
                //border: '2px solid #ADB1B7',
                borderRadius: '4px',
                height: '40px',
                display: 'flex',
                flexDirection: 'row',
                alignItems:'center',
                justifyContent: 'space-between'
            }}>
                <Box className="tempo-dec-container-styles" onClick={() =>{
                  // setTempo(tempo > tempoLowerLimit ? tempo-1 : tempoLowerLimit)}
                  dispatch(appActions.setTempo({tempo: tempo > tempoLowerLimit ? tempo-1 : tempoLowerLimit}))
                }}>
                    <img style={{width: '40%'}} src={MinusIcon}/>
                    </Box>
                <Box sx={{
                    marginLeft:'10px',
                    marginRight:'10px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '0.01em',
                    color: '#414753',
                    minWidth: '80px',
                }}>
                  {/* {tempo}  */}
                <input type="text"
                  ref={inputRef}
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '0.01em',
                    color: '#414753',
                    background: '#ADB1B7',
                    border: 'none',
                    marginRight: '3px',
                }}
                  onChange={(e) => {
                    if (inputRef.current) {
                      inputRef.current.style.width = `${e.target.value.length}ch`;
                    }
                  }}
                  onBlur={(e) => {
                    setTempoBlur(e.target.value)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      if (inputRef.current) {
                        inputRef.current.blur();
                        setTempoBlur(e.currentTarget.value)
                      }
                    }
                  }}
                  />
                bpm</Box>
                <Box className="tempo-inc-container-styles" onClick={() => {
                  dispatch(appActions.setTempo({tempo: tempo < tempoUpperLimit ? tempo+1 : tempoUpperLimit}))
                }}>
                    <img style={{width: '40%'}} src={PlusIcon}/>
                    </Box>
            </Box>

            </Box>
            <Box sx={{
              marginLeft: isSmallScreen ? '70px':'',
              display:'flex', alignItems: 'center', justifyContent: 'center', marginTop: '8px'}}>
                <Box sx={{backgroundColor: amount != 0 ? '#EB3459':'#40E16D', opacity: amount != 0 ? '1.0':'0.5', width:'10px', height:'10px',borderRadius:'50%'}}></Box>
                <Box sx={{
                    marginLeft:'8px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '19px',
                    letterSpacing: '0.01em',
                    color: '#ECECEC'
                }}>
                  { amount != 0 &&
                    <>{amount} bpm </>
                  }
                  {message} tempo
                </Box>
            </Box>

        {/* </Box> */}
        </Box>
    )
}

export default LessonPage