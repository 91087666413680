import { Box, SxProps } from "@mui/material"

export type CarouselElementState = 'active' | 'inactive'

const Carousel = ({carousels, sx}: {carousels:CarouselElementState[], sx?:SxProps}) => {
  return (
    <Box sx={{display: 'flex', marginLeft: '10px', ...sx}}>
        {[...carousels].map((k,v) => {
          return <Box key={`carousel-${v}`} sx={{
            width: '12px', height: '12px', 
            backgroundColor: k === 'active' ? '#CA7C62' : '#5B646E', 
            borderRadius: '50%', marginRight: '10px'}}
          />
        })}
    </Box>
  )
}
export default Carousel