import Phaser from "phaser";
import RoundedRect from "./RoundedRect";
import { colors, hex } from "Phaser/config";

export type InstructionPosition = "Top" | "Bottom";

export default class Instruction {
  instructionText?: Phaser.GameObjects.Text;
  instructionTextBg?: RoundedRect;

  passFailIndicator?: Phaser.GameObjects.Image;
  resultTimer?: Phaser.Time.TimerEvent;
  scene: Phaser.Scene;

  constructor(
    scene: Phaser.Scene,
    instruction: string,
    position: InstructionPosition = "Top",
    vw?: number,
    vh?: number,
  ) {
    this.scene = scene;
    const dpr = window.devicePixelRatio;
    const offset = 100 * dpr;
    const viewportWidth = vw ?? scene.cameras.main.width - offset * 2;
    const viewportHeight = vh ?? scene.cameras.main.height;
    this.instructionText = scene.add.text(0, 0, instruction, {
      //fontSize: 30 * dpr,
      font: `bold ${38 * dpr}px Lato`,
      //fontFamily: "Lato Bold",
      color: "#000000",
      wordWrap: { width: viewportWidth },
    });
    const pos = position === "Bottom" ? viewportHeight - 200 * dpr : 100 * dpr;
    this.instructionText.setPosition(
      viewportWidth / 2 - this.instructionText.width / 2 + offset,
      pos,
    );
    this.instructionTextBg = new RoundedRect(
      scene,
      this.instructionText.x - 30 * dpr,
      this.instructionText.y - 30 * dpr,
      this.instructionText.width + 60 * dpr,
      this.instructionText.height + 60 * dpr,
      10 * dpr,
      colors.promptBgQuestion,
      1,
    );
    this.instructionTextBg.setDepth(-1);
    this.instructionText.setAlpha(0);
    this.instructionTextBg.setAlpha(0);
    scene.tweens.add({
      targets: [this.instructionText, this.instructionTextBg],
      alpha: 1.0,
      duration: 500,
      onUpdate: () => this.instructionTextBg?.draw(),
      callbackScope: this,
    });
  }

  showResult(positive: boolean, cb: () => void, scope?: object) {
    console.debug("result");
    this.resultTimer = this.scene.time.delayedCall(
      500,
      this._showResultInner,
      [positive, cb, scope],
      this,
    );
  }

  _showResultInner(positive: boolean, cb: () => void, scope?: object) {
    if (positive) this.scene.sound.play("success");
    console.log("showing result");
    this.passFailIndicator?.destroy();
    this.passFailIndicator = this.scene.add.image(
      this.scene.cameras.main.width / 2,
      this.instructionTextBg!.y + this.instructionTextBg!.height * 2,
      positive ? "checkmark" : "crossmark",
    );
    this.passFailIndicator.setY(
      this.passFailIndicator.y - this.passFailIndicator.displayHeight / 1.5,
    );
    const color = positive ? colors.promptBgCorrect : colors.promptBgWrong;
    this.instructionText?.setColor(hex(colors.bg));
    this.instructionTextBg?.setColor(color, 1);
    this.passFailIndicator.setScale(0, 1);
    this.scene.tweens.add({
      targets: this.passFailIndicator,
      scaleX: 1,
      ease: "Bounce",
      duration: 500,
      onComplete: () => {
        this.hide();
        cb.call(scope);
      },
      callbackScope: this,
    });
  }

  hide() {
    const targets: any[] = [this.instructionText, this.instructionTextBg];
    if (this.passFailIndicator) targets.push(this.passFailIndicator);
    console.debug(targets);
    console.log("hide");
    this.scene.tweens.add({
      targets,
      alpha: 0,
      duration: 500,
      onUpdate: () => this.instructionTextBg?.draw(),
    });
  }

  destroy() {
    this.instructionText?.destroy();
    this.instructionTextBg?.destroy();
    this.passFailIndicator?.destroy();
    this.resultTimer?.remove();
  }
}
