export const TICK_EVT_NAME = 'tickEvent';

export const BPM = 72

export const MIN_NUM_PHRASES = 4

export const TONE_SAMPLES = {
  urls: {
    A0: "A0.mp3",
    C1: "C1.mp3",
    "D#1": "Ds1.mp3",
    "F#1": "Fs1.mp3",
    A1: "A1.mp3",
    C2: "C2.mp3",
    "D#2": "Ds2.mp3", 
    "F#2": "Fs2.mp3",
    A2: "A2.mp3",
    C3: "C3.mp3",
    "D#3": "Ds3.mp3",
    "F#3": "Fs3.mp3",
    A3: "A3.mp3",
    C4: "C4.mp3",
    "D#4": "Ds4.mp3",
    "F#4": "Fs4.mp3",
    A4: "A4.mp3",
    C5: "C5.mp3",
    "D#5": "Ds5.mp3",
    "F#5": "Fs5.mp3",
    A5: "A5.mp3",
    C6: "C6.mp3",
    "D#6": "Ds6.mp3",
    "F#6": "Fs6.mp3",
    A6: "A6.mp3",
    C7: "C7.mp3",
    "D#7": "Ds7.mp3",
    "F#7": "Fs7.mp3",
    A7: "A7.mp3",
    C8: "C8.mp3"
  },
  release: 1,
  baseUrl: process.env.PUBLIC_URL + "/audio-samples/salamander/",
}

export const SURVEY_LINK = "https://forms.gle/96HoLpmKpUurUPem6"

export const ALLOWED_PATHS_ANY_PLATFORM = [
  '/roadmap', // default is roadmap; so have to include this (blocking screens applied in component)
  '/',
  '/signin',
  '/register',
  '/welcome',
  '/connect-midi',
  '/trial-welcome',
  '/verify'
  // '/sound-output', <- Maybe not include?
]

export enum UserTypes {
  USER = 'user'
}

export const TEMPO_SVG_HEIGHT = 42

export enum HANDEDNESS {
  LEFT = 'lh',
  RIGHT = 'rh'
}

export enum COLORBLIND_TYPE {
  RED_GREEN='RED_GREEN',
  FULL='FULL',
}

export enum ERROR_REC_CLASS {
  CORRECT = 'CORRECT',
  ERROR = 'ERROR',
  SEMI_ERROR = 'SEMI_ERROR'
}