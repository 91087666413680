import { Box } from "@mui/material";
import { MainAppReducer } from "Types";
import { range } from "lodash";
import { useSelector } from "react-redux";
import CheckmarkSmall from 'assets/images/CheckmarkSmall.png';
import { LessonReducer } from "Reducers/lessonReducer";
import { ReactNode } from "react";

const circleDiameter = 26;


const lineStyles = {
  height: '2px',
  // width: '200px',
  flexGrow: 1,
  borderRadius: '99px',
}

const circleStyles = {
  width: circleDiameter + 'px',
  height: circleDiameter + 'px',
  borderRadius: '50%',
}


const YetToCompleteTierCirlce = ({ tier}: {tier: number}) => {

  return (
    <Box sx={{
      ...circleStyles,
      backgroundColor: '#767C8C',
      fontWeight: 'bold',
      color: '#31353F',
      display: 'flex', alignItems: 'center', justifyContent: 'center', 
    }}>
      {tier}
    </Box>
  )
}

const TierCompleteCircle = ({isCurrentTier}: {isCurrentTier: boolean}) => {

  const TierCircle = () => (
    <>
    <Box sx={{
      ...circleStyles,
      backgroundColor: '#6F87F1',
      display: 'flex', alignItems: 'center', justifyContent: 'center', 
    }}>
      <img  src={CheckmarkSmall}/> {/*   The checkmark must be black  */}
    </Box>
  </>
  )

  const ParentTierCircle = ({children}: {children: ReactNode}) => (
    <Box
      sx={{
        height: (isCurrentTier ? circleDiameter + 10 : circleDiameter) + 'px',
        width: (isCurrentTier ? circleDiameter + 10 : circleDiameter) + 'px',
        borderRadius: '50%',
        outlineStyle: 'solid',
        outlineWidth: '3px',
        outlineColor: '#6F87F1',
        backgroundColor: 'rgba(111, 135, 241, 0.3)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {children}
    </Box>
  )
  return (
    isCurrentTier ? (
      <>
        <ParentTierCircle>
          <TierCircle/>
        </ParentTierCircle>
      </>
    ) : (
      <>
        <TierCircle/>
        <Box sx={{
          ...lineStyles,
          backgroundColor: '#6F87F1',
        }}/>
      </>
    )
  )

}

const Completion = () => {
  const lesson = useSelector((state: LessonReducer) => state.lessonReducer)

  return (

    <Box sx={{
      // marginTop: '60px',
      marginBottom: '20px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: lesson?.currentLevelTiers > 2 ? '70%' : '50%',
    }}>
      {
        lesson?.currentLevelTiers > 1 && range(1, lesson?.currentLevelTiers + 1).map((tier, indx) => {
          const hasReachedTier = (lesson?.currentUserLevelData?.current_tier && tier <= lesson?.currentUserLevelData?.current_tier)
           || (lesson.currentUserLevelData?.highest_tier_complete && lesson.currentUserLevelData?.highest_tier_complete >= tier)
          const isCurrentTier = lesson?.currentUserLevelData?.current_tier && tier === (lesson?.currentUserLevelData?.current_tier );
          const isLastTier =  tier === lesson.currentLevelTiers
          let width = '100%';
          if(isLastTier && isCurrentTier) {
            width = (circleDiameter + 10) + 'px'
          } else if(isLastTier) {
            width = circleDiameter + 'px'
          }
          return (
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: width
            }}
            key={indx}
            >
              {
                hasReachedTier ? (
                  <TierCompleteCircle isCurrentTier={isCurrentTier || false}/>
                ) : 
                (
                  <YetToCompleteTierCirlce tier={tier}  /> 
                )
              }
              { !isLastTier && (
                 <Box sx={{
                  ...lineStyles,
                  backgroundColor: '#6F87F1',
                }}/>
              )}
  
            </Box>
            
          )
        })
    }
     
    </Box>
  )
}

export default Completion


