import { mediaQueryResults } from 'Types/MediaQueries';

export enum mediaQueryValues {
  minHeight1100 = 1100,
  minHeight900 = 900,
  minHeight750 = 750,
  minWidth1000 = 1000,
  minWidth800 = 800
}

export enum mediaQueryMin {
  minHeight = mediaQueryValues.minHeight750,
  minWidth = mediaQueryValues.minWidth800
}

export const getMaxHeightWidth = (mediaQueryResults: mediaQueryResults) => {
  let maxHeight: number = mediaQueryMin.minHeight, maxWidth:number = mediaQueryMin.minWidth;

  if(mediaQueryResults[mediaQueryValues.minHeight750]) {
    maxHeight = mediaQueryValues.minHeight750
  }
  // These should go from lowest to highest to capture the max value
  if(mediaQueryResults[mediaQueryValues.minWidth800]) {
    maxWidth = mediaQueryValues.minWidth800
  }
  if(mediaQueryResults[mediaQueryValues.minWidth1000]) {
    maxWidth = mediaQueryValues.minWidth1000
  }

  if(mediaQueryResults[mediaQueryValues.minHeight900]) {
    maxHeight = mediaQueryValues.minHeight900
  }
  if(mediaQueryResults[mediaQueryValues.minHeight1100]) {
    maxHeight = mediaQueryValues.minHeight1100
  }
  return [maxHeight, maxHeight]
}
