import { useDispatch, useSelector } from "react-redux"
import * as appActions from 'Actions/app'
import * as lessonActions from 'Actions/lesson'
import { MainAppReducer } from 'Types';
import { Box } from '@mui/material';
import { CustomSwitch } from "Components";
import Play from 'assets/images/Play.png'; 
import Pause from 'assets/images/Pause.png'; 
import Fullscreen from 'Components/Fullscreen';
import { AuthReducer } from "Types/AuthTypes";
import { useMidiContext } from "Contexts/MidiContext";
import { LessonReducer } from "Reducers/lessonReducer";
import { CarouselElementState } from "Components/Carousel";

type FooterContainerProps = {
  setCursorIsVisible: (cursorIsVisible: boolean) => void;
  cursorIsVisible: boolean;
}

const FooterContainer = (props: FooterContainerProps) => {
  const dispatch = useDispatch()
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const lesson = useSelector((state: LessonReducer) => state.lessonReducer)
  const {
    userData,
  } = data

  const playLevel = () => {
      dispatch(lessonActions.setLessonPlaying({lessonPlaying: !lesson.lessonPlaying}))
  }

  const { cursorIsVisible, setCursorIsVisible} = props

  return (
    <Box sx={{
      display: 'flex',
      backgroundColor: '#050B13',
      height: '100px',
      minHeight: '100px',
      justifyContent:'space-between',
      position: 'relative'
    }}>
      <Box sx={{zIndex: 1, position: 'absolute', top: 34, left: 30}}>
        <Fullscreen show_label={false}/>
      </Box>
      <Box sx={{
        marginLeft: '86px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        letterSpacing: '0.01em',
        color:'#FFFFFF',
        display: "flex",
        alignItems: 'center',
        flexDirection: 'row'
        }}>
          <Box sx={{display:'flex',alignItems: 'center',justifyItems: 'center'}}>
            <CustomSwitch defaultChecked
              onChange={() => setCursorIsVisible(!cursorIsVisible)}
            />
            <Box>Cursor</Box>
          </Box>
          <Box sx={{display:'flex',alignItems: 'center',}}>
            {/* I think we're kind of using the state backwards here? I.e. the off UI is the on UI? */}
            <CustomSwitch checked={userData?.audio_on}
              onChange={() => {
                dispatch(appActions.setAudioOn({audioOn: !userData?.audio_on}))
              }}
            />
            <Box>Keyboard Sound</Box>
          </Box>
      </Box>
        <Box onClick={() => playLevel()}>
          <Box sx={playButtonStyles}>
            { lesson.lessonPlaying ?
              <img style={{maxWidth: '100%', maxHeight: '100%', margin: '0px'}} src={Pause}/>
            :
              <img style={{maxWidth: '100%', maxHeight: '100%', margin: '0px'}} src={Play}/>
            }
          </Box>
        </Box>
    </Box>
  )
}

export default FooterContainer

const playButtonStyles = {
  position: 'absolute',
  right: '40px',
  bottom: '30px',
  width: '105px',
  height: '105px',
  padding: '0px', margin: '0px',
  borderRadius: '50%',
  '&:hover': {
    background: 'rgba(212,134,108,1.0)',
    cursor: 'pointer',
    transition: '0.3s',
    boxShadow: '0px 0px 5px 5px rgb(212,134,108,.5)',
  },
}