import React, { useCallback, useEffect, useState } from 'react';
import { Box } from "@mui/system";
import { useNavigate } from 'react-router-dom';
import RoadmapTierComplete from 'assets/images/RoadmapTierComplete.png'
import {  useDispatch, useSelector } from 'react-redux'
import * as appActions from 'Actions/app';
import { MainAppReducer, ERROR_RECOGNITION_MODE, Repertoire } from 'Types';
import * as lessonActions from 'Actions/lesson';
import * as repertoireActions from 'Actions/repertoire';
import { LessonReducer } from 'Reducers/lessonReducer';
import { levelStyles,composerStyles, titleStyles } from 'Containers/Repertoire/RepertoireItem';
import { useMediaQueries } from 'Hooks/useMediaQueries';

const RepertoireUnlocked = (props: any) => {
  const dispatch = useDispatch();
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const lesson = useSelector((state: LessonReducer) => state.lessonReducer)

  const levelData = data.levelData[data.levelSelect];
  const { newRepsUnlocked,  } = props


  const navigate = useNavigate(); 
  const levelComplete = lesson?.currentUserLevelData?.current_tier && lesson?.currentUserLevelData?.current_tier >= lesson.currentLevelTiers
  
  const updateTier = useCallback((tier: number)=>{
    dispatch(lessonActions.updateCurrentULP({
      currentUserLevelProgress: {
        current_tier: tier// 3,
        // highest_tier_complete: 3,
        // status: LevelStatus.complete//"active"
      },
    }))
  },[dispatch])

  // useEffect(() => {
  // dispatch(appActions.updateCurrentULP({
  //   authToken: user?.signInUserSession?.accessToken?.jwtToken,
  //   currentUserLevelProgress: {
  //     current_tier: 1,
  //     highest_tier_complete: 0,
  //     // status: LevelStatus.complete//"active"
  //   }
  // }))
  // }, [])

  return (
    <>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems:'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
      { newRepsUnlocked.length > 0 &&
      
        <Box>
          <Box sx={{
            marginBottom: '50px',
            fontWeight: '400',
            fontStyle: 'normal',
            color: '#469F6A',

            letterSpacing: '0.01em',
            fontSize: '42px',
            lineHeight: '41px',
          }}>
          New Repertoire Unlocked!
          </Box>

        <Box sx={{display: 'flex', gap: '16px', marginBottom: '10px'}}>
          { newRepsUnlocked.map((rep: Repertoire, index: number) => {
            const previewSrc = rep.repertoire_png_preview_url
            const title = rep.name
            let composer = ""
            rep.composers.forEach((comp: any, idx: number) => {
              composer = composer.concat(idx > 0 ? `, ${comp.name}`: comp.name)
            })

            if (index <= 3) {
              return (
                <Box
                key={index}
                sx={{
                  marginBottom: '20px', marginTop: '14px', minWidth: '160px', maxWidth: '160px', height: '180px', backgroundColor: '#07111D', borderRadius: '6px', border: `2px solid ${'#CA7C62'}`, position: 'relative', 
                  '&:hover': {
                    cursor: 'pointer',
                    },
                  }}
                  onClick={() => {
                    dispatch(repertoireActions.setRepertoireSelect({repertoireIdSelect: rep.id}));
                    // Timeout because we want to set the state before navigating and using wrong repertoireId for anything
                    // Not ideal, Same as the code in RepertoireItem.tsx
                    setTimeout(() => {
                      navigate('/repertoire-play')
                    }, 150)
                  }}
                >
                <Box sx={{width: '100%', height: '100px', backgroundColor: 'white', position: 'relative', borderTopLeftRadius: '4px', borderTopRightRadius: '4px', borderBottom: `2px solid ${'#CA7C62'}`}}>
                  <Box sx={{paddingTop: '0px', height: '100px', overflow: 'hidden', marginLeft: '0px', marginRight: '0px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px', }}>
                    <img style={{width: '100%'}} src={previewSrc}/>
                  </Box>
                  <Box sx={{...levelStyles, ...{backgroundColor: '#31353F'}}}>LVL {levelData.level_number}</Box>
                    <Box sx={{height: '74px', paddingBottom: '6px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', paddingLeft: '8px'}}>
                      <Box sx={titleStyles}>{title}</Box>
                      <Box sx={composerStyles}>{composer}</Box>
                    </Box>
                  </Box>
                </Box>
              )
            }
          })}
       </Box>
        </Box>
      }
      </Box>
    </>
  )

}
export default RepertoireUnlocked
