import { COLORBLIND_TYPE, ERROR_REC_CLASS } from "Utils/Constants"


export const BLACK = '#000'
export const WHITE = '#fff'
export const MUSEFLOW_ORANGE = '#CA7C62'
export const MUSEFLOW_OFF_WHITE_1 = '#CED0D4'
export const MUSEFLOW_OFF_WHITE_2 = '#ADB1B7'
export const MUSEFLOW_OFF_WHITE_3 = '#8D929F'
export const ERROR_REC_GREEN = '#469f6a'
export const ERROR_REC_YELLOW = '#aebb1a'
export const ERROR_REC_RED = '#eb3459'
export const ERROR_REC_BLUE = '#0072B2'
export const ERROR_REC_ORGANGE = '#E69F00'
export const ERROR_REC_BROWN = '#D55E00'
export const ERROR_REC_WHITE = '#D9D9D9'
export const ERROR_REC_GREY = '#7F7F7F'
export const ERROR_REC_BLACK = '#404040'


export const error_rec_default = {
  [ERROR_REC_CLASS.CORRECT]: ERROR_REC_GREEN,
  [ERROR_REC_CLASS.SEMI_ERROR]: ERROR_REC_YELLOW,
  [ERROR_REC_CLASS.ERROR]: ERROR_REC_RED,
}

export const error_rec_blue_green_colorblind = {
  [ERROR_REC_CLASS.CORRECT]: ERROR_REC_BLUE,
  [ERROR_REC_CLASS.SEMI_ERROR]: ERROR_REC_ORGANGE,
  [ERROR_REC_CLASS.ERROR]: ERROR_REC_BROWN,
}

export const error_rec_full_colorblind = {
  [ERROR_REC_CLASS.CORRECT]: ERROR_REC_WHITE,
  [ERROR_REC_CLASS.SEMI_ERROR]: ERROR_REC_GREY,
  [ERROR_REC_CLASS.ERROR]: ERROR_REC_BLACK,
}

export const getColorByCbType = (error_rec_class: ERROR_REC_CLASS, cb_type: COLORBLIND_TYPE | null)=> {
  let mapping = error_rec_default;
  if(cb_type === COLORBLIND_TYPE.RED_GREEN) {
    mapping = error_rec_blue_green_colorblind;
  } else if(cb_type === COLORBLIND_TYPE.FULL) {
    mapping = error_rec_full_colorblind;
  }
  return mapping[error_rec_class];
} 
