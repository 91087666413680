import { useState, useCallback } from 'react'
import { Box, Button } from '@mui/material';
import WelcomeButton from './WelcomeButton'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import * as appActions from '../../Actions/app'
import MidiKeyboard from '../../assets/images/MidiKeyboard.png';
import MidiKeyboardSound from '../../assets/images/MidiKeyboardSound.png';
import Logo from 'Components/Logo';
import './trial_welcome.css'
import './welcome.css'
import { AuthReducer } from 'Types/AuthTypes';

const SoundOutput = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const auth = useSelector((state: AuthReducer) => state.authReducer)

  const [selected, setSelected] = useState('')

  const setAudioSetting = useCallback((audioOn: boolean)=>{
    dispatch(appActions.setAudioOn({
      audioOn
    }))
  },[])

  const onSubmit = () => {
    navigate('/roadmap')
  }
  return (
    <Box className="page-div-styles">
    <Box className="filter-div-styles">
    <Box className="sign-in-div-styles">
      <Logo/>
      <Box className="desc-container welcome-container">
        <h3 className="title-welcome" style={{marginTop: '50px'}}>Now let’s set up your sound output.</h3>
        <p className="p-connect-midi">
          Does your keyboard have built-in speakers?
        </p>
      </Box>
      
      <Box className="sound-output-container">
        <Button onClick={() => {
          setSelected('keyboard-sound')
          setAudioSetting(false)
        }}>
          <Box className="sound-output-selections" sx={{border: selected=='keyboard-sound' ? '2px solid #CA7C62' : '2px solid #525969'}}>
            <Box sx={{marginBottom: '27px'}}><img style={{maxWidth: '100%'}} src={MidiKeyboardSound}/></Box>
            <p>Yes, my keyboard has<br/>built-in speakers</p>
          </Box>
        </Button>
        <Box sx={{width: '40px'}}/>
        <Button onClick={() => {
          setSelected('computer-sound');
          setAudioSetting(true)
        }}>
          <Box className="sound-output-selections" sx={{border: selected=='computer-sound' ? '2px solid #CA7C62' : '2px solid #525969'}}>
            <Box sx={{marginBottom: '27px'}}><img style={{maxWidth: '100%'}} src={MidiKeyboard}/></Box>
            <p>No, my keyboard doesn't<br/>have built-in speakers</p>
          </Box>
        </Button>
      </Box>
      <Box className="connect-button-container">
        <WelcomeButton onClick={onSubmit} style={{backgroundColor: selected == '' ? '#767C8C' : '#CA7C62'}} disabled={selected == ''}>
          LET’S FLOW
        </WelcomeButton>
        </Box>
      </Box>
    </Box>
    </Box>
  )
}

export default SoundOutput;
