import { AuthState, authActionTypes, AuthActions, SIGN_IN_COMPONENT } from "Types/AuthTypes";
import immutable from 'immutable';


const defaultErrors = {
  code: "",
  emailOrPhone: "",
  name: "",
  password: "",
  repeatPassword: "",
  unknown: "",
  currentPassword: "",
  newPassword: "",
  repeatNewPassword: ""
}

export const defaultAuthState: AuthState = {
  idToken: undefined,
  jwtToken: undefined,
  authPending: true,
  user: undefined,
  processPending: false,
  pendingEmailOrPhone:undefined,
  pendingCountryAcronym: undefined,
  pendingEmailOrPhoneSuccessful: false,
  confirmationCode: undefined,
  errors: defaultErrors,
  signInComponent: SIGN_IN_COMPONENT.SIGNIN,
  successMessage: undefined,
  failureMessage: undefined,
  resetEmailOrPhone: undefined,
  resetCountryAcronym: undefined,
  tempSignUpPass: undefined
}

export const authReducer = (state = defaultAuthState, action: AuthActions): AuthState  => {
  switch(action.type) {
    case authActionTypes.SET_TOKEN:
      return immutable.merge(
        state,
        {
          jwtToken: action.payload.token,
          authPending: false
        }
      )
    case authActionTypes.SET_ID_TOKEN:
      return immutable.merge(
        state,
        {
          idToken: action.payload.idToken
        }
      )
    case authActionTypes.LOG_OUT:
      return immutable.merge(
        state,
        {
          idToken: undefined,
          jwtToken: undefined,
          authPending: false,
          user: undefined,
          processPending: false,
          pendingEmailOrPhone:undefined,
          pendingEmailOrPhoneSuccessful: false,
          confirmationCode: undefined,
          errors: defaultErrors
        }
      )
    case authActionTypes.SET_ERRORS:
      console.log("setting errors in reducer")
      return immutable.merge(
        state,
        {
          errors: action.payload
        }
      )
    case authActionTypes.RESET_ERRORS:
      return immutable.merge(
        state,
        {
          errors: defaultErrors
        }
      )
    case authActionTypes.SIGN_UP:
    case authActionTypes.FORGOT_PASSWORD:
      return immutable.merge(
        state,
        {
          processPending: true
        }
      )
    case authActionTypes.LOG_IN:
        return immutable.merge(
          state,
          {
            authPending: true
          }
        )
    case authActionTypes.SET_USER:
      return immutable.merge(
        state,
        {
          user: action.payload.user
        }
      )
    case authActionTypes.SET_AUTH_PENDING:
      return immutable.merge(
        state,
        {
          authPending: action.payload
        }
      )
    case authActionTypes.SET_PROCESS_PENDING:
      return immutable.merge(
        state,
        {
          processPending: action.payload
        }
      )
    case authActionTypes.SET_PENDING_EMAIL_OR_PHONE:
      console.log("setting pending email or phone")
      return immutable.merge(
        state,
        {
          pendingEmailOrPhone: action.payload
        }
      )
    case authActionTypes.SET_PENDING_COUNTRY_ACRONYM:
        console.log("setting pending email or phone")
        return immutable.merge(
          state,
          {
            pendingCountryAcronym: action.payload
          }
        )
    case authActionTypes.SET_PENDING_EMAIL_OR_PHONE_SUCCESSFUL:
      return immutable.merge(
        state,
        {
          pendingEmailOrPhoneSuccessful: action.payload
        }
      )
    case authActionTypes.SET_SIGN_IN_COMPONENT:
      console.log("setting sign in component to " + action.payload)
      return immutable.merge(
        state,
        {
          signInComponent: action.payload
        }
      )
    case authActionTypes.SET_SUCCESS_MESSAGE:
      return immutable.merge(
        state,
        {
          successMessage: action.payload
        }
      )
    case authActionTypes.SET_FAILURE_MESSAGE:
      return immutable.merge(
        state,
        {
          successMessage: action.payload
        }
      )
    case authActionTypes.SET_CONFIRMATION_CODE:
      return immutable.merge(
        state,
        {
          confirmationCode: action.payload
        }
      )
    case authActionTypes.SET_RESET_EMAIL_OR_PHONE:
      return immutable.merge(
        state,
        {
          resetEmailOrPhone: action.payload
        }
      )
    case authActionTypes.SET_RESET_COUNTRY_ACRONYM:
        return immutable.merge(
          state,
          {
            resetCountryAcronym: action.payload
          }
        )
    case authActionTypes.SET_TEMP_SIGNUP_PASS:
      return immutable.merge(
        state,
        {
          tempSignUpPass: action.payload
        }
      )
    default:
      return state
  }
} 