
import { Box, Button } from '@mui/material';  
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from "react-router-dom";
import { MainAppReducer } from 'Types';
import LeftArrowIcon from '../../assets/images/LeftArrowIcon.png'
import { useState, useEffect, useCallback } from 'react'
import * as appActions from 'Actions/app';
import * as lessonActions from 'Actions/lesson';
import IntroSlide1 from '../../assets/images/game_tutorials/1.svg'
import IntroSlide2 from '../../assets/images/game_tutorials/2.svg'
import IntroSlide3 from '../../assets/images/game_tutorials/3.svg'
import IntroSlide4 from '../../assets/images/game_tutorials/4.svg'
import IntroSlide5 from '../../assets/images/game_tutorials/5.svg'
import IntroSlide6 from '../../assets/images/game_tutorials/6.svg'
import IntroSlide7 from '../../assets/images/game_tutorials/7.svg'
import IntroSlide8 from '../../assets/images/game_tutorials/8.svg'
import IntroSlide9 from '../../assets/images/game_tutorials/9.svg'
import { MIN_NUM_PHRASES } from 'Utils/Constants';

const Introduction = () => {


  let location = useLocation();

  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  let levelSelect = data.levelSelect
  const [currentCarousel, setCurrentCarousel] = useState(1)
  let totalCarousels = 9
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(()=>{
    // init
    // Need to set phrases til next tier to 4 so that finished message doesn't pop up when
    // navigating to level
    dispatch(lessonActions.updatePhrasesTilNextTier(MIN_NUM_PHRASES));
  },[])


  // comment in the lines with setTutorialOpacity1 & 2 ( and set defaults below to 0 to show the transition effect)
  // Note that I will need to use ref and clear the animation
  // so that a user can go through different pages and it will resstart
  // these timeouts for transition
  const [tutorialOpacity1, setTutorialOpacity1] = useState(1)
  const [tutorialOpacity2, setTutorialOpacity2] = useState(1)
  useEffect(() => {
    if (tutorialOpacity1 == 0 && tutorialOpacity2 == 0) {
    setTimeout(() => {
      setTutorialOpacity1(1)
    }, 500)
    setTimeout(() => {
      setTutorialOpacity2(1)
    }, 3000)
    }
  }, [currentCarousel])

  const decTutorial = useCallback(() => {
    if (currentCarousel > 1) {
      setCurrentCarousel(currentCarousel-1)
    }
  }, [currentCarousel]);
  
  const incTutorial = useCallback(() => {
    if (currentCarousel < totalCarousels) {
      setCurrentCarousel(currentCarousel+1)
    } else {
      // Also mark off that they've seen it!
      dispatch(appActions.setShownIntroduction(true))
      navigate(`/lesson`)
    }
  }, [currentCarousel]);

  // Use arrow keys to navigate the tutoiral
  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'ArrowLeft') decTutorial()
    if (e.key === 'ArrowRight') incTutorial()
  }, [decTutorial, incTutorial]);
  
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <Box sx = {{display: 'flex', flexDirection: 'column', height: '100vh'}}>
    <Header levelSelect={levelSelect} currentCarousel={currentCarousel} setCurrentCarousel={setCurrentCarousel} />
    <Box sx={{minHeight: '400px', height:'100%',  }}>
      { currentCarousel == 1 && <ImgContainer image={<img style={{borderRadius: '20px', maxWidth: '100%', maxHeight: '100%'}} src={IntroSlide1} />}/> }
      { currentCarousel == 2 && <ImgContainer image={<img style={{borderRadius: '20px', maxWidth: '100%', maxHeight: '100%'}} src={IntroSlide2} />}/> }
      { currentCarousel == 3 && <ImgContainer image={<img style={{borderRadius: '20px', maxWidth: '100%', maxHeight: '100%'}} src={IntroSlide3} />}/> }
      { currentCarousel == 4 && <ImgContainer image={<img style={{borderRadius: '20px', maxWidth: '100%', maxHeight: '100%'}} src={IntroSlide4} />}/> }
      { currentCarousel == 5 && <ImgContainer image={<img style={{borderRadius: '20px', maxWidth: '100%', maxHeight: '100%'}} src={IntroSlide5} />}/> }
      { currentCarousel == 6 && <ImgContainer image={<img style={{borderRadius: '20px', maxWidth: '100%', maxHeight: '100%'}} src={IntroSlide6} />}/> }
      { currentCarousel == 7 && <ImgContainer image={<img style={{borderRadius: '20px', maxWidth: '100%', maxHeight: '100%'}} src={IntroSlide7} />}/> }
      { currentCarousel == 8 && <ImgContainer image={<img style={{borderRadius: '20px', maxWidth: '100%', maxHeight: '100%'}} src={IntroSlide8} />}/> }
      { currentCarousel == 9 && <ImgContainer image={<img style={{borderRadius: '20px', maxWidth: '100%', maxHeight: '100%'}} src={IntroSlide9} />}/> }
    </Box>
    <Footer 
      currentCarousel={currentCarousel} setCurrentCarousel={setCurrentCarousel} totalCarousels={totalCarousels}
      setTutorialOpacity1={setTutorialOpacity1} setTutorialOpacity2={setTutorialOpacity2}
      decTutorial={decTutorial} incTutorial={incTutorial}
    />
    </Box>
  )
}

const ImgContainer = (props: any) => {
  const { image } = props
  return (
    <>
    { image } 
    </>
  )
}


interface HeaderProps {
  levelSelect: number;
  currentCarousel: number;
  setCurrentCarousel: Function;
}

const Header = (props: HeaderProps) => {
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  
  const navigate = useNavigate();

  const onBack = () => {
    navigate('/roadmap')
  } 

  return (
    <Box sx={{display: 'flex',
      flexDirection: 'row',
      position: 'relative', height: '100px'}}>
      <Box sx={{
        width:'100%', display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'space-between'
      }}>
        <Box sx={{display:'flex', flexDirection:'row',alignItems:'center', justifyContent: 'space-between', width: '100%',}}>
          <Box sx={backcontainerStyles} onClick={() => onBack()}><img style={{height: '100%'}}src={LeftArrowIcon}/></Box>
          <Box sx={headerTextStyles}>How the Game Works</Box>
          <Box sx={{width: '120px'}}></Box>
        </Box>
      </Box>
    </Box>
  )
}

const headerTextStyles = {
  color:'#CA7C62',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '24px',
  letterSpacing: '0.01em'
}

const backcontainerStyles = {
  marginLeft: '80px', marginRight: '18px', height:'25px',
  '&:hover': {
      background: 'rgba(222,144,118,.5)',
      cursor: 'pointer',
      transition: '0.3s',
      borderRadius:'50%',
      boxShadow: '0px 0px 5px 5px rgb(222,144,118,.5)',
    },
}

const Footer = (props: any) => {
  const {
    currentCarousel, setCurrentCarousel, totalCarousels,
    decTutorial, incTutorial, setTutorialOpacity1, setTutorialOpacity2
  } = props
  return (
    <Box sx={{
      display: 'flex',
      backgroundColor: '#050B13',
      height: '100px',
      minHeight: '100px',
      justifyContent:'space-between',
      alignItems: 'center',
    }}>
      <Box>
        <Button 
          sx={{visibility: currentCarousel > 1 ? "" : "hidden"}}
          style={backButtonStyles} variant="contained" 
          onClick={() => {
            decTutorial()
          }}>Back
        </Button>
      </Box>
      <Box sx={{display: 'flex', marginLeft: '10px'}}>
        {[...Array(totalCarousels)].map((k,v) => {
          return <Box key={`carousel-${v}`} sx={{
            width: '12px', height: '12px', 
            backgroundColor: v == currentCarousel-1 ? '#CA7C62' : '#5B646E', 
            borderRadius: '50%', marginRight: '10px'}}
          />
        })}
      </Box>
      <Box>
        <Button 
          style={nextButtonStyles} variant="contained" 
          onClick={() => {
            incTutorial()
          }}>
            { totalCarousels == currentCarousel ? "Start" : "Next" }
        </Button>
      </Box>
    </Box>
  )
}

const nextButtonStyles = {
  margin: '15px 40px 15px 40px',
  width: '114px', 
  color: 'black', 
  fontSize: '16px',
  background: '#CA7C62', 
  boxShadow: '0px 3.61149px 1.20383px -2.40766px rgba(0, 0, 0, 0.2), 0px 2.40766px 2.40766px rgba(0, 0, 0, 0.14), 0px 1.20383px 6.01915px rgba(0, 0, 0, 0.12)', 
  borderRadius: '40px'
}
const backButtonStyles = {
  margin: '15px 40px 15px 40px',
  width: '114px', 
  color: '#CA7C62', 
  fontSize: '16px',
  border: '2px solid #CA7C62',
  background: 'none', 
  borderRadius: '40px'
}

export default Introduction
