import { Box } from '@mui/material';
import pianoIcon from '../../assets/images/pianoIcon.png';
import soundIcon from '../../assets/images/soundIcon.png';
import { useDispatch, useSelector } from 'react-redux'
import { MainAppReducer } from 'Types';
import * as appActions from '../../Actions/app'
import { AuthReducer } from 'Types/AuthTypes';
import { useCallback, useEffect, useState } from 'react';
import { CustomSwitch } from 'Components/StyledComponents'
import { COLORBLIND_TYPE } from 'Utils/Constants';

const Accessibility = () => {

  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const { userData } = data;
  useEffect(()=>{
    console.log("COLORBLOIND TYPE",userData?.colorblind_type, userData?.colorblind_type === COLORBLIND_TYPE.RED_GREEN)
  },[])
  const [fullColorblindSwitch, setFullColorblindSwitch] = useState(userData?.colorblind_type === COLORBLIND_TYPE.FULL);
  const [redGreenColorblindSwitch, setRedGreenColorblindSwitch] = useState(userData?.colorblind_type === COLORBLIND_TYPE.RED_GREEN);
  
  const dispatch = useDispatch()

  useEffect(()=>{
    if(fullColorblindSwitch){
      // setRedGreenColorblindSwitch(false)
      dispatch(appActions.setColorblindType(COLORBLIND_TYPE.FULL))
    }
    if(redGreenColorblindSwitch){
      // setFullColorblindSwitch(false)
      dispatch(appActions.setColorblindType(COLORBLIND_TYPE.RED_GREEN))
    }
    if(!fullColorblindSwitch && !redGreenColorblindSwitch) {
      dispatch(appActions.setColorblindType(null))
    }

  },[fullColorblindSwitch, redGreenColorblindSwitch])

  useEffect(()=>{
    console.log("userData?.colorblind_type === COLORBLIND_TYPE.FULL?",userData?.colorblind_type === COLORBLIND_TYPE.FULL)
    // if(userData?.colorblind_type === COLORBLIND_TYPE.FULL && !fullColorblindSwitch){
    //   setFullColorblindSwitch(true)
    // }
    // console.log("userData?.colorblind_type === COLORBLIND_TYPE.RED_GREEN?",userData?.colorblind_type === COLORBLIND_TYPE.RED_GREEN)

    // if(userData?.colorblind_type === COLORBLIND_TYPE.RED_GREEN && !redGreenColorblindSwitch){
    //   setRedGreenColorblindSwitch(true)
    // }
    // if(userData?.colorblind_type === null && (fullColorblindSwitch || redGreenColorblindSwitch)) {
    //   setRedGreenColorblindSwitch(false)
    //   setFullColorblindSwitch(false)
    // }

  },[userData?.colorblind_type])
  
  return (
    <Box sx={{height: 'calc(100vh - 200px)', overflow: 'scroll'}}>
    <Box sx={{
      marginLeft: '50px',
      width: '600px',
      backgroundColor: 'black',
      borderRadius: '5px',
      }}>
      <Box sx={{
        color: 'white',
        textAlign: 'left',
        marginLeft: '24px',
        marginRight: '24px',
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Box sx={{
          marginTop: '20px', 
          marginBottom: '20px',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '26px',
          letterSpacing: '0.01em',
          color: '#FFFFFF',
          fontFamily: 'Lato'
          }}>
        Color Theme
        </Box>
  
        <Box sx={{marginBottom: '20px', fontFamily: 'Lato'}}>
          <Box
            sx={{display: 'flex', flexDirection: 'vertical', alignItems: 'center'}}
          >
            <CustomSwitch 
              onChange={()=>{
                setRedGreenColorblindSwitch(!redGreenColorblindSwitch)
                if(!redGreenColorblindSwitch) {
                  setFullColorblindSwitch(false)
                }
              }}
              checked={redGreenColorblindSwitch}
            /> 
            <Box sx={{display:'flex', alignItems:'center', height: '25px'}}>Red/Blue Mode</Box>
          </Box>
          <Box
            sx={{display: 'flex', flexDirection: 'vertical', alignItems: 'center'}}
          >
            <CustomSwitch 
              onChange={()=>{
                setFullColorblindSwitch(!fullColorblindSwitch)
                if(!fullColorblindSwitch) {
                  setRedGreenColorblindSwitch(false)
                }
              }}
              checked={fullColorblindSwitch}
            /> 
            <Box sx={{display:'flex', alignItems:'center', height: '25px'}}>Monochrome Mode</Box>
          </Box>
        </Box>
      </Box>
    </Box>
    </Box>
  )
}

export default Accessibility
