enum LogLevel {
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error'
}

let logLevel: LogLevel = LogLevel.INFO


const logLevelByString: {[key: string]: LogLevel} = {
  'debug': LogLevel.DEBUG,
  'info': LogLevel.INFO,
  'warn': LogLevel.WARN,
  'error': LogLevel.ERROR
}

const logLevels = [
  LogLevel.DEBUG, 
  LogLevel.INFO,
  LogLevel.WARN,
  LogLevel.ERROR
]

if(!logLevels.includes(process.env.REACT_APP_LOG_LEVEL as LogLevel)){
  console.warn(`value ${process.env.REACT_APP_LOG_LEVEL} is not a recogized log level. Defaulting to info.`)
} else if (process.env.REACT_APP_LOG_LEVEL){
  logLevel = logLevelByString[process.env.REACT_APP_LOG_LEVEL]
}

const logLevelValues = {
  [LogLevel.DEBUG]: 1,
  [LogLevel.INFO]: 2,
  [LogLevel.WARN]: 3,
  [LogLevel.ERROR]: 5
}

class Logger {
  level: LogLevel
  constructor(level:LogLevel) {
    this.level = level;
  }

  info(...args: any[]) {
    if(logLevelValues[this.level] >= logLevelValues[LogLevel.INFO]) {
      console.log(...args)
    }
  }

  debug(...args: any[]) {
    if(logLevelValues[this.level] >= logLevelValues[LogLevel.DEBUG]) {
      console.log(...args)
    }
  }

  warn(...args: any[]) {
    if(logLevelValues[this.level] >= logLevelValues[LogLevel.WARN]) {
      console.warn(...args)
    }
  }

  error(...args: any[]) {
    if(logLevelValues[this.level] >= logLevelValues[LogLevel.ERROR]) {
      console.error(...args)
    }
  }

}

export default new Logger(logLevel)