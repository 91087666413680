import { ERROR_RECOGNITION_MODE, MidiEventState, MidiEventType } from "Types";


export interface EventState {
  sessionId: string | undefined,
  queue: EventTypes[]
}

export interface EventReducer {
  eventReducer: EventState,
}

export enum EventType {
  VisitEvent = "VisitEvent",
  PageViewEvent = "PageViewEvent",
  WelcomeLetterEvent = "WelcomeLetterEvent",
  SignInEvent = "SignInEvent",
  MidiConnectionSuccessEvent = "MidiConnectionSuccessEvent",
  MidiConnectionFailureEvent = 'MidiConnectionFailureEvent',
  RepertoireStartedEvent = "RepertoireStartedEvent",
  RepertoireCompletedEvent = "RepertoireCompletedEvent",
  RepertoirePlayingEvent = "RepertoirePlayingEvent",
  LevelPlayingEvent = 'LevelPlayingEvent',
  LevelPlayEvent = "LevelPlayEvent",
  LevelPauseEvent = "LevelPauseEvent",
  LevelStartedEvent = "LevelStartedEvent",
  LevelCompletedEvent = "LevelCompletedEvent",
  TierStartedEvent = "TierStartedEvent",
  TierCompletedEvent = "TierCompletedEvent",
  TutorialStartedEvent = 'TutorialStartedEvent',
  TutorialPageViewEvent = 'TutorialPageViewEvent',
  TutorialSkippedEvent = 'TutorialSkippedEvent',
  MobileBlockedEvent = 'MobileBlockedEvent',
  PhraseCompletedEvent = 'PhraseCompletedEvent',
  SafariBlockedEvent = 'SafariBlockedEvent',
  UserSystemDetailsEvent = 'UserSystemDetailsEvent',
  SetEventQueue = "SetEventQueue",
}


export enum EventActionTypes {
  SET_SESSION_ID = "SET_SESSION_ID",
  SET_EVENT_QUEUE = "SET_EVENT_QUEUE",
  DISPATCH_EVENTS = "DISPATCH_EVENTS",
  DISPATCH_EVENTS_SUCCESS = "DISPATCH_EVENTS_SUCCESS",
  FLUSH_QUEUE = "FLUSH_QUEUE",
  VISIT_EVENT_ACTION = 'VISIT_EVENT_ACTION',
  PAGE_VIEW_EVENT_ACTION = 'PAGE_VIEW_EVENT_ACTION',
  WELCOME_LETTER_EVENT_ACTION = 'WELCOME_LETTER_EVENT_ACTION',
  SIGN_IN_EVENT_ACTION = "SIGN_IN_EVENT_ACTION",
  MIDI_CONNECTION_SUCCESS_EVENT_ACTION = "MIDI_CONNECTION_SUCCESS_EVENT_ACTION",
  MIDI_CONNECTION_FAILURE_EVENT_ACTION = 'MIDI_CONNECTION_FAILURE_EVENT_ACTION',
  LEVEL_PLAYING_EVENT_ACTION = 'LEVEL_PLAYING_EVENT_ACTION',
  LEVEL_PLAY_EVENT_ACTION = 'LEVEL_PLAY_EVENT_ACTION',
  LEVEL_PAUSE_EVENT_ACTION = 'LEVEL_PAUSE_EVENT_ACTION',
  LEVEL_STARTED_EVENT_ACTION = 'LEVEL_STARTED_EVENT_ACTION',
  LEVEL_COMPLETED_EVENT_ACTION = 'LEVEL_COMPLETED_EVENT_ACTION',
  REPERTOIRE_STARTED_EVENT_ACTION = "REPERTOIRE_STARTED_EVENT_ACTION",
  REPERTOIRE_PLAYING_EVENT_ACTION = "REPERTOIRE_PLAYING_EVENT_ACTION",
  REPERTOIRE_COMPLETED_EVENT_ACTION = "REPERTOIRE_COMPLETED_EVENT_ACTION",
  TIER_STARTED_EVENT_ACTION = 'TIER_STARTED_EVENT_ACTION',
  TIER_COMPLETED_EVENT_ACTION = 'TIER_COMPLETED_EVENT_ACTION',
  TUTORIAL_PAGE_VIEW_EVENT_ACTION = 'TUTORIAL_PAGE_VIEW_EVENT_ACTION',
  TUTORIAL_SKIPPED_EVENT_ACTION = 'TUTORIAL_SKIPPED_EVENT_ACTION',
  MOBILE_BLOCKED_EVENT_ACTION = 'MOBILE_BLOCKED_EVENT_ACTION',
  TUTORIAL_STARTED_EVENT_ACTION = 'TUTORIAL_STARTED_EVENT_ACTION',
  PHRASE_COMPLETED_EVENT_ACTION = 'PHRASE_COMPLETED_EVENT_ACTION',
  SAFARI_BLOCKED_EVENT_ACTION = 'SAFARI_BLOCKED_EVENT_ACTION',
  USER_SYSTEM_DETAILS_EVENT_ACTION = 'USER_SYSTEM_DETAILS_EVENT_ACTION',
}


export type PhraseNoteError = {
  note_error_type: MidiEventState,
  note: number | null,
  is_rest: boolean,
  tick: number,
  additional_note: number | null
}

export type MidiData = {midiEvent: MidiEventType, midiNote: number, timestamp: number}

export type BaseEvent = {
  datetime: string;
  analytics_session_id: string;
}

export type VisitEvent = {
  event_type: EventType.VisitEvent,
}

export type PageViewEvent = {
  event_type: EventType.PageViewEvent,
  page: string
}

export type WelcomeLetterEvent = {
  event_type: EventType.WelcomeLetterEvent,
}

export type SignInEvent = {
  event_type: EventType.SignInEvent
}

export type MidiConnectionSuccessEvent = {
  event_type: EventType.MidiConnectionSuccessEvent,
}

export type MidiConnectionFailureEvent = {
  event_type: EventType.MidiConnectionFailureEvent,
}

export type LevelPlayingEvent = {
  event_type: EventType.LevelPlayingEvent,
  play_time: number,
  level_number: number,
  tier_number: number,
  play_session_id: string
}

export type LevelPlayEvent = {
  event_type: EventType.LevelPlayEvent,
  level_number: number,
  tier_number: number,
  play_session_id: string
}

export type LevelPauseEvent = {
  event_type: EventType.LevelPauseEvent,
  level_number: number,
  tier_number: number,
  play_session_id: string
}


export type LevelStartedEvent = {
  event_type: EventType.LevelStartedEvent,
  level_number: number,
  play_session_id: string
}


export type LevelCompletedEvent = {
  event_type: EventType.LevelCompletedEvent,
  level_number: number,
  play_session_id: string
}

export type RepertoirePlayingEvent = {
  event_type: EventType.RepertoirePlayingEvent,
  repertoire_id: number,
  play_session_id: string
}

export type RepertoireStartedEvent = {
  event_type: EventType.RepertoireStartedEvent,
  repertoire_id: number,
  play_session_id: string
}

export type RepertoireCompletedEvent = {
  event_type: EventType.RepertoireCompletedEvent,
  repertoire_id: number,
  play_session_id: string
}

export type TierStartedEvent = {
  event_type: EventType.TierStartedEvent,
  level_number: number,
  tier_number: number,
  play_session_id: string
}


export type TierCompletedEvent = {
  event_type: EventType.TierCompletedEvent,
  level_number: number,
  tier_number: number,
  play_session_id: string
}

export type TutorialStartedEvent = {
  event_type: EventType.TutorialStartedEvent,
  level_number: number,
}

export type TutorialPageViewEvent = {
  event_type: EventType.TutorialPageViewEvent,
  level_number: number,
  page_number: number
}

export type TutorialSkippedEvent =  {
  event_type: EventType.TutorialSkippedEvent
  level_number: number,
}

export type MobileBlockedEvent =  {
  event_type: EventType.MobileBlockedEvent
}

export type PhraseCompletedEvent = {
  event_type: EventType.PhraseCompletedEvent,
  midi_data: MidiData[],
  error_data: PhraseNoteError[],
  accuracy: number | null,
  avg_lh_accuracy: number | null,
  avg_rh_accuracy: number | null,
  avg_running_accuracy: number | null,
  avg_lh_running_accuracy: number | null,
  avg_rh_running_accuracy: number | null,
  total_notes: number,
  error_score: number,
  phrase_s3_id: string,
  level_number: number,
  tier_number: number,
  error_recognition_mode: ERROR_RECOGNITION_MODE,
  bpm: number,
  play_session_id: string

}

export type SafariBlockedEvent = {
  event_type: EventType.SafariBlockedEvent;
}

export type UserSystemDetailsEvent =  {
  event_type: EventType.UserSystemDetailsEvent;
  browser?: string;
  browser_type?: string;
  os: string;
  screen_width: number;
  screen_height: number
  user_agent?: string;
  platform: string;
  browser_version?: string
}


export interface SetSessionId {
  type: EventActionTypes.SET_SESSION_ID;
}

export interface VisitEventAction {
  type: EventActionTypes.VISIT_EVENT_ACTION;
  payload: VisitEvent
}

export interface PageViewEventAction {
  type: EventActionTypes.PAGE_VIEW_EVENT_ACTION;
  payload: PageViewEvent
}

export interface WelcomeLetterEventAction {
  type: EventActionTypes.WELCOME_LETTER_EVENT_ACTION;
  payload: WelcomeLetterEvent
}

export interface SignInEventAction {
  type: EventActionTypes.SIGN_IN_EVENT_ACTION;
  payload: SignInEvent
}

export interface MidiConnectionSuccessEventAction {
  type: EventActionTypes.MIDI_CONNECTION_SUCCESS_EVENT_ACTION;
  payload: MidiConnectionSuccessEvent
}

export interface MidiConnectionFailureEventAction {
  type: EventActionTypes.MIDI_CONNECTION_FAILURE_EVENT_ACTION;
  payload: MidiConnectionFailureEvent
}

export interface LevelPlayingEventAction {
  type: EventActionTypes.LEVEL_PLAYING_EVENT_ACTION;
  payload: LevelPlayingEvent;
}

export interface LevelPlayEventAction {
  type: EventActionTypes.LEVEL_PLAY_EVENT_ACTION;
  payload: LevelPlayEvent;
}

export interface LevelPauseEventAction {
  type: EventActionTypes.LEVEL_PAUSE_EVENT_ACTION;
  payload: LevelPauseEvent;
}

export interface LevelStartedEventAction {
  type: EventActionTypes.LEVEL_STARTED_EVENT_ACTION;
  payload: LevelStartedEvent;
}

export interface LevelCompletedEventAction {
  type: EventActionTypes.LEVEL_COMPLETED_EVENT_ACTION;
  payload: LevelCompletedEvent;
}

export interface TierStartedEventAction {
  type: EventActionTypes.TIER_STARTED_EVENT_ACTION;
  payload: TierStartedEvent;
}

export interface TierCompletedEventAction {
  type: EventActionTypes.TIER_COMPLETED_EVENT_ACTION;
  payload: TierCompletedEvent;
}

export interface RepertoireStartedEventAction {
  type: EventActionTypes.REPERTOIRE_STARTED_EVENT_ACTION;
  payload: RepertoireStartedEvent;
}

export interface RepertoirePlayingEventAction {
  type: EventActionTypes.REPERTOIRE_PLAYING_EVENT_ACTION;
  payload: RepertoirePlayingEvent;
}

export interface RepertoireCompletedEventAction {
  type: EventActionTypes.REPERTOIRE_COMPLETED_EVENT_ACTION;
  payload: RepertoireCompletedEvent;
}

export interface TutorialStartedEventAction {
  type: EventActionTypes.TUTORIAL_STARTED_EVENT_ACTION;
  payload: TutorialStartedEvent;
}

export interface TutorialPageViewEventAction {
  type: EventActionTypes.TUTORIAL_PAGE_VIEW_EVENT_ACTION;
  payload: TutorialPageViewEvent;
}

export interface TutorialSkippedEventAction {
  type: EventActionTypes.TUTORIAL_SKIPPED_EVENT_ACTION;
  payload: TutorialSkippedEvent;
}

export interface MobileBlockedEventAction {
  type: EventActionTypes.MOBILE_BLOCKED_EVENT_ACTION;
  payload: MobileBlockedEvent;
}

export interface PhraseCompletedEventAction {
  type: EventActionTypes.PHRASE_COMPLETED_EVENT_ACTION;
  payload: PhraseCompletedEvent;
}

export interface SafariBlockedEventAction {
  type: EventActionTypes.SAFARI_BLOCKED_EVENT_ACTION;
  payload: SafariBlockedEvent;
}

export interface UserSystemDetailsEventAction {
  type: EventActionTypes.USER_SYSTEM_DETAILS_EVENT_ACTION;
  payload: UserSystemDetailsEvent;
}

export interface SetEventQueue {
  type: EventActionTypes.SET_EVENT_QUEUE;
  payload: EventTypes[]
}

export interface DispatchEvents {
  type: EventActionTypes.DISPATCH_EVENTS
}

export interface DispatchEventsSuccess {
  type: EventActionTypes.DISPATCH_EVENTS_SUCCESS
}

export type EventStateActions = 
  | SetSessionId
  | SetEventQueue
  | DispatchEvents
  | DispatchEventsSuccess

export type EventActions =
  | SignInEventAction
  | MidiConnectionSuccessEventAction
  | MidiConnectionFailureEventAction
  | LevelPlayingEventAction
  | LevelPlayEventAction
  | LevelPauseEventAction 
  | LevelCompletedEventAction
  | LevelStartedEventAction
  | RepertoireStartedEventAction
  | RepertoirePlayingEventAction
  | RepertoireCompletedEventAction
  | TierStartedEventAction
  | TierCompletedEventAction
  | TutorialStartedEventAction
  | TutorialPageViewEventAction
  | TutorialSkippedEventAction
  | MobileBlockedEventAction
  | PhraseCompletedEventAction
  | SafariBlockedEventAction
  | UserSystemDetailsEventAction
  | VisitEventAction
  | PageViewEventAction
  | WelcomeLetterEventAction

export type EventTypes = 
  | LevelPlayingEvent
  | LevelPlayEvent
  | LevelPauseEvent
  | LevelStartedEvent
  | LevelCompletedEvent
  | TierStartedEvent
  | TierCompletedEvent
  | RepertoireStartedEvent
  | RepertoireCompletedEvent
  | RepertoirePlayingEvent
  | MidiConnectionFailureEvent
  | MidiConnectionSuccessEvent
  | MobileBlockedEvent
  | PhraseCompletedEvent 
  | SafariBlockedEvent 
  | SignInEvent 
  | TutorialPageViewEvent 
  | TutorialSkippedEvent
  | TutorialStartedEvent 
  | UserSystemDetailsEvent 
  | VisitEvent
  | PageViewEvent
  | WelcomeLetterEvent;