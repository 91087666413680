
import { FC, useEffect } from 'react'
import { Box } from '@mui/material';
import { CustomCircularProgress } from 'Components/StyledComponents';
import { useNavigate } from "react-router-dom";
import ColoredLogo from '../../assets/images/ColoredLogo.png';
import pianoBackground from '../../assets/images/pianoBackground.png';
import { useDispatch, useSelector } from 'react-redux'
import { MainAppReducer } from 'Types';
import GoBackButton from 'Components/GoBackButton';
import SignUp from './SignUp'
import { useLocation } from 'react-router-dom';
import { MUSEFLOW_OFF_WHITE_1 } from 'Components/Colors';
import {useMediaQuery} from '@mui/material';
import IntroFlowBack from 'Components/IntroFlowBack';
import Logo from 'Components/Logo';
import { AuthReducer } from 'Types/AuthTypes';
import * as authActions from 'Actions/auth';
import { awsRum } from 'Utils/AwsRum';


const Signup: FC<{}> = () => {
    const navigate = useNavigate(); 
    const { userLevelData } = useSelector((state: MainAppReducer) => state.mainAppReducer)
    const dispatch = useDispatch();
    const auth = useSelector((state: AuthReducer) => state.authReducer)

    const matchesHeight = useMediaQuery('(max-height:800px)');
    const matchesWidth = useMediaQuery('(max-width:750px)');

  
    const {
      processPending,
      errors
    } = auth;

    useEffect(()=>{
      if(auth.jwtToken) {
        dispatch(authActions.setPendingEmailOrPhone(undefined))
        if(userLevelData.length) {
          navigate('/roadmap')
        } else { 
          navigate('/welcome')
        }
      }
    },[navigate, auth.jwtToken, userLevelData])
  

    return (
      <Box className="page-div-styles">
        <Box className="filter-div-styles">
          <Box className="sign-in-div-styles">
          <IntroFlowBack/>
            <Logo/>
            {
              processPending &&
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: '100px'
                }}
              >
                <CustomCircularProgress />
              </Box>
            }
            <Box>

            </Box>
              <h3 style={{marginTop: '50px', marginBottom: 0}}>Create an Account</h3>
              <SignUp
                goBackFn={()=>{
                  dispatch(authActions.resetErrors())
                  navigate('/signin')
                }}
              />
          </Box>
        </Box>
      </Box>      
  )
}

export default Signup;
