import { useState } from 'react'
import { FC } from 'react';
import { Button, Box, TextField, Link, FormGroup, FormControlLabel, Checkbox, Input } from '@mui/material';
import './paywall.css'
import WelcomeButton from '../TrialWelcome/WelcomeButton'
import { useNavigate } from 'react-router-dom';

interface ConfirmationProps {
  setShowConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
}

const Confirmation: FC<ConfirmationProps> = ({ setShowConfirmation }) => {
  const navigate = useNavigate();
  return (
    <>
    <Box className="modal-outer"/>
    <Box className="modal-inner2">
      <Box className="confirmation-container">
      <h2 className="confirmation-title">Thank you for your payment!</h2>
      <p className="confirmation-desc">We hope you continue enjoying MuseFlow.</p>
      <WelcomeButton onClick={() => {setShowConfirmation(false); navigate('/roadmap')}}>
        LET’S FLOW
      </WelcomeButton>
      </Box>
    </Box>
    </>
  )
}

export default Confirmation;

