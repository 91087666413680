import { MIN_NUM_PHRASES } from 'Utils/Constants';
import { AppActions, AppState, IUserData, UpdateUserData, actionTypes } from '../Types'
import { find } from 'lodash';
import { v4 as uuid } from 'uuid';
import logger from 'Utils/Logger';

export const defaultState: AppState = {
  errors: [],
  loading: false,
  data: [],
  unitSelect:1,
  levelSelect: 0,
  tempo: 120,
  tutorialData: [],
  tutorialDataProcessing: false,
  userLevelData: [],
  highestLevelCompleted: 0,
  levelData: [],
  tiersByLevels: [],
  midiInput: null,
  lastShownSurvey: null,
  isMidiConnected: null,
  hasShownIntroduction: null,
  userData: null,
  isFullScreen: false,
  repPageSelected: 'Home',
  errorRecognitionActive: {left: true, right: true},
  playOnStart: false,
}

export const mainAppReducer = (state = defaultState, action: AppActions) => {
  switch (action.type) {
    case actionTypes.FETCH_CLIENT_DATA_REQUEST:
      return {
        ...state,
        loading: true
      }

    case actionTypes.FETCH_CLIENT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        errors: []
      }
    
    case actionTypes.FETCH_CLIENT_DATA_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.error
      }

    case actionTypes.SET_LEVEL_SELECT:
      return {
        ...state,
        levelSelect: action.payload.levelSelect,
        currentUserLevelData: find(state.userLevelData, uld => uld.level?.level_number === state.levelData[action.payload.levelSelect]?.level_number),
        currentLevelTiers: find(state.tiersByLevels, tbl => tbl.level_number === state.levelData[action.payload.levelSelect].level_number)?.tiers
      }

    case actionTypes.SET_UNIT_SELECT:
      return {
        ...state,
        unitSelect: action.payload.unitSelect
      }
    
    case actionTypes.SET_TEMPO:
      return {
        ...state,
        tempo: action.payload.tempo
      }
    case actionTypes.SET_HIGHEST_LEVEL_COMPLETED:
      return {
        ...state,
        highestLevelCompleted: action.payload.highestLevelCompleted
      }

    case actionTypes.FETCH_TUTORIAL_DATA_SUCCESS:
      return {
        ...state,
        tutorialData: action.payload.tutorialData,
      }

    case actionTypes.FETCH_TUTORIAL_DATA_PROCESSING:
      return {
        ...state,
        tutorialDataProcessing: action.payload,
      }

    // case actionTypes.AUTHENTICATED_STARTUP:
    //   return {
    //     ...state,
    //     authToken: action.payload.authToken,
    //   }

    case actionTypes.FETCH_USER_LEVEL_SUCCESS:
      return {
        ...state,
        userLevelData: action.payload.userLevelData
      }

    case actionTypes.FETCH_LEVEL_DATA_SUCCESS:
      return {
        ...state,
        levelData: action.payload.levelData
      }

    case actionTypes.UPDATE_TIERS_BY_LEVELS:
      return {
        ...state,
        tiersByLevels: action.payload
      }

    case actionTypes.MIDI_INPUT_SETUP:
      return {
        ...state,
        midiInput: action.payload
      }

    case actionTypes.SET_SHOWN_INTRODUCTION:
      return {
        ...state,
        hasShownIntroduction: action.payload
      }
    
    case actionTypes.SET_USER_DATA:
      return {
        ...state,
        userData: action.payload
      }
    
    case actionTypes.UPDATE_USER_DATA:
      // this code checks for actual changes and otherwise returns state. (Prevents rerender on useEffect dependency)
      // way way more verbose because of type guard for keys
      if (state.userData) {
        let changesPresent = false;
        const isKeyOfIUserData = (key: keyof IUserData): boolean => {
          if (!state.userData) return false
          return key in state.userData;
        }      
        for (const key in action.payload) {
          const keyWithType: keyof IUserData = key as keyof IUserData; // WTF typescript...
          if (isKeyOfIUserData(keyWithType) && state.userData[keyWithType] !== action.payload[keyWithType]) {
            changesPresent = true;
            break;
          }
        }
        if (!changesPresent) return state;
      }

      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload,
        }
      }
    
    case actionTypes.LOAD_USER_STATE:
      return {
        ...state,
        ...action.payload
      }
    
    case actionTypes.SET_FULL_SCREEN:
      return {
        ...state,
        isFullScreen: action.payload
      }
    
    case actionTypes.SET_SUBSCRIPTION_STATUS:
      // Return state if subscription_status is not different; if returns spread of userData WILL trigger useEffect on userData
      if (!state.userData ||state.userData.subscription_status == action.payload) {
        return state;
      }
      return {
        ...state,
        userData: {
          ...state.userData,
          subscription_status: action.payload,
        }
      }

    case actionTypes.SET_REP_PAGE_SELECTED:
      return {
        ...state,
        repPageSelected: action.payload
      }

    case actionTypes.SET_ERROR_RECOGNITION_ACTIVE:
      return {
        ...state,
        errorRecognitionActive: action.payload
      }

    case actionTypes.SET_PLAY_ON_START:
      return {
        ...state,
        playOnStart: action.payload
      }
      
  default: return state
  }
}
