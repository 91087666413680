import { MIDIValInput } from '@midival/core'
import { 
  actionTypes, 
  FetchClientDataRequestPayload,
  FetchClientDataSuccessPayload, 
  FetchClientDataErrorPayload,
  SetLevelSelectPayload,
  SetUnitSelectPayload,
  SetTempoPayload,
  FetchTutorialDataPayload,
  FetchTutorialDataSuccessPayload,
  UserLevelDataSuccessPayload,
  SetHighestLevelCompletedPayload,
  LevelDataSuccessPayload,
  UpdateCurrentULPPayload,
  UserLevelData,
  TiersByLevels,
  IUserData,
  UserInfo,
  AppState,
  ErrorRecognitionActive,
  SetRepDownbeatsSound,
  SetRepMetronomeSound,
 } from '../Types'
import { COLORBLIND_TYPE } from 'Utils/Constants'

export const fetchClientDataRequest = (payload: FetchClientDataRequestPayload) => ({
  type: actionTypes.FETCH_CLIENT_DATA_REQUEST,
  payload
})
export const fetchClientDataSuccess = (payload: FetchClientDataSuccessPayload) => ({
  type: actionTypes.FETCH_CLIENT_DATA_SUCCESS,
  payload
})
export const fetchClientDataError = (payload: FetchClientDataErrorPayload) => ({
  type: actionTypes.FETCH_CLIENT_DATA_ERROR,
  payload
})

export const setPlayOnStart = (payload: boolean) => ({
  type: actionTypes.SET_PLAY_ON_START,
  payload
})

export const setLevelSelect = (payload: SetLevelSelectPayload) => ({
  type: actionTypes.SET_LEVEL_SELECT,
  payload
})
export const setUnitSelect = (payload: SetUnitSelectPayload) => ({
  type: actionTypes.SET_UNIT_SELECT,
  payload
})
export const setTempo = (payload: SetTempoPayload) => ({
  type: actionTypes.SET_TEMPO,
  payload
})

export const setHighestLevelCompleted = (payload: SetHighestLevelCompletedPayload) => ({
  type: actionTypes.SET_HIGHEST_LEVEL_COMPLETED,
  payload
})

export const fetchTutorialData = () => ({
  type: actionTypes.FETCH_TUTORIAL_DATA,
})

export const fetchTutorialDataProcessing = (payload: boolean) => ({
  type: actionTypes.FETCH_TUTORIAL_DATA_PROCESSING,
  payload
})

export const fetchTutorialDataSuccess = (payload: FetchTutorialDataSuccessPayload) => ({
  type: actionTypes.FETCH_TUTORIAL_DATA_SUCCESS,
  payload
})

export const midiInputSetUp = (payload: MIDIValInput) => ({
  type: actionTypes.MIDI_INPUT_SETUP,
  payload
})

export const authenticatedStartup = () => ({
  type: actionTypes.AUTHENTICATED_STARTUP,
})

export const fetchUserLevelsSuccess = (payload: UserLevelDataSuccessPayload) => ({
  type: actionTypes.FETCH_USER_LEVEL_SUCCESS,
  payload
})

export const fetchLevelDataSuccess = (payload: LevelDataSuccessPayload) => ({
  type: actionTypes.FETCH_LEVEL_DATA_SUCCESS,
  payload
})

export const updateTiersByLevels = (payload: TiersByLevels[]) => ({
  type: actionTypes.UPDATE_TIERS_BY_LEVELS,
  payload
})

export const setLastShowSurvey = (payload: {
  last_shown_survey: Date
}) => ({
  type: actionTypes.SET_LAST_SHOWN_SURVEY,
  payload
})

export const setShownIntroduction = (payload: boolean) => ({
  type: actionTypes.SET_SHOWN_INTRODUCTION,
  payload
})

export const setUserData = (payload: Partial<IUserData>) => ({
  type: actionTypes.SET_USER_DATA,
  payload
})

export const updateUserData = (payload: Partial<IUserData>) => ({
  type: actionTypes.UPDATE_USER_DATA,
  payload
})

export const setAudioOn = (payload: {
  audioOn: boolean
}) => ({
  type: actionTypes.SET_AUDIO_ON,
  payload: payload
})

export const setColorblindType = (coloblindType: COLORBLIND_TYPE | null) => ({
  type: actionTypes.SET_COLORBLIND_TYPE,
  payload: coloblindType
})

export const setRepMetronomeSound = (payload: boolean): SetRepMetronomeSound => ({
  type: actionTypes.SET_REP_METRONOME_SOUND,
  payload: payload
})

export const setRepDownbeatsSound = (payload: boolean): SetRepDownbeatsSound => ({
  type: actionTypes.SET_REP_DOWNBEATS_SOUND,
  payload: payload
})

export const loadUserState = (payload: AppState) => ({
  type: actionTypes.LOAD_USER_STATE,
  payload
})

export const updateMailchimpData = (payload: any) => ({
  type: actionTypes.UPDATE_MAILCHIMP_INFO,
  payload
})

export const setFullScreen = (payload: boolean) => ({
  type: actionTypes.SET_FULL_SCREEN,
  payload
})

export const getSubscriptionStatus = () => ({
  type: actionTypes.GET_SUBSCRIPTION_STATUS,
})

export const setSubscriptionStatus = (payload: string) => ({
  type: actionTypes.SET_SUBSCRIPTION_STATUS,
  payload
})

export const setRepPageSelected = (payload: string) => ({
  type: actionTypes.SET_REP_PAGE_SELECTED,
  payload
})

export const setErrorRecognitionActive = (payload: ErrorRecognitionActive) => ({
  type: actionTypes.SET_ERROR_RECOGNITION_ACTIVE,
  payload
})
