import { useEffect, useState } from 'react'
import { Button, Box, TextField, Link, FormGroup, FormControlLabel, Checkbox, Input } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import ColoredLogo from '../../assets/images/ColoredLogo.png';
import pianoBackground from '../../assets/images/pianoBackground.png';
import KeyboardConnectMidi from '../../assets/images/KeyboardConnectMidi.png';
import MidiKeyboard from '../../assets/images/MidiKeyboard.png';
import Logo from 'Components/Logo'
import { useDispatch } from 'react-redux'
import { awsRum } from 'Utils/AwsRum';

const ErrorPage = () => {
  const [componentShow, setComponentShow] = useState("Signin")
  const dispatch = useDispatch()
  const navigate = useNavigate(); 
  
  const builtIn = () => {
    console.log("prevent tone JS")
    navigate('/roadmap')
  }
  const withoutBuiltIn = () => {
    console.log("allow tone JS")
    navigate('/roadmap')
  }

  let location = useLocation();

  return (
    <Box sx={pageDivStyles}>
    <Box sx={filterDivStyles}>
    <Box sx={{position: 'absolute', animation: 'error-page-rotation 6s infinite linear'}}>
      <img style={{maxWidth: '1000px', width: '75vw', opacity: 0.3}} src={KeyboardConnectMidi} alt="error-page-rotation"></img>
    </Box>
    <Box sx={signInDivStyles}>
      <Logo/>
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Box sx={{
          ////fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '34px',
          letterSpacing: '0.01em',
          color: '#CED0D4',
          padding: '80px 120px 40px 120px',
        }}>
          The Flow has been Broken!
        </Box>
        <Box sx={textStyles}>
          Looks like that page does not exist
          <br/><br/>
          <a style={{color:"#469F6A"}} href="/roadmap">Return to Roadmap</a>
        </Box>
      </Box>
    </Box>
    </Box>
    </Box>      
  )
}

export default ErrorPage;

const textStyles = {
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '20px',
  lineHeight: '30px',
  textAlign: 'center',
  letterSpacing: '0.01em',
  color: '#CED0D4',
  // margin: '0px 60px 20px 60px',
}

const pageDivStyles = {
    backgroundImage: `url(${pianoBackground})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
}

const filterDivStyles = {
  width: '100%',
  height: '100%',
  backdropFilter: 'brightness(15%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

const signInDivStyles = {
    backgroundColor: "black",
    //width: 300,
    maxWidth: '80%',
    maxHeight: '80%',
    //maxWidth: '80%',
    borderRadius: '12px',
    marginTop: '20px',
    //margin: '100px auto 10px, 10px',
    paddingBottom: '50px',
    
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 10,
}

const submitButtonStyles = {
  color: '#469F6A', 
  textDecorationLine: 'underline',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '16px',
}
