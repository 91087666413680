import Immutable, { Set, Map } from 'immutable';
import { CompletionState, ChapterAction, ChapterActionTypes } from 'Types/ChapterTypes';

const defaultCompletionState: CompletionState = Map<number, Set<number>>([]);


export const chapterReducer = (state = defaultCompletionState, action: ChapterAction) => {
  switch (action.type) {
    case ChapterActionTypes.MARK_CHAPTER_COMPLETE: {
      return Immutable.set(
        state,
        action.payload.lesson,
        Immutable.Set(
          Immutable.get(state, action.payload.lesson) || []
        ).union([action.payload.chapter])
      );
    }
    case ChapterActionTypes.LOAD_COMPLETION_STATE: {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state;
  }
}
