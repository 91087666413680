import React from "react";
import { useEffect, useState, useCallback, useRef } from "react";
import { Analytics } from 'aws-amplify'
import { UseStateError } from 'Utils/Errors';
import { RepertoireReducer, RepertoireActionTypes } from 'Types/RepertoireTypes';
import { useDispatch, useSelector } from "react-redux";
import { AuthReducer } from "Types/AuthTypes";
// import AxiosRetrier from 'Utils/AxiosRetrier';
import { IntervalTimer } from "Utils/IntervalTimer";
import TimeKeeper from "Models/TimeKeeper";
import EventStream from "Models/EventStream";
import { MIDIVal, MIDIValInput,IMIDIAccess } from '@midival/core';
import { TimingOffsetsConfig } from 'Models/EventStream';
import { MainAppReducer } from 'Types';
import MusicXML from 'Models/MusicXML';
import Phrase from 'Models/Phrase';
import * as appActions from 'Actions/app'
import TimeSignature from 'Models/TimeSignature';
import { MusicXMLStream } from 'Models/MusicXMLStream';
import {List} from 'immutable'
import Scheduler2 from 'Utils/Scheduler';
import { NoteType } from 'opensheetmusicdisplay';
// import { LevelPlayingEvent } from 'Constants/Events';
import { divisionsPerMeasureToNoteTypeIn4Over4 } from 'Utils';
import { TickNoteEvent } from 'Utils/CustomEvents';
import { REPERTOIRE_PROGRESS } from 'Types/RepertoireTypes';
import * as repertoireActions from '../Actions/repertoire'
import { find, first } from 'lodash';
import axios  from 'axios'
import { useMidiContext } from 'Contexts/MidiContext';
import { COLORBLIND_TYPE } from "Utils/Constants";
// const axios = new AxiosRetrier();

export enum AuthStatus {
  UNAUTHENTICATED,
  PENDING,
  ERROR,
  SUCCESS
}

export enum UserActionTypeEnum {
  LOG_IN,
  LOG_OUT,
  SIGN_UP
}

export type RepertoireStateType = {
  allowChevrons: boolean;
  accuracyPercent: number | null | undefined;
  performanceTempo: number | null; 
  inPerformanceMode: boolean,
  scheduler2: any;
  midiStream: any;
  topPhraseStartTimestamp: any;
  bottomPhraseStartTimestamp: any;
  musicLoaded: any;
  phraseParts: any;
  updateKey: any;
  showCursorSelector: boolean;
  isPlaying: boolean;

};

export type RepertoireFunctions = {
  setAccuracy: (accuracy: number) => void;
  setAllowChevrons: React.Dispatch<React.SetStateAction<boolean>>
  setMusicLoaded: any;
  allPhrasesLoaded: any;
  setInPerformanceMode: (prev: any) => void;
  setShowCursorSelector: any;
  setIsPlaying: (isPlaying:boolean) => void;
}

const initialState = { 
  allowChevrons: false,//undefined,
  accuracyPercent: 0,//undefined,
  scheduler2: undefined,
  midiStream: undefined,
  topPhraseStartTimestamp: undefined,
  bottomPhraseStartTimestamp: undefined,
  musicLoaded: undefined,
  phraseParts: undefined,
  updateKey: undefined,
  showCursorSelector: false,
  performanceTempo: null,
  inPerformanceMode: false,
  isPlaying: false
};

async function useComponentWillUnmount(cleanupCallback = () => {}) {
  const callbackRef = useRef(cleanupCallback)
  callbackRef.current = cleanupCallback // always up to date
  useEffect(() => {
    return () => {callbackRef.current()}
  }, [])
}

export const RepertoirePlayContext = React.createContext<(RepertoireStateType & RepertoireFunctions) | undefined>(undefined);


export const useRepertoirePlayContext: () => RepertoireStateType & RepertoireFunctions = () => {
  const state = React.useContext(RepertoirePlayContext);
  if (!state) {
    throw new UseStateError(useRepertoirePlayContext, RepertoirePlayProvider);
  }

  return {
    ...state
  };
};

export type RepertoirePlayProviderProps = {
  children?: React.ReactNode;
};

interface PhraseItem {
  s3_music_xml_id: string;
  s3_music_xml_url: string;
  music_xml_url_pointers: {
    [key: string]: string;
  };
  time_signature_numerator: number;
  time_signature_denominator: number;
}


export const RepertoirePlayProvider: React.FC<RepertoirePlayProviderProps> = ({
  children
}: RepertoirePlayProviderProps) => {

  // console.log("Number of times context loading?")
  const [state, setState] = React.useState<RepertoireStateType>(initialState) 
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const { errorRecognitionActive } = data
  const { userData } = data
  const auth = useSelector((state: AuthReducer) => state.authReducer)
  const repertoireReducerData = useSelector((state: RepertoireReducer) => state.repertoireReducer)
  const userRepertoireProgress = repertoireReducerData.userRepertoireProgresses
  const repertoireData = repertoireReducerData.repertoireData
  const repertoireIdSelect = repertoireReducerData.repertoireIdSelect
  const repertoireProgressSelected = find(userRepertoireProgress, urd => urd.repertoire.id === repertoireIdSelect)
  const repertoireSelected = find(repertoireData, rd => rd.id === repertoireIdSelect)
  const performanceTempo = repertoireSelected?.bpm || 120
  const [musicXMLStream] = useState(new MusicXMLStream());
  const tempo = repertoireProgressSelected?.last_tempo || performanceTempo
  const {addListener, removeListener, clearListeners} = useMidiContext()


  //const repertoireSelected = find(repertoireData, rd => rd.id === repertoireIdSelect)
  const userRepertoireSelected = find(userRepertoireProgress, rd => {
    if (rd == null) return false
    if (rd.repertoire) {
      return rd.repertoire.id === repertoireIdSelect
    } else {
      return false
    }
  })
  const [allowChevrons, setAllowChevrons] = useState(false)
  const [measuresPerPhrase, setMeasuresPerPhrase] = useState(5)//4)
  const musicXmlSvgWidth = useRef(960);
  const minTempoForChevronFlash = 72
  const dispatch = useDispatch();
  const intervalTimer = useRef<IntervalTimer | undefined>();
  const lastPlayedNoteTimer = useRef<NodeJS.Timeout | undefined>();
  const [timeKeeper, setTimeKeeper] = useState(
    new TimeKeeper(),
  )

  const [midiStream] = useState(
    new EventStream(timeKeeper, new TimingOffsetsConfig(), data.userData?.colorblind_type || null,1, 0.025, 0.1, true),
  )
  const [midiValInput, setMidiValInput] = useState<MIDIValInput>();
  const [midiAccess, setMidiAccess] = useState<IMIDIAccess>()
  const [midiInitialized, setMidiInitialized] = useState(false)
  const allowRepeatPhrases = false
  /// This should be in redux instead??
  const [ showAccuracy, setShowAccuracy ] = useState(false);
  const [phraseParts, setPhraseParts ] = useState<List<Phrase | undefined>>(List([]));
  const [musicLoaded, setMusicLoaded] = useState(false);
  const [started, setStarted] = useState(false);
  const [topPhraseStartTimestamp, setTopPhraseStartTimestamp] = useState(100000)
  const [bottomPhraseStartTimestamp, setBottomPhraseStartTimestamp] = useState(100000)
  const [tickNoteType] = useState(NoteType._64th);
  const phrasePartsRef = useRef<List<Phrase | undefined>>(phraseParts);
  const [updateKey, setUpdateKey] = useState(0);
  const [initialized, setInitialized] = useState(false);
  const [ accuracyPercent, setAccuracyPercent ] = useState<number | null>(null);
  const [ allPhrasesLoaded, setAllPhrasesLoaded ] = useState(false)
  const [inPerformanceMode, setInPerformanceMode] = useState<boolean>(false)
  const [isPlaying, setIsPlaying] = useState(false)
  
  const [showCursorSelector, setShowCursorSelector] = useState(false)

  const [accuracyAtTempo, setAccuracyAtTempo] = useState<number[]>([]);


  const setAccuracy = (accuracy: number | null) => {
    setAccuracyPercent(accuracy)
  }
  


  const wakeLock = useRef<any>(null);
  const [ isWakeLocked, setIsWakeLocked ] = useState(false);
  const canWakeLock = () => 'wakeLock' in navigator;
  async function lockWakeState() {
    if(!canWakeLock()) return;
    try {
      wakeLock.current = await (navigator as any).wakeLock.request();
      wakeLock.current.addEventListener('release', () => {
        setIsWakeLocked(false);
      });
      setIsWakeLocked(true);
    } catch(error) {
      // console.error('Failed to lock wake state');
      // console.error(error);
    }
  }

  // Last measure that the user fully played.
  const lastPlayedMeasureRef = useRef(-1)
  // Last updated phrase index 0-3
  const lastUpdatedIndexRef = useRef(-1)
  // What measure offset should the next phrase have
  const nextPhraseStartTimestampRef = useRef(0)
  // When we reach this measure, we should grab and update the next new phrase.
  // Subtract one here since we really want to switch at measure 6, but we use 0-based indexing.
  const nextPhraseUpdateMeasureRef = useRef(measuresPerPhrase * 1.5 - 1)
  const nextPhraseStartMeasureRef = useRef(0)
  // This interval timer isn't related to the in-game audiocontext timer.
  // it doesn't have to be as precise. Also, the scheduler only schedules in ticks.
  
  const keyCallbackRef = useRef<((ev: KeyboardEvent) => void) | null>(null)

  // useEffect(()=> {
  //   midiStream.errorRecognitionActive = !inPerformanceMode
  //   midiStream.clearErrors()
  // }, [inPerformanceMode])

  useEffect(() => {
    midiStream.setErrorRecognitionActive(errorRecognitionActive)
  }, [errorRecognitionActive])

  const noteOff = useCallback((ev: any) => {
    midiStream.handleNoteOffEvent(ev.note)
  },[midiStream]);

  const noteOn = useCallback((ev: any) => {
    if (ev.velocity !== 0) {
      setShowAccuracy(true)
      clearTimeout(lastPlayedNoteTimer?.current)
      if(process.env.REACT_APP_CURSOR_TIMEOUT) {
        lastPlayedNoteTimer.current = setInterval(() => {
          dispatch(repertoireActions.setRepertoirePlaying({repertoirePlaying: false}))
        },10000)
      }
      if(true){//!inPerformanceMode) {
        console.log("note i nnoteon", ev.note)
        midiStream.handleNoteOnEvent(ev.note, ev.velocity)
      }
    } else {
      noteOff(ev) // call the note off logic becasue velocity is 0.
    }
  },[midiStream, noteOff]);//, inPerformanceMode]);

  const listener = React.useRef({
    name: "eventListener",
    noteOn,
    noteOff
  });


  let [scheduler2, setScheduler2 ]= useState<Scheduler2 | null>(null);

  useEffect(()=>{
    const firstPhrase = phrasePartsRef.current.get(0);
    if(allPhrasesLoaded && firstPhrase) {
      const countInMeasures = (firstPhrase.getTimeSigFract() + firstPhrase.getPickUpMeasureOffset()) * (1/firstPhrase.getTimeSigFract())
      scheduler2?.reset()
      setScheduler2(null);
      const newScheduler = new Scheduler2(timeKeeper, -countInMeasures, firstPhrase.getPickUpMeasureOffset())
      setScheduler2(newScheduler)
   
      return () => {
        // Cleanup logic when the component unmounts
        newScheduler.pauseTicks(); // Stop any ongoing intervals or timeouts
        newScheduler.reset(); // Clear internal state
        newScheduler.cleatPauseUnpauseCallbacks(); // Remove all callbacks
        setScheduler2(null)
      };

    }
  },[allPhrasesLoaded])

  const resetScheduler = useCallback(()=>{
    scheduler2?.reset()
    const scheduler = new Scheduler2(timeKeeper)
    setScheduler2(scheduler)

    return () => {
      // Cleanup logic when the component unmounts
      scheduler.pauseTicks(); // Stop any ongoing intervals or timeouts
      scheduler.reset(); // Clear internal state
      scheduler.cleatPauseUnpauseCallbacks(); // Remove all callbacks
      setScheduler2(null)
    };

    
  },[scheduler2, timeKeeper])

  useEffect(() => {
    // Set the metronome from state. (Will apply for initial state as well as when user changes preference since reducer will trigger this.)
    if (userData?.rep_metronome_sound != undefined) {
      scheduler2?.changeMetronomeAudio(userData?.rep_metronome_sound);
    }
  }, [scheduler2, userData && userData.rep_metronome_sound])

  useEffect(() => {
    // Set the downbeats from state. (Will apply for initial state as well as when user changes preference since reducer will trigger this.)
    if (userData?.rep_downbeats_sound != undefined) {
      scheduler2?.changeDownbeatsAudio(userData?.rep_downbeats_sound);
    }
  }, [scheduler2, userData && userData.rep_downbeats_sound])
  
  const getPhraseInfo =  useCallback(async () => {
    const options = {
      url: process.env.REACT_APP_BACKEND_URL + `/api/v1/phrases?repertoireId=${repertoireIdSelect}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${auth.jwtToken}`
      }
    }
    let response = await axios.request(options)
    let phraseArr: any;
    // Sort the response based on order_number
    const phrase_items = response['data']['data'].sort((p1: {order_number: any}, p2: {order_number: any}) => p1.order_number - p2.order_number)
    phraseArr = phrase_items.map((phrase_item: PhraseItem) => {
      return ({
      uuid: phrase_item['s3_music_xml_id'],
      s3_music_xml_url: phrase_item['s3_music_xml_url'],
      svg_url: phrase_item['music_xml_url_pointers'][`svg_url_${musicXmlSvgWidth.current}`],
      svg_url_timesig: phrase_item['music_xml_url_pointers'][`svg_url_timesig_${musicXmlSvgWidth.current}`],
      json_data_url: phrase_item['music_xml_url_pointers'][`json_data_url_${musicXmlSvgWidth.current}`],
      json_data_url_timesig: phrase_item['music_xml_url_pointers'][`timesig_json_data_url_${musicXmlSvgWidth.current}`],
      time_signature_numerator: phrase_item['time_signature_numerator'],
      time_signature_denominator: phrase_item['time_signature_denominator']
    })})
    return phraseArr
    
    
  }, [data.levelSelect, data.levelData, auth.jwtToken, musicXmlSvgWidth, repertoireIdSelect])
  
  const getNextPhrase = useCallback(async function genNextPhrase(){
    const phraseDataArr = await getPhraseInfo();
    let phrasePromises = phraseDataArr.map(async (phraseData: any) => {
      let [musicXmlRes, cursorDataRes, cursorDataTimesigRes] = await Promise.all([
        axios.get(phraseData.s3_music_xml_url),
        axios.get(phraseData.json_data_url),
        axios.get(phraseData.json_data_url_timesig)
      ]);

      let musicXML = new MusicXML(musicXmlRes.data, [new TimeSignature(phraseData.time_signature_numerator, phraseData.time_signature_denominator)]);
      let phrase = new Phrase(
        phraseData.uuid, 
        musicXML, 
        phraseData.svg_url, 
        phraseData.svg_url_timesig, 
        cursorDataRes.data, 
        cursorDataTimesigRes.data
      );
      return phrase;
    });
    // phrasePromises = phrasePromises.slice(2,)  // <- Test 5 measures in phrase Good King Wenceslas
    return await Promise.all(phrasePromises);
  }, [getPhraseInfo])

  const updatePhraseParts = useCallback(function updatePhraseParts(newPhrasePart: Phrase | undefined, totalNumPhrases: number, varmeasuresPerPhrase: number, firstPhrasePickUpMeasureOffset: number){
    const nextIndexRef = (lastUpdatedIndexRef.current + 1) % totalNumPhrases//varmeasuresPerPhrase//4
    if (nextIndexRef % 2 == 0) {
      setTopPhraseStartTimestamp(nextPhraseStartTimestampRef.current)
    } else {
      setBottomPhraseStartTimestamp(nextPhraseStartTimestampRef.current)
    }
    if(nextIndexRef !== 0) {
      newPhrasePart?.setStartTimestamp(nextPhraseStartTimestampRef.current - firstPhrasePickUpMeasureOffset)
    } else {
      newPhrasePart?.setStartTimestamp(nextPhraseStartTimestampRef.current)
    }
    timeKeeper.setTimeSignatures(nextPhraseStartMeasureRef.current, newPhrasePart?.musicXML?.timeSignatures)

    nextPhraseStartTimestampRef.current += varmeasuresPerPhrase * timeKeeper.measureDurationAt(0)
    nextPhraseStartMeasureRef.current += varmeasuresPerPhrase
   
    phrasePartsRef.current = phrasePartsRef.current.set(nextIndexRef, newPhrasePart)
    
    setPhraseParts(phrasePartsRef.current)
    lastUpdatedIndexRef.current = nextIndexRef

  }, [measuresPerPhrase, nextPhraseStartTimestampRef.current, nextPhraseStartMeasureRef.current, phrasePartsRef.current, lastUpdatedIndexRef.current])

  const getTiersForLevel = useCallback(async function getTiersForLevel(){
    const levelNumber =  data.levelData[data.levelSelect].level_number
    const options = {
      url: process.env.REACT_APP_BACKEND_URL + `/api/v1/phrases/tiers-by-level?levelNumber=${levelNumber}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${auth.jwtToken}`
      }
    }
    let response = await axios
      .request(options)
    return response['data']
  }, [data.levelSelect, data.levelData, auth.jwtToken])

  const getNextNMeasures = useCallback(async () => {
    try {
      const newPhrases = await getNextPhrase();
      newPhrases.forEach(phrase => {
        musicXMLStream.addPhrase(phrase);
      });
      // Fetch the next phrase from the stream
      setMeasuresPerPhrase(newPhrases.length)
      newPhrases.forEach(() => {
        let nextPhrase = musicXMLStream.nextPhrase();
        updatePhraseParts(nextPhrase, newPhrases.length, nextPhrase!.musicXML?.measures?.length, newPhrases[0].pickUpMeasureOffset)
        
      })
    } catch (error) {
      console.error("Error fetching measures:", error);
    }
  }, [getNextPhrase, updatePhraseParts, musicXMLStream, allowRepeatPhrases, phraseParts]);
  
  useEffect(() => {
    if (musicLoaded && ! repertoireReducerData.repertoirePlaying && auth) {
      let repertoireStatus = REPERTOIRE_PROGRESS.IN_PROGRESS
      let completeSum = 0;
      accuracyAtTempo.forEach((el) => completeSum += el);
      console.log("accuracyAtTempo", accuracyAtTempo)
      console.log("midiStream.totalNotesLength()", midiStream.totalNotesLength())
      let percentComplete = midiStream.totalNotesLength() > 0 ? (completeSum/midiStream.totalNotesLength()) : 0 // make sure not dividing by 0
      if (percentComplete >= 1.0) {
        repertoireStatus = REPERTOIRE_PROGRESS.COMPLETE
      }
      let bestAccuracy;
      let bestPercentComplete;
      if (userRepertoireSelected && userRepertoireSelected.percent_complete && 100*percentComplete < userRepertoireSelected.percent_complete) {
        // Use best last values for accuracy and percent complete
        bestAccuracy = userRepertoireSelected.accuracy
        bestPercentComplete = userRepertoireSelected.percent_complete
      } else {
        bestAccuracy = accuracyPercent || 0
        bestPercentComplete = 100*percentComplete
      }
      const data = {
        status: repertoireStatus,
        repertoire: { id: repertoireIdSelect },
        last_played_at: (new Date()).toISOString(),
        completed_at: null,
        accuracy: bestAccuracy,
        percent_complete: bestPercentComplete,
        last_tempo: tempo,
      };
      console.log("updating data!", data)
      dispatch(repertoireActions.updateRepertoireDataRequest({...data}));
    }
  }, [auth.jwtToken,  repertoireReducerData.repertoirePlaying, musicLoaded])


  const incrementPhrase = useCallback(function incrementPhrase(ev: any){
    if (!ev.detail.timeKeeper.getIsCountingIn()) {
      // We want the last measure that was FULLY PLAYED, hence the floor and subtract 1.
      // We subtract 1 since if we just got to a new measure the LAST measure was fully played.
      // Also wrap the whole thing in Math.max(), since once we hit the first measure after the count in,
      // we'd actually move back a measure if we stopped playback during the first measure after count-in without it.
      lastPlayedMeasureRef.current = Math.max(lastPlayedMeasureRef.current, 
        Math.floor(ev.detail.ticks / ev.detail.timeKeeper.getTicksPerMeasure()) - 1)

      // Check if the user has fully played enough measures that we should grab the next phrase they'll play.
      if (lastPlayedMeasureRef.current >= nextPhraseUpdateMeasureRef.current) {
        getNextNMeasures()
        nextPhraseUpdateMeasureRef.current += measuresPerPhrase
      }
    }
  }, [getNextNMeasures]);

  const startMidiStreamUpdateLoop = useCallback(function startMidiStreamUpdateLoop(){
    scheduler2?.setIntervalByTick('updateMidiStream', (ev: typeof TickNoteEvent) => {
      if(true){//!ev.detail.workerState.inPerformanceMode) {
        midiStream.update()
      }
    }, NoteType._32nd, 1)
  },[midiStream, scheduler2])//, inPerformanceMode])


  const startAccuracyLoop = useCallback(()=>{
    // console.log("startAccuracyLoop:", scheduler2)
    // scheduler2?.setTimeoutByTick('setAccuracyIntervalTimeout', ()=> {
      scheduler2?.setIntervalByTick('accuracyLoop',function accuracyLoop(ev: typeof TickNoteEvent) {
        const accuracy = midiStream.calcRepAccuracyAndCompletion(tempo, performanceTempo, setAccuracyAtTempo)
        console.log("accuracy", accuracy)
        if (!isNaN(accuracy)) {
          setAccuracy(accuracy)
        }
        // every beat we check eligibility. If it's less than 95 then nope. This is reset every phrase (4 measures)
        // if accuracy dips below .95 at any point, the current phrase is not eligible for a chevron
        // However, we don't count the very first note.
        if(!isNaN(accuracy) && accuracy < 95){//} && midiStream.accuracyQueueLength() >= 1) {
          scheduler2?.updateWorkerState({isEligible: false})
        }
      }, divisionsPerMeasureToNoteTypeIn4Over4(timeKeeper.getTimeSignature().denominator), 1, 0);
    // }, NoteType.WHOLE, 4)
  },[midiStream, scheduler2, setAccuracy, timeKeeper])

  useEffect(() => {
    scheduler2?.updateWorkerState({tempo: tempo})
    if (tempo < minTempoForChevronFlash) {
      scheduler2?.updateWorkerState({isEligible: false})
      setAllowChevrons(false)
    }
  }, [scheduler2, setAllowChevrons, tempo])

  const onStop = useCallback((goBackOnPause: boolean = true) => {
    intervalTimer?.current?.pause()
    clearTimeout(lastPlayedNoteTimer.current)
    lastPlayedNoteTimer.current = undefined;
    scheduler2?.pauseTicks(goBackOnPause)
    setIsPlaying(false)
  }, [scheduler2])
  
  const onStart = useCallback(async () => {
    if(!started) {
      await scheduler2?.init({tempo:tempo});
      // this has to be initialized after the page is loaded and the user has made an action on the screen
      if (errorRecognitionActive) {
        startMidiStreamUpdateLoop()
      }
      startAccuracyLoop()
      setStarted(true);
    }
    if(!intervalTimer?.current) {
      // intervalTimer.current = new IntervalTimer(getRecordPlayTimeIntervalFunction(new Date().getTime()),10000)
      // intervalTimer?.current?.start()
    } else {
      // intervalTimer?.current?.resume()
    }
    if(process.env.REACT_APP_CURSOR_TIMEOUT) {
      clearTimeout(lastPlayedNoteTimer.current)
      lastPlayedNoteTimer.current = setInterval(() => {
        dispatch(repertoireActions.setRepertoirePlaying({repertoirePlaying: false}))
      },10000) 
    }
    await scheduler2?.unpauseTicks(tempo, 1, lastPlayedMeasureRef.current + 1, tickNoteType)
  }, [onStop, scheduler2, startAccuracyLoop, startMidiStreamUpdateLoop, started, tempo, tickNoteType, timeKeeper, errorRecognitionActive])

  // This will handle reaching the end and stopping fully
  const onCalcTheEnd = useCallback(async () => {
    if (timeKeeper.getTimeSignature()) {
      const totalNumOfMeasures = musicXMLStream.totalMeasures
      scheduler2?.setTimeoutByTick('endRepTimeout', () => {
        dispatch(repertoireActions.setRepertoirePlaying({repertoirePlaying: false}))
        onStop(false) // <- fix this, so it stops and doesn't bounce back if it's stopping for being at the end/
        reset()
        dispatch(repertoireActions.setRepertoireEnded(true));
        // Need to fix this so that when resets, the error markings are also reset!!!
        // Also need to move setScrollPosition into context, and take out of passing props!
      }, divisionsPerMeasureToNoteTypeIn4Over4(timeKeeper.getTimeSignature().denominator), (timeKeeper.getTimeSignature().numerator * totalNumOfMeasures)-1)
    }
  }, [timeKeeper, musicXMLStream,  repertoireReducerData.repertoirePlaying])

  useEffect(() => {
    nextPhraseStartTimestampRef.current = 0; // Reset to default value on component mount
    nextPhraseStartMeasureRef.current = 0; // Other related refs can be reset here
    // You might also reset other state or refs as necessary
  }, []);

  useEffect(() => {
    if (timeKeeper.getTimeSignature() &&  repertoireReducerData.repertoirePlaying) {
      onCalcTheEnd()
    }
  }, [timeKeeper.getTimeSignature(),  repertoireReducerData.repertoirePlaying])

  useEffect(() => {
    if (data.playOnStart && allPhrasesLoaded) {
      setTimeout(() => { // not necessary, it can play after allPhrasesLoaded, but gives user a moment to breathe...
        dispatch(repertoireActions.setRepertoirePlaying({repertoirePlaying: true}))  
        dispatch(appActions.setPlayOnStart(false))
      }, 400)
    }
  }, [data.playOnStart, allPhrasesLoaded])

  useEffect(() => {
    if (initialized &&  repertoireReducerData.repertoirePlaying){
      onStart()
    } else {
      onStop()
    }
  }, [ repertoireReducerData.repertoirePlaying])

  useEffect(() => {
    timeKeeper.setBpm(tempo)
  }, [tempo])

  const reset = useCallback(async ()=>{
      timeKeeper.resetTime()
      for (let i = 0; i < phrasePartsRef.current.size; i++) {
        phrasePartsRef.current.set(i, undefined)
      }
      setTopPhraseStartTimestamp(100000)
      setBottomPhraseStartTimestamp(100000)
      resetScheduler()
      clearTimeout(lastPlayedNoteTimer?.current)
      lastPlayedNoteTimer.current = undefined;
      intervalTimer.current?.pause()
      intervalTimer.current?.clear()
      intervalTimer.current = undefined
      setAllowChevrons(false)
      // setAccuracy(null);  // Reset is called at end, don't reset accuracy yet.
      lastPlayedMeasureRef.current = -1
      lastUpdatedIndexRef.current = -1
      nextPhraseStartTimestampRef.current = 0
      nextPhraseUpdateMeasureRef.current = measuresPerPhrase * 1.5 - 1
      nextPhraseStartMeasureRef.current = 0
      midiStream.reset();
      musicXMLStream.reset();

      setStarted(false);
  },[ setAllowChevrons, setAccuracy, dispatch, phrasePartsRef, midiStream, scheduler2, getNextNMeasures])


  const init = useCallback(async () => {
    // console.log("About to run this shit?", initialized, auth.jwtToken, "hmm")
    clearListeners()
    addListener(listener.current)
    if(!initialized && auth.jwtToken && repertoireIdSelect) {
      // make sure level is not in a playing state (for pause button)
      dispatch(repertoireActions.setRepertoirePlaying({repertoirePlaying: false}))
      
      // Initialize the first top and bottom phrases 
      if(auth.jwtToken) {
        if(musicXMLStream.length() === 0) {
          await Promise.all([
            getNextNMeasures(),
          ])
          setAllPhrasesLoaded(true)
        }
      } else {
        console.error("no jwt token available to pull tiers")
      }
      lockWakeState()
      setInitialized(true)
    }

}, [ dispatch, getTiersForLevel, initialized, scheduler2, updatePhraseParts, auth.jwtToken, repertoireIdSelect])


  useEffect(() => {
    if (auth.jwtToken && data.levelData.length > 0 && repertoireIdSelect) { // don't init context until level data is loaded (could also wait til on repertoire play page or something..)
      init() 
    }
  }, [auth.jwtToken, data.levelData.length, repertoireReducerData.repertoireIdSelect])



  useComponentWillUnmount(()=>{
    removeListener(listener.current)
    if(keyCallbackRef?.current) {
      document.removeEventListener('keyup', keyCallbackRef?.current)
    }
    setInitialized(false)
    onStop()
    dispatch(repertoireActions.setRepertoirePlaying({repertoirePlaying: false}))
    // midiValInput?.disconnect()
    midiStream.reset()
    musicXMLStream.reset()
    scheduler2?.reset()
    scheduler2?.cleatPauseUnpauseCallbacks()
    setScheduler2(null);
  })

  return (
    <RepertoirePlayContext.Provider 
      value={{
        ...state,
        allowChevrons: allowChevrons,
        accuracyPercent: accuracyPercent,
        scheduler2: scheduler2,
        midiStream: midiStream,
        topPhraseStartTimestamp: topPhraseStartTimestamp,
        bottomPhraseStartTimestamp: bottomPhraseStartTimestamp,
        performanceTempo: performanceTempo,
        inPerformanceMode: inPerformanceMode,
        musicLoaded: musicLoaded,
        phraseParts: phraseParts,
        updateKey: updateKey,
        allPhrasesLoaded: allPhrasesLoaded,
        isPlaying,
        setIsPlaying,
        
        showCursorSelector: showCursorSelector,
        setShowCursorSelector: setShowCursorSelector,
        setInPerformanceMode: setInPerformanceMode,

        setAccuracy,
        setAllowChevrons,
        setMusicLoaded,
      }}
    >
      {children}
    </RepertoirePlayContext.Provider>
  );
};
