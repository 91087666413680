import { Box } from '@mui/material'
import { SxProps } from '@mui/material/styles'
import LeftArrowIcon from 'assets/images/LeftArrowIcon.png'

const BackToRoadmapButton = ({sx, onClick}: {sx?:SxProps, onClick: ()=>void}) => (
  <Box
    sx={{
      marginTop: '20px',
      top: '0',
      left: '24px',
      position: 'absolute',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer'
      },
      ...sx
    }}
    onClick={onClick}
  >
    <img style={{height: '18px'}} src={LeftArrowIcon}/>
    <Box sx={{
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '24px',
      // textAlign: 'center',
      letterSpacing: '0.01em',
      color: '#CA7C62',
      marginLeft: '8px',
      marginBottom: '2px',
      '&:hover': {
        cursor: 'pointer'
      }
    }}
    >
      Back to Roadmap
    </Box>
  </Box>
)

export default BackToRoadmapButton