const defaultSpeedState: number = 1;

export enum PlaybackSpeedChangeActionTypes {
  LOAD_PLAYBACK_SPEED,
  SET_PLAYBACK_SPEED,
}

export type PlaybackSpeedChangeAction =
  | {
      type: typeof PlaybackSpeedChangeActionTypes.SET_PLAYBACK_SPEED;
      payload: number;
    }
  | {
      type: typeof PlaybackSpeedChangeActionTypes.LOAD_PLAYBACK_SPEED;
      payload: number;
    };

export const playbackSpeedReducer = (
  state = defaultSpeedState,
  action: PlaybackSpeedChangeAction,
) => {
  switch (action.type) {
    case PlaybackSpeedChangeActionTypes.LOAD_PLAYBACK_SPEED: {
      return action.payload;
    }
    case PlaybackSpeedChangeActionTypes.SET_PLAYBACK_SPEED: {
      return action.payload;
    }
    default:
      return state;
  }
};

export interface PlaybackSpeedReducer {
  playbackSpeedReducer: number;
}
