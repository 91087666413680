import { 
  RepertoireActionTypes,
  RepertoireData,
  UserRepertoireProgress,
  SetRepertoireSelectPayload,
  SetRepertoirePlayingPayload,
  RepertoireState,
  SetCursorVisible,
} from 'Types/RepertoireTypes'

export const updateRepertoireDataRequest = (payload: UserRepertoireProgress) => ({
  type: RepertoireActionTypes.UPDATE_REPERTOIRE_DATA_REQUEST,
  payload
})

export const fetchRepertoireDataSuccess = (payload: RepertoireData[]) => ({
  type: RepertoireActionTypes.FETCH_REPERTOIRE_DATA_SUCCESS,
  payload
})

export const setRepertoireEnded = (payload: boolean) => ({
  type: RepertoireActionTypes.SET_REPERTOIRE_ENDED,
  payload
})

export const setRepertoireSelect = (payload: SetRepertoireSelectPayload) => ({
  type: RepertoireActionTypes.SET_REPERTOIRE_SELECT,
  payload
})


export const fetchURPSuccess = (payload: UserRepertoireProgress[]) => ({
  type: RepertoireActionTypes.FETCH_URP_SUCCESS,
  payload
})

export const updateRepertoireDataLocal = (payload: Partial<UserRepertoireProgress>) => {
  return ({
  type: RepertoireActionTypes.UPDATE_REPERTOIRE_DATA_LOCAL,
  payload
})}

export const loadRepertoireState = (payload: RepertoireState) => ({
  type: RepertoireActionTypes.LOAD_REPERTOIRE_STATE,
  payload
})

export const setRepertoirePlaying = (payload: SetRepertoirePlayingPayload) => ({
  type: RepertoireActionTypes.SET_REPERTOIRE_PLAYING,
  payload
})

export const setCursorVisible = (payload: boolean): SetCursorVisible => ({
  type: RepertoireActionTypes.SET_CURSOR_VISIBLE,
  payload
})