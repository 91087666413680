import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import RootApp from './App';
import reportWebVitals from './reportWebVitals';
import { routerMiddleware, ConnectedRouter } from "connected-react-router"
import { createBrowserHistory } from "history";
import { createStore, applyMiddleware } from "redux"
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from "redux-devtools-extension"
import createRootReducer from "./Reducers"
import rootSaga from './Sagas'
import eventSafa from './Sagas/Events'
import { loadState, saveState } from './Utils/LocalStorage'
import { Provider } from "react-redux"
import { createTheme, ThemeProvider } from '@mui/material/styles'
// import { RepertoirePlayProvider } from 'Contexts/RepertoirePlayContext';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import { authReducer } from './Reducers/authReducer'
import { PersistGate } from 'redux-persist/integration/react'

const theme = createTheme({      
  typography: {
    button: {
      textTransform: 'none'
    }
  }
})
const rootNode = document.getElementById('root')
const sagaMiddleware = createSagaMiddleware()
const persistedState = {
  mainAppReducer: loadState(null)// (No user id yet, will just load default state!)
}

const history = createBrowserHistory()

const store = createStore(
  createRootReducer(history),
  persistedState,
  composeWithDevTools(
    applyMiddleware(sagaMiddleware, routerMiddleware(history))
  )
)


// This only does auth at the moment. We have our own local storage persistance. Might be a TODO to refactor to using this.
let persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

ReactDOM.render(
  <PersistGate persistor={persistor}>
  <ThemeProvider theme={theme}>
    <Provider store={store}>
        <ConnectedRouter history={history}>
          <RootApp store={store}/>
        </ConnectedRouter>
    </Provider>
  </ThemeProvider>
 </PersistGate>
, rootNode
);


// export default ReactMusicXML;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
