import { EventState, EventActionTypes, EventActions, EventStateActions } from "Types/EventTypes";
import immutable from 'immutable';
import { v4 } from 'uuid'

export const defaultEventState: EventState = {
  sessionId: "",
  queue: []
}

export const eventReducer = (state = defaultEventState, action: EventActions | EventStateActions) => {
  // console.log("event reducer: " + action.type)
  switch(action.type) {
    case EventActionTypes.SET_SESSION_ID:
      return immutable.merge(
        state,
        {
          sessionId: v4(),
        }
      )
    case EventActionTypes.SET_EVENT_QUEUE:
      return immutable.merge(
        state,
        {
          queue: action.payload,
        }
      )
    case EventActionTypes.DISPATCH_EVENTS:
      return state
    default:
      return state
  }
} 