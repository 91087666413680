import axios from "axios"
import { select } from "d3"
import { LevelCompletedEvent, LevelPlayEvent, LevelPlayingEvent, RepertoirePlayingEvent, TierCompletedEvent, TierStartedEvent, LevelStartedEvent, EventTypes, EventType } from "Types/EventTypes"
import { getCurrentAuthToken } from "Utils/Amplify"
import logger from 'Utils/Logger'

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/`
const eventMaxBatchSize = process.env.REACT_APP_EVENT_MAX_BATCH_SIZE;
if(!eventMaxBatchSize) {
  throw Error("REACT_APP_EVENT_MAX_BATCH_SIZE is not set")
}

const eventMaxBatchSizeInt = parseInt(eventMaxBatchSize)

type BaseEventType = {
  datetime: string,
  analytics_session_id: string,
}

type PlaySessionEventType = 
  BaseEventType & 
  {play_session_id?: string}

type PlaySessionEvents =
  PlaySessionEventType &
  (
    LevelCompletedEvent |
    LevelPlayEvent |
    LevelPlayingEvent |
    RepertoirePlayingEvent | 
    TierCompletedEvent |
    TierStartedEvent |
    LevelStartedEvent
  )

type GenericEventType = ((BaseEventType & EventTypes) | (PlaySessionEvents))



class EventQueue {

  queue: GenericEventType[] = []

  pushEvent(event: GenericEventType) {
    this.queue.push(event)
  }

  batchEvents(queue: GenericEventType[]): any[] {
    const batches: any[] = [[]];
    let indx = 0;
    while(
      queue.length > 0
    ) {
      // console.log("queue is larger than zero")
      // console.log("queue.length", queue.length)
      if(batches[indx].length <= eventMaxBatchSizeInt) {
        batches[indx].push(queue.shift())
      } else {
        batches.push([queue.shift()]);
        indx += 1;
      }
    }
    return batches
  }

  filterDuplicateEvents(queue: GenericEventType[]) {
    let latestLevelPlayingEvent: (LevelPlayingEvent & PlaySessionEventType) | undefined,
        latestTierCompletedEvent: (TierCompletedEvent & PlaySessionEventType) | undefined,
        latestLevelCompletedEvent: (LevelCompletedEvent & PlaySessionEventType) | undefined ;
  
    const filteredQeue = queue.reduce((
      accumulatedEvents: GenericEventType[], 
      currentEvent:GenericEventType ) =>{
        switch(currentEvent.event_type) {
          case EventType.LevelCompletedEvent:
            if(
              !latestLevelCompletedEvent || 
              new Date(currentEvent.datetime) > new Date(latestLevelCompletedEvent.datetime)
            ) {
              latestLevelCompletedEvent = currentEvent
            }
            break;
          case EventType.LevelPlayingEvent:
            if(!latestLevelPlayingEvent || currentEvent.play_time > currentEvent.play_time) {
              latestLevelPlayingEvent = currentEvent
            }
            break;
          case EventType.TierCompletedEvent:
              if(
                !latestTierCompletedEvent || 
                new Date(currentEvent.datetime) > new Date(latestTierCompletedEvent.datetime)
              ) {
                latestTierCompletedEvent = currentEvent
              }
              break;
          default:
            accumulatedEvents.push(currentEvent)
        }
        if(!latestLevelCompletedEvent && currentEvent.event_type === EventType.LevelCompletedEvent){
          latestLevelCompletedEvent = currentEvent
          accumulatedEvents.push(currentEvent)
        }
        return accumulatedEvents;
    }, [])
  
    for(let evt of [
      latestLevelCompletedEvent,
      latestLevelPlayingEvent,
      latestTierCompletedEvent
    ]) {
      if(evt) {
        filteredQeue.push(evt)
      }
    }
    return filteredQeue;
  }

  async clearQueue() {
    const jwtToken = await getCurrentAuthToken()
    const deduped = this.filterDuplicateEvents(this.queue)
    const batches = this.batchEvents(deduped)

    try{
      if(batches.length) {
        logger.debug("sending batch of size " + batches[0].length)
        // setting queue to empty as quickly as possible to prevent multiple simultaneous calls from 
        // sending dupe events.
        this.queue = []
        await axios.post(baseUrl + 'events', batches[0], {headers: {'Authorization': `Bearer ${jwtToken}`}});
      }

    } catch(err) {
      logger.error("events batch error")
      logger.error(err)
    }
    for(const batch of batches.slice(1,batches.length) ) {
      await new Promise(prom=>setTimeout(prom,200))
      logger.debug("sending batch of size " + batch.length)
      try{
        await axios.post(baseUrl + 'events', batch, {headers: {'Authorization': `Bearer ${jwtToken}`}});
      } catch(err) {
        logger.error("events batch error")
        logger.error(err)
      }
    }
    logger.debug("queue cleared")
  }

  async dispatchEvents(callback?: ()=>void) {
    if(process.env.REACT_APP_SEND_EVENTS == 'true') {
      try {
        if(this.queue.length > 0) {
          await this.clearQueue();
        }
        if(callback) {
          callback()

        }
      } catch (error) {console.error(error)}
    } else {
      logger.debug('REACT_APP_SEND_EVENTS not set - not sending events.')
    }
  }
}

// singleton is important here
export const eventQueue = new EventQueue();