import { IConfig, IObjective } from "Phaser/Scenes/ExerciseBase";
import ExerciseBase from "Phaser/Scenes/ExerciseBase";
import { StaffNote, GrandStaff, Phrase } from "Types/ExerciseData";
import Cursor from "Phaser/GameObjects/Cursor";
import { IteratorPhaser } from 'Models/Phrase'
import TimeSignature from "Models/TimeSignature";
import IPlayable from "Phaser/GameObjects/Playable";
import { ProgressState } from "Phaser/GameObjects/Progress";
import {
  EventBus,
  ListenForReactEvent,
  ListenUpdateObjective,
  NotifyReact,
  ReactToExerciseEventType,
  RemoveListener,
  ShowExercise,
} from "Phaser/EventBus";
import { colors } from "Phaser/config";

interface Objective extends IObjective {
  tempo: number;
  paddingHorizontal: number;
  notes?: StaffNote[];
  staff?: GrandStaff;
  timeSignature: TimeSignature;
  phraseId: string;
  svgUrl: string;
  jsonData: Phrase;
}

export interface Config extends IConfig<Objective> {
  transition?: "Wipe" | "Dissolve";
}

const loadSvgFromString = (
  loader: Phaser.Loader.LoaderPlugin,
  resource: string,
  id: string,
  options?: Phaser.Types.Loader.FileTypes.SVGSizeConfig,
) => {
  const url = URL.createObjectURL(
    new Blob([resource], { type: "image/svg+xml" }),
  );
  loader.svg(id, url, options);
};

class Note extends Phaser.GameObjects.Image implements IPlayable {
  isFocused: boolean = false;
  scene: Phaser.Scene;
  length: number;
  timestamp: number;
  timingState?: ProgressState;
  tween?: Phaser.Tweens.Tween;
  resetTimer?: Phaser.Time.TimerEvent;
  origScale: number;
  isRest: boolean;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    data: StaffNote,
    scale: number,
    isRest: boolean,
    i: number,
  ) {
    super(scene, x, y, data.id);
    this.scene = scene;
    this.length = data.length;
    this.timestamp = data.timestamp;
    scene.add.existing(this);
    this.origScale = scale;
    this.setScale(scale);
    this.isRest = isRest;
    this.alpha = 0;
    this.scene.time.delayedCall(
      50,
      () => {
        this.setScale(0);
        this.scene.tweens.add({
          targets: this,
          alpha: 1,
          scale,
          duration: 1000,
          ease: "Power2",
          delay: 50 * i,
        });
      },
      [],
      this,
    );
  }

  update(_: number) {}

  destroy() {
    this.tween?.remove();
    this.resetTimer?.remove();
    this.isFocused = false;
    super.destroy();
  }

  setFocus(focus: boolean) {
    console.log("focus", focus);
    this.isFocused = focus;
    this.tween?.remove();
    /*if (focus) {
      this.tween = this.scene.tweens.add({
        targets: this,
        scale: this.origScale * 1.35,
        yoyo: true,
        duration: 500,
        ease: "Bounce",
      });
    }*/
  }

  setTimingState(state: ProgressState) {
    console.log("setting state ", state);
    this.timingState = state;
    switch (state) {
      case ProgressState.LateStart:
      case ProgressState.MistimedRelease:
        this.setTintFill(colors.noteFillOffTime);
        break;
      case ProgressState.Ended:
        this.setTintFill(colors.noteFillOnTime);
        break;
      case ProgressState.Missed:
        this.setTintFill(colors.noteFillMiss);
        break;
      default:
    }
  }

  getState() {
    return this.timingState;
  }

  shake(_: any) {}

  reset(force: boolean) {
    this.timingState = undefined;
    if (force) {
      this.setTintFill(0);
      this.setScale(this.origScale);
    } else {
      this.tween?.remove();
      this.resetTimer = this.scene.time.delayedCall(
        2500,
        () => this.setTintFill(0),
        [],
        this,
      );
    }
  }

  getPosition() {
    return { x: this.x, y: this.y };
  }

  getHaloOffset() {
    if (this.length !== 1) {
      return this.height / 3.75;
    } else {
      return 0;
    }
  }
}

export interface INoteGraphicsDatabase {
  getNoteById: (id: string) => IPlayable | undefined;
}

export default class StaffExercise
  extends ExerciseBase<Objective, Config>
  implements INoteGraphicsDatabase
{
  table: Map<string, Note> = new Map();
  staffGraphic?: Phaser.GameObjects.Image;
  cursor?: Cursor;
  started: boolean = false;
  running: boolean = false;
  startingTimestamp: number = 0;
  timers: Phaser.Time.TimerEvent[] = [];

  constructor() {
    super("StaffExercise");
    this.currentObjective = -1;
  }

  create() {
    EventBus.emit("current-scene-ready", this);
  }

  getAll() {
    let states: (ProgressState | undefined)[] = [];
    this.table.forEach((x) => states.push(x.getState()));
    return states;
  }

  getNoteById(id: string): Note | undefined {
    return this.table.get(id);
  }

  loadAssets(
    notes: StaffNote[],
    staff: GrandStaff,
    objective: number,
    onLoad: (notes: StaffNote[], staff: GrandStaff, objective: number) => void,
  ) {
    //console.log("loading: assets");
    const dpr = window.devicePixelRatio;
    const scale = dpr * 2;
    this.textures.remove(staff.id);
    //console.log("loading: ", staff.svg);
    loadSvgFromString(this.load, staff.svg, staff.id, { scale });
    for (const note of notes) {
      this.textures.remove(note.id);
      loadSvgFromString(this.load, note.svg, note.id, { scale });
    }
    //console.log("loading: starting loader");
    this.load.start();
    //this.load.removeListener(Phaser.Loader.Events.COMPLETE);
    this.load.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        console.log("loading: loaded");
        onLoad(notes, staff, objective);
        //this.load.shutdown();
      },
      this,
    );
  }

  midiOn(_data: any): void {
    console.debug("note staff: ", _data);
    if (this.started) {
      this.playNote(_data.key);
    }
  }

  midiOff(_data: any): void {
    console.debug("note staff: ", _data);
    if (this.started) {
      this.releaseNote(_data.key);
    }
  }

  releaseNote(note: number) {
    if (this.running) {
      this.cursor?.onReleaseNote(note);
    }
  }

  onExerciseEnd() {
    console.log("evaluateing");
    let fail = false;
    this.table.forEach((val) => {
      console.debug("state", val.timingState, val.isRest);
      if (!val.isRest && val.timingState !== ProgressState.Ended) {
        fail = true;
      }
    });
    //fail = false;
    if (fail) this.failExercise();
    else this.passObjective();
    this.running = false;
  }

  onExerciseFail() {
    this.startExercise();
  }

  passObjective() {
    //console.log("pass");
    this.started = false;
    console.log("pass ", this.currentObjective, this.config?.objectives.length);
    super.passObjective(
      this.currentObjective === this.config?.objectives.length ?? 1 - 1,
    );
  }

  playNote(note: number) {
    console.log("playing note");
    if (this.currentObjective === undefined || this.currentObjective < 0) {
      console.debug(this.currentObjective);
      console.log("objective undefined");
      return;
    }
    const { tempo } = this.config!.objectives[this.currentObjective!];
    if (!this.running) {
      const [timing] = this.cursor!.tryStart(this.onExerciseEnd, this);
      console.log("timing", timing);
      if (timing !== "perfect") {
        this.table.forEach((note) => {
          if (note.timestamp === this.startingTimestamp) {
            note.shake(Math.min((60 / tempo) * 1000, 200));
          }
        });
        if (timing === "early") {
          this.showToast("Too early!");
        } else if (timing === "late") {
          this.showToast("Too late!");
        }
      } else {
        this.table.forEach((note) => note.reset(true));
        this.running = true;
        //this.noteGraphics[0].setFocus(true);
      }
    }
    if (this.running) {
      console.log("cursor");
      this.cursor?.onHoldNote(note);
    }
  }

  init(config: Config): void {
    EventBus.emit("current-scene-ready", this);
    super.init(config);
    if (this.config!.firstObjectiveAppearDelay)
      this.timers.push(
        this.time.delayedCall(
          this.config!.firstObjectiveAppearDelay,
          this.loadPhrase,
          [],
          this,
        ),
      );
    ListenForReactEvent(
      ListenUpdateObjective(() => this.updateObjective(Math.random())),
    );
  }

  loadPhrase(): void {
    console.log("loading phrase", this.currentObjective);
    const obj =
      this.currentObjective !== undefined && this.currentObjective === -1
        ? 0
        : this.currentObjective ?? 0;
    console.debug(obj, this.config?.objectives);
    const { notes, staff } = this.config!.objectives[obj];
    this.loadAssets(notes!, staff!, obj, this.createGraphics.bind(this));
  }

  startExercise(): void {
    this.started = true;
    console.log("starting");
    this.cursor!.reset();
    this.cursor!.waitForInput();
  }

  transition(skip?: boolean, last?: boolean) {
    let notes = [];
    for (const note of this.table.values()) {
      notes.push(note);
    }
    this.tweens.add({
      targets: [this.staffGraphic, ...notes],
      alpha: 0,
      duration: 600,
      onComplete: () => super.transition(skip, last),
      callbackScope: this,
    });
  }

  createGraphics(notes: StaffNote[], staff: GrandStaff, obj: number): void {
    console.log("loading phrase exercise, ", obj);
    const dpr = window.devicePixelRatio;
    this.staffGraphic = this.add.image(0, 0, staff.id);
    this.staffGraphic.alpha = 0;
    this.tweens.add({
      targets: this.staffGraphic,
      alpha: 1,
      duration: 2000,
      ease: "Power1",
    });
    const staffWidth = this.staffGraphic.displayWidth;
    const cameraWidth = this.cameras.main.width;
    const pLeft = 20 * dpr;
    const scale = (cameraWidth - pLeft * 2) / staffWidth;
    const cameraHeight = this.cameras.main.height;
    this.staffGraphic.setScale(scale, scale);
    this.staffGraphic.setOrigin(0, 0);
    this.staffGraphic.setPosition(
      cameraWidth / 2 - this.staffGraphic!.displayWidth / 2,
      cameraHeight / 2 - this.staffGraphic!.displayHeight / 2,
    );
    notes.forEach((note, idx) => {
      const n = new Note(
        this,
        note.x * scale * 2 * dpr,
        note.y * scale * 2 * dpr,
        note,
        scale,
        note.isRest,
        idx,
      );
      //n.setScale(scale);
      n.setX(n.x + n.displayWidth / 2 + this.staffGraphic!.x);
      n.setY(n.y + n.displayHeight / 2 + this.staffGraphic!.y);
      //n.setPosition(500, 500);
      console.debug("note", note);
      this.table.set(note.id, n);
    });
    const { tempo, timeSignature, jsonData } = this.config!.objectives[obj];
    /*const cursorData = this.notes.map((note) => ({
      timestamp: note.timestamp,
      position: { x: note.x, y: note.y },
      durationInMs: note.length * 4 * (60 / tempo) * 1000,
      durationRelative: note.length,
      playable: note,
    }));*/
    const { height, x, y } = this.staffGraphic!;
    const cursorHeight = height + 20 * dpr;
    const cursorWidth = cursorHeight / 10;
    const radius = cursorWidth / 2;
    const xpos = x;
    const ypos = y - (cursorHeight / 2 - this.staffGraphic!.displayHeight / 2);
    const iterator = new IteratorPhaser(jsonData.notes, jsonData.uuid);
    const locations = new Map();
    this.table.forEach((note, id) => locations.set(id, note.x));
    iterator.setLocationCache(locations);
    iterator.setPhraseEnd(
      this.staffGraphic!.x,
      this.staffGraphic!.displayWidth,
    );
    this.cursor = new Cursor(
      this,
      xpos,
      ypos,
      cursorWidth,
      cursorHeight,
      radius,
      timeSignature,
      tempo,
      iterator,
    );
    NotifyReact(ShowExercise());
  }

  pauseExercise() {
    this.cursor?.pause();
    super.pauseExercise();
  }

  resume() {
    this.cursor?.resume();
    super.resume();
  }

  update(time: number) {
    console.log("update");
    this.cursor?.update(time);
  }

  unloadGraphics() {
    this.timers.forEach((timer) => timer.remove());
    this.timers.clear();
    this.staffGraphic?.destroy();
    this.cursor?.destroy();
    this.table.forEach((n) => n.destroy());
    this.table.clear();
    //this.notes.forEach((n) => n.destroy());
    this.started = false;
    this.running = false;
  }

  onObjectivePassed() {
    this.unloadGraphics();
  }

  onObjectiveSkipped() {
    this.unloadGraphics();
  }

  unload() {
    this.tweens.killAll();
    console.log("scene: unloading staff");
    RemoveListener(ReactToExerciseEventType.UpdateObjective);
    this.unloadGraphics();
    super.unload();
  }

  updateObjective(id: number) {
    // this is kinda bad
    console.log("update objective", id);
    this.currentObjective =
      this.currentObjective !== undefined ? this.currentObjective + 1 : 0;
    console.log("update objective : current ", this.currentObjective);
    super.showInstruction(this.config!.objectives[this.currentObjective].text);
    if (this.currentObjective > 0) {
      this.loadPhrase();
    }
  }

  stop(): void {
    this.tweens.killAll();
    this.scene.stop();
  }
}
