import { Box, SxProps } from "@mui/system"
import Carousel, { CarouselElementState } from 'Components/Carousel'

type NativigationProps = {
  onNext:()=>void,
  onBack: ()=>void,
  sx?:SxProps,
  nextButtonSx?:SxProps
  backButtonSx?:SxProps
  nextText?: string
  backText?: string
  carousel?: CarouselElementState[]
}

const Navigation = ({
  sx={}, 
  nextButtonSx={},
  backButtonSx={},
  onNext,
  onBack,
  nextText,
  backText,
  carousel
}: NativigationProps) => {

  const gridTemplateColumns = carousel ? 
    '[col1-start] 40%  [col2-start] 20%  [col3-start] 40% [col3-end]' :
    '[col1-start] 50%  [col2-start] 50%  [col2-end]';

  return (
    <Box
        sx={{
          width:'90%',
          display: 'grid',
          gridTemplateColumns: gridTemplateColumns,
          position: 'relative',
          ...sx
        }}
      >
        <Box sx={{
          marginLeft: '30px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8px',
          gap: '8px',
          width: '160px',
          height: '24px',
          outlineColor: '#CA7C62',
          outlineStyle: 'solid',
          borderRadius: '40px',
          '&:hover': {
            cursor: 'pointer'
          },
          ...backButtonSx
        }}
        onClick={onBack}
        >
          <Box sx={{
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '19px',
            textAlign: 'center',
            letterSpacing: '2.5px',
            textTransform: 'uppercase',
            color: '#CA7C62',

          }}>
            {backText}
          </Box>

        </Box>

        {carousel && 
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Carousel carousels={carousel}/>
          </Box>
        }

        <Box sx={{
          marginLeft: 'auto',
          marginRight: '30px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8px 8px',
          gap: '8px',
          width: '100px',
          height: '28px',
          background: '#CA7C62',
          borderRadius: '40px',
          '&:hover': {
            cursor: 'pointer'
          },
          ...nextButtonSx
        }}
        onClick={onNext}
        >
          <Box sx={{
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '19px',
            textAlign: 'center',
            letterSpacing: '2.5px',
            textTransform: 'uppercase',
            color: '#050B13',
            width: 'auto',
            padding: '8px'
          }}>
            {nextText}
          </Box>

        </Box>
      </Box>
  )
}

export default Navigation