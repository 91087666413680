import { PlaybackSpeedChangeActionTypes } from "Reducers/videoSpeedReducer";

export const loadPlaybackSpeedState = (payload: number) => ({
  type: PlaybackSpeedChangeActionTypes.LOAD_PLAYBACK_SPEED,
  payload,
});

export const setPlaybackSpeedState = (payload: number) => ({
  type: PlaybackSpeedChangeActionTypes.SET_PLAYBACK_SPEED,
  payload,
});
