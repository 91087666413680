import MusicXML from './MusicXML';
import { MidiEventState, MidiEventType, MidiFullErrorStates, PrerenderedPhraseData, PrerenderedVoiceEntry, errorScores } from 'Types';
import { EventBucket, NoteEvent, RestEvent } from 'Models/EventStream';
import { sum, cloneDeep, rest } from 'lodash';
import { PhraseNoteError } from 'Types/EventTypes';
import { midiFullErrorStates, midiSemiErrorStates, midiAllErrorStates } from 'Types';
import logger from 'Utils/Logger';
import TimeSignature from './TimeSignature';
import { HANDEDNESS } from 'Utils/Constants';
import { isErrorState, isSemiErrorState } from 'Utils';


const END_PHRASE_LEFT = 'END_PHRASE_LEFT',
      END_PHRASE_WIDTH = 'END_PHRASE_WIDTH';

export enum AccuracyTypes {
  avg_running_accuracy = 'avg_running_accuracy',
  avg_lh_running_accuracy = 'avg_lh_running_accuracy',
  avg_rh_running_accuracy = 'avg_rh_running_accuracy',
  avg_accuracy = 'avg_accuracy', 
  avg_lh_accuracy = 'avg_lh_accuracy',
  avg_rh_accuracy = 'avg_rh_accuracy'
}


export type AccuracyType = 
  AccuracyTypes.avg_accuracy |
  AccuracyTypes.avg_lh_accuracy | 
  AccuracyTypes.avg_rh_accuracy | 
  AccuracyTypes.avg_running_accuracy | 
  AccuracyTypes.avg_lh_running_accuracy | 
  AccuracyTypes.avg_rh_running_accuracy
  


export class Iterator {
  cursorData: PrerenderedPhraseData[];
  phrase: Phrase
  indx: number = 0;
  currentMeasureIndex: number = 0;
  protected endReached: boolean = false;
  currentTimeStamp: number = 0;
  noteLocationCache: Map<string, number> = new Map();
  svg: HTMLElement | null = null;

  constructor(cursorData: PrerenderedPhraseData[], phrase: Phrase) {
    this.cursorData = cursorData
    this.phrase = phrase;
    if(cursorData.length) {
      // console.log(phraseUuid)
      // console.log(cursorData)
      this.currentMeasureIndex = cursorData[0].currentMeasureIndex;
      this.currentTimeStamp = cursorData[0].currentTimestamp;
    } else {
      this.endReached = true;
    }
  }

  next() {
    this.indx += 1;
    if (this.indx >= this.cursorData.length) {
      this.endReached = true;
    } else {
      this.currentMeasureIndex = this.cursorData[this.indx].currentMeasureIndex;
      this.currentTimeStamp = this.cursorData[this.indx].currentTimestamp;
    }
  }

  public get EndReached(): boolean {
    return this.endReached;
  }

  public get CurrentMeasureIndex(): number {
    return this.currentMeasureIndex;
  }

  public get CurrentVoiceEntries(): PrerenderedVoiceEntry[] {
    return this.cursorData[this.indx].currentVoiceEntries;
  }

  // Function is extracted out as it needs to be slightly modified
  // in derived classes to be compatible with phaser
  getGraphicsLeft(currentNoteId: string): number | undefined {
    const element = document.getElementById(currentNoteId);
    const left = element?.getBoundingClientRect()?.left;
    if (left) {
      this.noteLocationCache.set(currentNoteId, left);
    }
    return left;
  }

  // Function is extracted out as it needs to be slightly modified
  // in derived classes to be compatible with phaser
  getPhraseLeft(): number | undefined {
    if (this.phrase.uuid) {
      // prevent unnecessary DOM query if using cached locations
      let left = this.noteLocationCache.get(END_PHRASE_LEFT);
      let width = this.noteLocationCache.get(END_PHRASE_WIDTH);
      if (left && width) {
        return left + width;
      }
      if (!this.svg) {
        this.svg = document.getElementById(this.phrase.uuid);
      }
      if (this.svg && (!left || !width)) {
        left = this.svg.getBoundingClientRect().left;
        this.noteLocationCache.set(END_PHRASE_LEFT, left);
        width = this.svg.getBoundingClientRect().width;
        this.noteLocationCache.set(END_PHRASE_WIDTH, width);
      }
      if (left && width) {
        return left + width;
      } else {
        console.error('LEFT OR WIDTH NOT FOUND');
        console.log('left: ' + left);
        console.log('width: ' + width);
      }

      if (!this.svg) {
        console.warn('no SVG found for phrase id: ' + this.phrase.uuid);
      }
    } else {
      console.warn(
        'no phrase id defined in Phrase iterator - this should only happen if phrases are rendered in browser'
      );
    }
  }

  public left(): number | undefined {
    // returns the equivalent "left" value of the current cursor iterator location in the
    // phrase SVG

    if (this.EndReached) {
      return this.getPhraseLeft();
    } else {
      // best option here is to try to find the first non rest note because whole note long rests will be in
      // the middle of the measure, which throws off animation.
      let noteIndx = 0,
        voiceEntryIndex = 0;
      let note = this.cursorData[this.indx].currentVoiceEntries[0].Notes[0];
      let shortestNoteLength: number | undefined = undefined;
      while (
        voiceEntryIndex < this.cursorData[this.indx].currentVoiceEntries.length
      ) {
        while (
          noteIndx <
          this.cursorData[this.indx].currentVoiceEntries[voiceEntryIndex].Notes
            .length
        ) {
          const newNote =
            this.cursorData[this.indx].currentVoiceEntries[voiceEntryIndex]
              .Notes[noteIndx];
          if (
            shortestNoteLength === undefined ||
            newNote.length <= shortestNoteLength
          ) {
            // typically rests will be later in the measure even if they are the same length (i.e. dotted half note vs 3/4 whole note rest)
            if (
              newNote.isRest &&
              !note.isRest &&
              newNote.length == shortestNoteLength
            ) {
              break;
            } else {
              note =
                this.cursorData[this.indx].currentVoiceEntries[voiceEntryIndex]
                  .Notes[noteIndx];
              shortestNoteLength = note.length;
            }
          }
          noteIndx += 1;
        }
        noteIndx = 0;
        voiceEntryIndex += 1;
      }
      const currentNoteId: string = note?.graphics[0]?.staveNote?.id;
      if (currentNoteId) {
        let left = this.noteLocationCache.get(currentNoteId);
        if (!left) {
          left = this.getGraphicsLeft(currentNoteId);
        }
        return left;
      }
      return 0;
    }
  }

  // The phaser scenes calculate the locations of every interactive element
  // (Notes / Rests) at the time of rendering the phrases so we can just
  // use those precalculated values without having the recompute them
  // during playback
  setLocationCache(locations: Map<string, number>) {
    this.noteLocationCache = locations;
  }

  setPhraseEnd(left: number, width: number) {
    this.noteLocationCache.set(END_PHRASE_LEFT, left);
    this.noteLocationCache.set(END_PHRASE_WIDTH, width);
  }

  public clearCache() {
    this.noteLocationCache = new Map<string, number>();
  }

  public resetIterator() {
    this.indx = 0;
    if (this.cursorData.length) {
      this.currentMeasureIndex = this.cursorData[0].currentMeasureIndex;
      this.currentTimeStamp = this.cursorData[0].currentTimestamp;
      this.endReached = false;
    } else {
      this.endReached = true;
    }
  }

  peekNext(): PrerenderedPhraseData | undefined {
    if (this.indx >= this.cursorData.length - 1) return undefined;
    else {
      return this.cursorData[this.indx + 1];
    }
  }
}

// TEMPORARY. The phaser iterator should take a phrase just as the iterator does
export class IteratorPhaser {
  cursorData: PrerenderedPhraseData[];
  phraseUuid: string
  phrase: undefined = undefined
  indx: number = 0;
  currentMeasureIndex: number = 0;
  protected endReached: boolean = false;
  currentTimeStamp: number = 0;
  noteLocationCache: Map<string, number> = new Map();
  svg: HTMLElement | null = null;

  constructor(cursorData: PrerenderedPhraseData[], phraseUuid: string) {
    this.cursorData = cursorData
    this.phraseUuid = phraseUuid;
    if(cursorData.length) {
      // console.log(phraseUuid)
      // console.log(cursorData)
      this.currentMeasureIndex = cursorData[0].currentMeasureIndex;
      this.currentTimeStamp = cursorData[0].currentTimestamp;
    } else {
      this.endReached = true;
    }
  }

  next() {
    this.indx += 1;
    if (this.indx >= this.cursorData.length) {
      this.endReached = true;
    } else {
      this.currentMeasureIndex = this.cursorData[this.indx].currentMeasureIndex;
      this.currentTimeStamp = this.cursorData[this.indx].currentTimestamp;
    }
  }

  public get EndReached(): boolean {
    return this.endReached;
  }

  public get CurrentMeasureIndex(): number {
    return this.currentMeasureIndex;
  }

  public get CurrentVoiceEntries(): PrerenderedVoiceEntry[] {
    return this.cursorData[this.indx].currentVoiceEntries;
  }

  // Function is extracted out as it needs to be slightly modified
  // in derived classes to be compatible with phaser
  getGraphicsLeft(currentNoteId: string): number | undefined {
    const element = document.getElementById(currentNoteId);
    const left = element?.getBoundingClientRect()?.left;
    if (left) {
      this.noteLocationCache.set(currentNoteId, left);
    }
    return left;
  }

  // Function is extracted out as it needs to be slightly modified
  // in derived classes to be compatible with phaser
  getPhraseLeft(): number | undefined {
    if (this.phraseUuid) {
      // prevent unnecessary DOM query if using cached locations
      let left = this.noteLocationCache.get(END_PHRASE_LEFT);
      let width = this.noteLocationCache.get(END_PHRASE_WIDTH);
      if (left && width) {
        return left + width;
      }
      if (!this.svg) {
        this.svg = document.getElementById(this.phraseUuid);
      }
      if (this.svg && (!left || !width)) {
        left = this.svg.getBoundingClientRect().left;
        this.noteLocationCache.set(END_PHRASE_LEFT, left);
        width = this.svg.getBoundingClientRect().width;
        this.noteLocationCache.set(END_PHRASE_WIDTH, width);
      }
      if (left && width) {
        return left + width;
      } else {
        console.error('LEFT OR WIDTH NOT FOUND');
        console.log('left: ' + left);
        console.log('width: ' + width);
      }

      if (!this.svg) {
        console.warn('no SVG found for phrase id: ' + this.phraseUuid);
      }
    } else {
      console.warn(
        'no phrase id defined in Phrase iterator - this should only happen if phrases are rendered in browser'
      );
    }
  }

  public left(): number | undefined {
    // returns the equivalent "left" value of the current cursor iterator location in the
    // phrase SVG

    if (this.EndReached) {
      return this.getPhraseLeft();
    } else {
      // best option here is to try to find the first non rest note because whole note long rests will be in
      // the middle of the measure, which throws off animation.
      let noteIndx = 0,
        voiceEntryIndex = 0;
      let note = this.cursorData[this.indx].currentVoiceEntries[0].Notes[0];
      let shortestNoteLength: number | undefined = undefined;
      while (
        voiceEntryIndex < this.cursorData[this.indx].currentVoiceEntries.length
      ) {
        while (
          noteIndx <
          this.cursorData[this.indx].currentVoiceEntries[voiceEntryIndex].Notes
            .length
        ) {
          const newNote =
            this.cursorData[this.indx].currentVoiceEntries[voiceEntryIndex]
              .Notes[noteIndx];
          if (
            shortestNoteLength === undefined ||
            newNote.length <= shortestNoteLength
          ) {
            // typically rests will be later in the measure even if they are the same length (i.e. dotted half note vs 3/4 whole note rest)
            if (
              newNote.isRest &&
              !note.isRest &&
              newNote.length == shortestNoteLength
            ) {
              break;
            } else {
              note =
                this.cursorData[this.indx].currentVoiceEntries[voiceEntryIndex]
                  .Notes[noteIndx];
              shortestNoteLength = note.length;
            }
          }
          noteIndx += 1;
        }
        noteIndx = 0;
        voiceEntryIndex += 1;
      }
      const currentNoteId: string = note?.graphics[0]?.staveNote?.id;
      if (currentNoteId) {
        let left = this.noteLocationCache.get(currentNoteId);
        if (!left) {
          left = this.getGraphicsLeft(currentNoteId);
        }
        return left;
      }
      return 0;
    }
  }

  // The phaser scenes calculate the locations of every interactive element
  // (Notes / Rests) at the time of rendering the phrases so we can just
  // use those precalculated values without having the recompute them
  // during playback
  setLocationCache(locations: Map<string, number>) {
    this.noteLocationCache = locations;
  }

  setPhraseEnd(left: number, width: number) {
    this.noteLocationCache.set(END_PHRASE_LEFT, left);
    this.noteLocationCache.set(END_PHRASE_WIDTH, width);
  }

  public clearCache() {
    this.noteLocationCache = new Map<string, number>();
  }

  public resetIterator() {
    this.indx = 0;
    if (this.cursorData.length) {
      this.currentMeasureIndex = this.cursorData[0].currentMeasureIndex;
      this.currentTimeStamp = this.cursorData[0].currentTimestamp;
      this.endReached = false;
    } else {
      this.endReached = true;
    }
  }

  peekNext(): PrerenderedPhraseData | undefined {
    if (this.indx >= this.cursorData.length - 1) return undefined;
    else {
      return this.cursorData[this.indx + 1];
    }
  }
}

export type MidiEvent = {midiEvent: MidiEventType, midiNote: number, timestamp: number}

class Phrase {
  readonly musicXML: MusicXML;
  protected svgURL: string;
  protected svgTimesigURL: string;
  protected cursorData: PrerenderedPhraseData[];
  protected cursorDataTimesig: PrerenderedPhraseData[];
  protected iterator: Iterator;
  protected timesigIterator: Iterator;
  readonly uuid: string
  protected startTimestamp: number = 0;
  protected endTimestamp: number = 0;
  protected pickUpMeasureLength: number = 0;
  protected pickUpMeasureOffset: number = 0;
  protected timeSigFract: number = 0;
  protected midiEvents: {midiEvent: MidiEventType, midiNote: number, timestamp: number}[] = []
  protected playData: EventBucket[] = []
  protected runningAccuracy: number[] = []
  protected lhRunningAccuracy: number[] = []
  protected rhRunningAccuracy: number[] = []
  protected complete: boolean = false;
  protected hasLeftHandNotes = false;
  protected hasRightHandNotes = false;

  // This will at some point contain all tags
  constructor(uuid: string, musicXML: MusicXML, svgURL: string, svgTimesigURL: string, cursorData: PrerenderedPhraseData[], cursorDataTimesig: PrerenderedPhraseData[]) {
    this.uuid = uuid;
    this.musicXML = musicXML;
    this.svgURL = svgURL;
    this.svgTimesigURL = svgTimesigURL;
    this.cursorData = cursorData;
    this.cursorDataTimesig = cursorDataTimesig;
    this.iterator = new Iterator(cursorData, this);
    this.timesigIterator = new Iterator(cursorDataTimesig, this);
    this.timeSigFract = (this.musicXML.timeSignatures[0].numerator / this.musicXML.timeSignatures[0].denominator)
    this.pickUpMeasureLength = this.calcPickUpLength()
    // I feel like this could be more simple but don't have the time
    this.pickUpMeasureOffset = this.pickUpMeasureLength == 0 ?
      0 :
    (this.timeSigFract) - this.pickUpMeasureLength
    this.hasLeftHandNotes =  cursorData.reduce(((prev,curr) => {
      return prev + curr.currentVoiceEntries.reduce((acc, currVoiceEntry) => {
        return acc + currVoiceEntry.Notes.reduce((acc, note) => acc + (note.staffPosition === 'bottom' ? 1 : 0), 0)
      }, 0)
    }), 0) > 0
    this.hasRightHandNotes =  cursorData.reduce(((prev,curr) => {
      return prev + curr.currentVoiceEntries.reduce((acc, currVoiceEntry) => {
        return acc + currVoiceEntry.Notes.reduce((acc, note) => acc + (note.staffPosition === 'top' ? 1 : 0), 0)
      }, 0)
    }), 0) > 0
    // this.calcPickUpLength()
    
  }

  private calcPickUpLength() {
    const timeSigNum = this.musicXML.timeSignatures[0].numerator
    const timeSigDenom = this.musicXML.timeSignatures[0].denominator
    const timeSigFract = timeSigNum / timeSigDenom
    if(this.cursorData) {
      const lastDatum = this.cursorData[this.cursorData?.length - 1]
      const voiceEntries = lastDatum.currentVoiceEntries
      const randomVoiceEntry = voiceEntries[voiceEntries.length - 1];
      const randomNote = randomVoiceEntry.Notes[randomVoiceEntry.Notes.length - 1]
      const randomLastVoiceEntryNoteEndTimestamp = lastDatum.currentTimestamp + randomNote.length
      return randomLastVoiceEntryNoteEndTimestamp % timeSigFract
    } 
    return this.pickUpMeasureLength
  }

  private calcEndTimestamp(){
    const lastDatum = this.cursorData[this.cursorData?.length - 1]
    const voiceEntries = lastDatum.currentVoiceEntries
    const randomVoiceEntry = voiceEntries[voiceEntries.length - 1];
    const randomNote = randomVoiceEntry.Notes[randomVoiceEntry.Notes.length - 1]
    const randomLastVoiceEntryNoteEndTimestamp = lastDatum.currentTimestamp + randomNote.length
    return randomLastVoiceEntryNoteEndTimestamp
    
  }
  
  public setStartTimestamp(startTimestamp: number) {
    this.startTimestamp = startTimestamp
    this.endTimestamp = this.calcEndTimestamp() + startTimestamp
  }

  public getStartTimestamp():number {
    return this.startTimestamp
  }

  public getEndTimestamp():number {
    return this.endTimestamp
  }

  public getTimeSigFract(): number {
    return this.timeSigFract
  }

  public getPickUpMeasureOffset(): number {
    return this.pickUpMeasureOffset
  }

  public getIterator() {
    return this.iterator
  }

  public getTimesigIterator() {
    return this.timesigIterator
  }
 
  public getHasRightHandedNotes() {
    return this.hasRightHandNotes
  }

  public getHasLeftHandedNotes() {
    return this.hasLeftHandNotes
  }

  public pushEvent(midiEvent: MidiEvent) {
    this.midiEvents.push(midiEvent)
  }

  public pushRunningAccuracy(accuracy: number) {
    this.runningAccuracy.push(accuracy);
  }

  public pushLHRunningAccuracy(accuracy: number) {
    this.lhRunningAccuracy.push(accuracy);
  }

  public pushRHRunningAccuracy(accuracy: number) {
    this.rhRunningAccuracy.push(accuracy);
  }

  public pushPhrasePlayData(bucket:EventBucket) {
    this.playData.push(bucket)
  }

  public getSvgUrl() {
    return this.svgURL
  }

  public getSvgTimesigUrl() {
    return this.svgTimesigURL
  }

  public getComplete() {
    return this.complete
  }

  public setComplete(complete: boolean) {
    this.complete = complete;
  }

  private calcRunningAccuracy(hand?: HANDEDNESS): number | null {
    // return this.cursorData.reduce((accum: number, datum) =>{
    //   return datum.currentVoiceEntries.reduce((accum: number, currentVoiceEntry) =>{
    //     return currentVoiceEntry.Notes.filter(note => note.staffPosition === 'bottom').map(note => note.)
    //   },0) + accum;
    // }, 0)
    const totalAccuracy = this.playData.reduce((acc: number, datum: EventBucket) =>{
      const noteAcc =  datum.notes.reduce((acc: number, note: NoteEvent) => {
        if(hand && hand === HANDEDNESS.LEFT) {
          return note.hand === hand ? note.getLhSnapshotPhraseAccuracy() + acc : acc
        }
        if(hand && hand === HANDEDNESS.RIGHT) {
          return note.hand === hand ? note.getRhSnapshotPhraseAccuracy() + acc : acc
        }
        return note.getSnapshotPhraseAccuracy() + acc
      }, 0)

      const restAcc = datum.rests.reduce((acc: number, rest: RestEvent) => {
          const restSnapshotAccuracy = rest.getSnapshotPhraseAccuracy()
          if( restSnapshotAccuracy !== undefined) {
            if(hand && hand === HANDEDNESS.LEFT) {
              let LHSnapshotAccuracy = rest.getLhSnapshotPhraseAccuracy()
              if(LHSnapshotAccuracy) {
                return rest.hand === hand ? LHSnapshotAccuracy + acc : acc
              } else {
                return acc
              }
            } else if(hand && hand === HANDEDNESS.RIGHT) {
              let rhSnapshotAccuracy = rest.getLhSnapshotPhraseAccuracy()
              if(rhSnapshotAccuracy) {
                return rest.hand === hand ? rhSnapshotAccuracy + acc : acc
              } else {
                return acc
              }
            }
            return acc + restSnapshotAccuracy
          }
          return acc;
        }, 0)

      return restAcc + noteAcc + acc;
    },0)

    const totalNoteAndRestSum = this.playData.reduce((acc: number, datum: EventBucket) =>{
      const noteSum =  datum.notes.reduce((acc: number, note: NoteEvent) => {
        if(hand) {
          return note.hand === hand ? 1 + acc : acc
        } else {
          return 1 + acc
        }
      }, 0)

      const restSum = datum.rests.reduce((acc: number, rest: RestEvent) => {
          const restSnapshotAccuracy = rest.getSnapshotPhraseAccuracy()
          if( restSnapshotAccuracy !== undefined) {
             if(hand) {
              return rest.hand === hand ? 1 + acc : acc
            }
            return 1 + acc
          }
          return acc;
        }, 0)
        return noteSum + restSum + acc;
    },0)
    console.log("totalRunningAccuracy for " + hand, totalAccuracy)
    console.log("totalNoteAndRestSum (running accuracy)", totalNoteAndRestSum)

    if(totalNoteAndRestSum > 0) {
      return totalAccuracy / totalNoteAndRestSum
    } else {
      return null
    }
  }

  private calcAccuracy2(hand?: HANDEDNESS): number | null {

    const getNoteAccuracyfromEndState = (midiEventState: MidiEventState) => {
      if(isSemiErrorState(midiEventState)) {
        return .7
      } else if(isErrorState(midiEventState)){
        return 0
      } else {
        return 1
      }
    }
    const totalAccuracy = this.playData.reduce((acc: number, datum: EventBucket) =>{
      const noteAcc =  datum.notes.reduce((acc: number, note: NoteEvent) => {
        if(hand) {
          return note.hand === hand ? getNoteAccuracyfromEndState(note.getOffsetState()) + acc : acc
        }
        return getNoteAccuracyfromEndState(note.getOffsetState()) + acc
      }, 0)

      const restAcc = datum.rests.reduce((acc: number, rest: RestEvent) => {
          const restSnapshotAccuracy = rest.getSnapshotPhraseAccuracy()
          if( restSnapshotAccuracy !== undefined) {
            if(hand) {
              //TODO: make not magic number
              return rest.hand === hand ? .7  + acc : acc
            }
            return .7 + acc
          }
          return acc;
        }, 0)

      return restAcc + noteAcc + acc;
    },0)

    const totalNoteAndRestSum = this.playData.reduce((acc: number, datum: EventBucket) =>{
      const noteSum =  datum.notes.reduce((acc: number, note: NoteEvent) => {
        if(hand) {
          return note.hand === hand ? 1 + acc : acc
        } else {
          return 1 + acc
        }
      }, 0)

      const restSum = datum.rests.reduce((acc: number, rest: RestEvent) => {
          const restSnapshotAccuracy = rest.getSnapshotPhraseAccuracy()
          // it's kind of implied here that the handedsnapshot accuracy would be available
          if(restSnapshotAccuracy !== undefined) {
            if(hand) {
              return rest.hand === hand ? 1 + acc : acc
            }
            return 1 + acc
          }
          return acc;
        }, 0)
        return noteSum + restSum + acc;
    },0)
    console.log("totalAccuracy (accuracy) for " + hand, totalAccuracy)
    console.log("totalNoteAndRestSum (accuracy)", totalNoteAndRestSum)

    if(totalNoteAndRestSum > 0) {
      return (totalAccuracy / totalNoteAndRestSum) * 100
    } else {
      return null
    }
  }

  private getAccuracy(accuracyType: AccuracyTypes.avg_lh_accuracy | AccuracyTypes.avg_rh_accuracy | AccuracyTypes.avg_accuracy): number | null {
    console.log("running avg accuracy. playdata: ", this.playData)
    if(
      accuracyType === AccuracyTypes.avg_lh_accuracy
    ) {
      console.log("getting avg lh running accuracy.", this.calcAccuracy2(HANDEDNESS.LEFT))
      // console.log("this.lhRunningAccuracy arr: ", this.lhRunningAccuracy)
      // console.log("calculated lh running accuracy: ",  (sum(this.lhRunningAccuracy) / (this.lhRunningAccuracy.length * 100)) * 100)
      // return this.lhRunningAccuracy.length === 0 ? null :  (sum(this.lhRunningAccuracy) / (this.lhRunningAccuracy.length * 100)) * 100
      return this.calcAccuracy2(HANDEDNESS.LEFT)
    } else if(
      accuracyType === AccuracyTypes.avg_rh_accuracy
    ) {
      console.log("getting avg rh running accuracy.", this.calcAccuracy2(HANDEDNESS.RIGHT))
      // console.log("this.rhRunningAccuracy arr: ", this.rhRunningAccuracy)
      // console.log("calculated rh running accuracy: ", (sum(this.rhRunningAccuracy) / (this.rhRunningAccuracy.length * 100)) * 100)
      // return this.rhRunningAccuracy.length === 0 ? null : (sum(this.rhRunningAccuracy) / (this.rhRunningAccuracy.length * 100)) * 100
      return this.calcAccuracy2(HANDEDNESS.RIGHT)

    }
    console.log("getting avg running accuracy.", this.calcAccuracy2())
    // console.log("this.runningAccuracyy arr: ", this.runningAccuracy)
    // console.log("calculated running accuracy: ", (sum(this.runningAccuracy) / (this.runningAccuracy.length * 100)) * 100)
    // return this.runningAccuracy.length === 0 ? null : (sum(this.runningAccuracy) / (this.runningAccuracy.length * 100)) * 100
    return this.calcAccuracy2()
  }

  private calcAccuracy(accuracyType: AccuracyType) {
    logger.debug("getting accuracy. Accuracy type: ", accuracyType)
    const playData = this.filterPlayDataByAccuracyType(accuracyType);
    const totalNotes = playData.reduce((prevBucket,currBucket) => {
      return prevBucket + currBucket.notes.length
    }, 0)
    const totalRests = playData.reduce((prevBucket, currBucket) => {
      return prevBucket + currBucket.rests.filter(rest => !rest.hidden).length
    }, 0)
    const totalSemiCorrect = playData.reduce((prevBucketTotal:number,currBucket: EventBucket) => {
      const currBucketTotalNotesSemiCorrect = currBucket.notes.reduce((prevNoteTotal:number, currNote:NoteEvent) => {
        if (
          midiSemiErrorStates.includes(currNote.getOffsetState())
        ) {
          return prevNoteTotal + 1; 
        }
        return prevNoteTotal
      }, 0)

      const currBucketTotalRestsSemiCorrect = currBucket.rests.reduce((prevRestsTotal: number, currRest: RestEvent) => {
        if(!currRest.hidden) {
          return currRest.state !== MidiEventState.UNSET ? prevRestsTotal + 1 : prevRestsTotal;
        } else {
          return prevRestsTotal;
        }
      }, 0)
      return prevBucketTotal + currBucketTotalNotesSemiCorrect + currBucketTotalRestsSemiCorrect; 
    }, 0)
    const totalCorrect = playData.reduce((prevBucketTotal:number,currBucket: EventBucket) => {
      const totalCorrectNotes = currBucket.notes.reduce((prevNoteTotal:number, currNote:NoteEvent) => {
        if (currNote.getOffsetState() == MidiEventState.HIT) {
          return prevNoteTotal + 1; 
        }
        return prevNoteTotal
      }, 0)
      const totalCorrectRests = currBucket.rests.reduce((prevNoteTotal:number, currNote:RestEvent) => {
        if (currNote.state == MidiEventState.UNSET) {
          return prevNoteTotal + 1; 
        }
        return prevNoteTotal
      }, 0)
      return totalCorrectNotes + totalCorrectRests + prevBucketTotal
    }, 0)
    logger.debug(`totalNotes = ${totalNotes}`)
    logger.debug(`totalRests = ${totalRests}`)
    logger.debug(`totalSemiCorrect = ${totalSemiCorrect}`)
    logger.debug(`totalCorrect = ${totalCorrect}`)
    logger.debug(`((.7 * totalOffTime) + (totalCorrect))/(totalNotes + totalRests) = ((.7 * ${totalSemiCorrect}) + (${totalCorrect}))/(${totalNotes} + ${totalRests}) - ${((.7 * totalSemiCorrect) + (totalCorrect))/(totalNotes + totalRests)}`)
    if(totalNotes + totalRests === 0) {
      logger.debug("final result accuracyType: ", accuracyType,100)
      return 100
    } else {
      const accuracy = ((.7 * totalSemiCorrect) + (totalCorrect))/(totalNotes + totalRests);
      logger.debug("final result accuracyType: ", accuracyType, accuracy * 100)
      return accuracy * 100;
    }
  }

  private getAvgRunningAccuracy(accuracyType: AccuracyTypes.avg_lh_running_accuracy | AccuracyTypes.avg_rh_running_accuracy | AccuracyTypes.avg_running_accuracy): number | null {
    console.log("running avg accuracy. playdata: ", this.playData)
    if(
      accuracyType === AccuracyTypes.avg_lh_running_accuracy
    ) {
      console.log("getting avg lh running accuracy.", this.calcRunningAccuracy(HANDEDNESS.LEFT))
      // console.log("this.lhRunningAccuracy arr: ", this.lhRunningAccuracy)
      // console.log("calculated lh running accuracy: ",  (sum(this.lhRunningAccuracy) / (this.lhRunningAccuracy.length * 100)) * 100)
      // return this.lhRunningAccuracy.length === 0 ? null :  (sum(this.lhRunningAccuracy) / (this.lhRunningAccuracy.length * 100)) * 100
      return this.calcRunningAccuracy(HANDEDNESS.LEFT)
    } else if(
      accuracyType === AccuracyTypes.avg_rh_running_accuracy
    ) {
      console.log("getting avg rh running accuracy.", this.calcRunningAccuracy(HANDEDNESS.RIGHT))
      // console.log("this.rhRunningAccuracy arr: ", this.rhRunningAccuracy)
      // console.log("calculated rh running accuracy: ", (sum(this.rhRunningAccuracy) / (this.rhRunningAccuracy.length * 100)) * 100)
      // return this.rhRunningAccuracy.length === 0 ? null : (sum(this.rhRunningAccuracy) / (this.rhRunningAccuracy.length * 100)) * 100
      return this.calcRunningAccuracy(HANDEDNESS.RIGHT)

    }
    console.log("getting avg running accuracy.", this.calcRunningAccuracy())
    // console.log("this.runningAccuracyy arr: ", this.runningAccuracy)
    // console.log("calculated running accuracy: ", (sum(this.runningAccuracy) / (this.runningAccuracy.length * 100)) * 100)
    // return this.runningAccuracy.length === 0 ? null : (sum(this.runningAccuracy) / (this.runningAccuracy.length * 100)) * 100
    return this.calcRunningAccuracy()
  }

  private filterPlayDataByHand(hand: 'lh' | 'rh') {
    return this.playData.reduce((listByhand: EventBucket[], currentNoteBucket: EventBucket) => {
      const evtBucketByHand = cloneDeep(currentNoteBucket);
      evtBucketByHand.notes = evtBucketByHand.notes.filter(note => {
        return note.hand === hand;
      })
      listByhand.push(evtBucketByHand);
      return listByhand;
    }, [])
  }

  private filterPlayDataByAccuracyType(accuracyType: AccuracyType) {
    if(
      accuracyType === AccuracyTypes.avg_lh_accuracy ||
      accuracyType === AccuracyTypes.avg_lh_running_accuracy
    ) {
      return this.filterPlayDataByHand('lh');
    } else if(
      accuracyType === AccuracyTypes.avg_rh_accuracy ||
      accuracyType === AccuracyTypes.avg_rh_running_accuracy
    ) {
      return this.filterPlayDataByHand('rh');
    }
    return this.playData
  }


  private calculateErrorScore(errorData:PhraseNoteError[]): number {
    return errorData.reduce(
      (prevNoteErrAcc, noteError) => {
        if (midiAllErrorStates.includes(noteError.note_error_type)) {
          return prevNoteErrAcc + errorScores[noteError.note_error_type as MidiFullErrorStates];
        } else {
          return prevNoteErrAcc;
        }
      },
      0,
    );
  }
  

  private getErrorData() {
    return this.playData.reduce(
      (prevErrors:PhraseNoteError[], noteBucket: EventBucket ) => {
        const errors = []
        for( let note of noteBucket.notes) {

          if(
            midiAllErrorStates.includes(note.getOffsetState())
          ) {
            errors.push({
              note: note.midiNote,
              note_error_type: note.getOffsetState(),
              tick: noteBucket.timestamp - this.startTimestamp,
              additional_note: note.additionalNote || null,
              is_rest: false
            })
          } else if (
            midiAllErrorStates.includes(note.getOnsetState())
          ) {
            // this is a stop gap in case somehow an event with an onset error gets through without that error being carried to offset
            errors.push({
              note: note.midiNote,
              note_error_type: note.getOnsetState(),
              tick: noteBucket.timestamp - this.startTimestamp,
              additional_note: note.additionalNote || null,
              is_rest: false
            })
          }
        }

        for(let rest of noteBucket.rests) {
          if(rest.additionalNote) {
            errors.push({
              note: -1,
              note_error_type: rest.state,
              tick: noteBucket.timestamp - this.startTimestamp,
              additional_note: rest.additionalNote || null,
              is_rest: true
            })
          }
        }
        return prevErrors.concat(errors)
      }, []) 
  }

  private getTotalNotes() {
    return this.playData.reduce(
      (prevNotes:number, noteBucket: EventBucket ) => {
        return prevNotes + noteBucket.notes.length;
      }, 0)
  }

  public generatePhraseCompletedEventData() {
    const avgRunningAccuracy: number | null = this.getAvgRunningAccuracy(AccuracyTypes.avg_running_accuracy)
    const avgLHRunningAccuracy: number | null = this.getAvgRunningAccuracy(AccuracyTypes.avg_lh_running_accuracy)
    const avgRHRunningAccuracy: number | null = this.getAvgRunningAccuracy(AccuracyTypes.avg_rh_running_accuracy)
    const accuracy: number | null = this.getAccuracy(AccuracyTypes.avg_accuracy)
    const rhAccuracy: number | null = this.getAccuracy(AccuracyTypes.avg_rh_accuracy)
    const lhAccuracy: number | null = this.getAccuracy(AccuracyTypes.avg_lh_accuracy)
    const errorData: PhraseNoteError[] = this.getErrorData()
    const totalNotes: number = this.getTotalNotes();
    const errorScore: number = this.calculateErrorScore(errorData);
    logger.debug("error data in phrase")
    logger.debug(errorData)
    const midiData = this.midiEvents
    return ({
      avgRunningAccuracy,
      avgLHRunningAccuracy,
      avgRHRunningAccuracy,
      accuracy,
      rhAccuracy,
      lhAccuracy,
      errorData,
      midiData,
      totalNotes,
      errorScore
    })
  }
  

}

export default Phrase;
